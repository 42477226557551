export default function CaretDown({ className }: SvgProps) {
  return (
    <svg
      className={className}
      viewBox="0 0 16 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 8L16 9.53674e-07L6.78947 6.55084H9.21053L-5.59506e-08 0.727274L8 8Z"
        fill="currentColor"
      />
    </svg>
  )
}
