import { videoAsset } from '@/data/sanity/fragments'
import { getMainDataOverrides, getMergedLanguageQueryString } from '@/data/sanity/utils'

export const fields = `
  title, 
  'slug': slug.current,
  ...(content) {
    ${getMergedLanguageQueryString('Content', [
      'publishedDate',
      'linkType',
      'link',
      { name: 'file', fields: videoAsset.fields },
    ])}
  },
  ${getMainDataOverrides({
    objectSuffix: 'Content',
    mainObjectName: 'content',
    mainDataOverrides: ['title'],
  })}
`

export const fragment = (name: string) => `${name}{
	${fields}
}`

const exports = { fields, fragment }

export default exports
