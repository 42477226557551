import { pageMetadataFields } from '@/data/sanity/fragments/_shared'
import cmsSettings from '../cmsSettings'
import menuItemCategory from '../menuItemCategory'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  sectionBackground,
  itemsByCategory[]{
    categoryReference->{${menuItemCategory.fields}},
    items[]-> {
      ${pageMetadataFields}
    }
  }
`

export const fragment = (name = 'menuListing') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
