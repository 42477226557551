import cmsSettings from '../cmsSettings'
import { imageAsset } from '@/data/sanity/fragments'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  sectionBackground,
  items[]{
    title,
    description,
    ${imageAsset.fragment('image')},
    imageStyle
  }
`

export const fragment = (name = 'valuesList') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
