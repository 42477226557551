import metadata from './metadata'
import navigation from './navigation'
import footer from './footer'
import { LANGUAGES } from '@/data'

/* eslint-disable */
export const fields = `
  _type == "siteSettingsMetadata" => { 
    _type,
    ${Object.values(LANGUAGES)
      .map(language => {
        return `
        $language == "${language}" => {
          ...(${language}SiteSettingsMetadata) {
            ${metadata.fields}
          }
        }
      `
      })
      .join(',')}
   },
  _type == "navigation" => { 
    _type,
    ${Object.values(LANGUAGES)
      .map(language => {
        return `
        $language == "${language}" => {
          ...(${language}NavigationSettings) {
            ${navigation.fields}
          }
        }
      `
      })
      .join(',')}
   },
  _type == "footer" => { 
    _type,
    ${Object.values(LANGUAGES)
      .map(language => {
        return `
        $language == "${language}" => {
          ...(${language}FooterSettings) {
            ${footer.fields}
          }
        }
      `
      })
      .join(',')}
   }
`
/* eslint-enable */

export const fragment = (name = 'siteSettings') => `${name}{${fields}}`

const exported = {
  fields,
  fragment,
}

export default exported
