// Languages
export const LANGUAGES = {
  EN: 'en',
  TW: 'tw',
}
export const DEFAULT_LANGUAGE = LANGUAGES.EN
export const LANGUAGE_COOKIE_NAME = 'DTF_LANG'
export const LANGUAGE_COOKIE_EXPIRY_SECONDS = 86400 * 7 // seconds in day * 7

export const STICKY_BANNER_CLOSED_COOKIE_NAME = 'STICKY_BANNER_CLOSED'
export const STICKY_BANNER_CLOSED_COOKIE_EXPIRY_SECONDS = 86400 * 7 // seconds in day * 7

// Doc Types
export const DOC_TYPES = {
  PAGE: 'page',
  MENU_ITEM: 'menuItem',
  LOCATION: 'location',
  BLOG_POST: 'blogPost',
}

// Service Types
export const SERVICE_TYPES = {
  PICKUP: 'pickup',
  RESERVATIONS: 'reservations',
  CATERING: 'catering',
  DELIVERY: 'delivery',
  GENERAL_RESERVATION: 'generalReservation',
  DINING_ROOM: 'diningRoom',
  BAR_COUNTER: 'barCounter',
}

// Private Dining Section ID
export const PRIVATE_DINING_SECTION_ID_SUBSTRING = 'private_dining_location_blurb'

// Slugs / Paths
export const FOUR_OH_FOUR_SLUG = '404'
export const MENU_SLUG = 'menu'
export const LOCATIONS_SLUG = 'locations'
export const BLOG_SLUG = 'blog'

// Misc Text
export const MISC_TEXT = {}

// Event Names
export const EVENTS = {}

// Time Zones
export const TIME_ZONES = {
  ATLANTIC: {
    title: 'Atlantic',
    offset: '+4:00',
    zone: 'America/St_Johns',
  },
  EASTERN: {
    title: 'Eastern',
    offset: '+5:00',
    zone: 'America/New_York',
  },
  CENTRAL: {
    title: 'Central',
    offset: '+6:00',
    zone: 'America/Chicago',
  },
  MOUNTAIN: {
    title: 'Mountain',
    offset: '+7:00',
    zone: 'America/Denver',
  },
  PACIFIC: {
    title: 'Pacific',
    offset: '+8:00',
    zone: 'America/Los_Angeles',
  },
  ALASKA: {
    title: 'Alaska',
    offset: '+9:00',
    zone: 'America/Nome',
  },
}

export const DAY_ORDER = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY']
