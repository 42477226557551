import { imageAsset, link } from '@/data/sanity/fragments'
import cmsSettings from '../cmsSettings'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  sectionBackground,
  title,
  subtitle,
  description,
  eventDetails,
  inviteCode,
  ${link.fragment('cta')},
  ${link.fragment('secondaryCta')},
  ${imageAsset.fragment('image', true)}
`

export const fragment = (name = 'eventDetails') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
