import cmsSettings from '../cmsSettings'
import { imageAsset, link, videoAsset } from '@/data/sanity/fragments'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  sectionBackground,
  title,
  eyebrow,
  description[]{
    ...,
    markDefs[]{
      ...,
      _type == "link" => {
        ${link.fields}
      },
    }
  },
  textPosition,
  ${link.fragment('cta')},
  media {
    mediaStyle,
    mediaType,
    ${imageAsset.fragment('image')},
    ${videoAsset.fragment('videoLoopDesktop')},
    ${videoAsset.fragment('videoLoopMobile')},
  }
`

export const fragment = (name = 'textAndImage') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
