'use client'

import classnames from 'classnames'
import styles from './RestaurantNavigation.module.scss'
import useStore from '@/store'
import { useEffect, useRef, useState } from 'react'
import RestaurantOpenClosedBlurb from '@/components/RestaurantOpenClosedBlurb/RestaurantOpenClosedBlurb'
import useI18n from '@/hooks/use-i18n'
import OrderDropdown from '@/components/OrderDropdown/OrderDropdown'
import gsap from 'gsap'
import useBreakpoint from '@/hooks/use-breakpoint'
import Icon from '@/components/Icon/Icon'

type RestaurantNavigationProps = {
  className?: string
}

const CHECKBOX_ID = 'preferredCheckbox'
const DURATION = 0.8

const RestaurantNavigation = ({ className }: RestaurantNavigationProps) => {
  const activeRestaurant = useStore(state => state.activeRestaurant)
  const setFavouriteRestaurant = useStore(state => state.setFavouriteRestaurant)
  const favouriteRestaurant = useStore(state => state.favouriteRestaurant)
  const [data, setData] = useState<LocationHero | null>(null)
  const { i18n } = useI18n()
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)
  const navigationRef = useRef<HTMLElement | null>(null)
  const { isMobile } = useBreakpoint()

  useEffect(() => {
    let show = Boolean(data)
    if (!activeRestaurant) {
      show = false
    }

    gsap.killTweensOf([navigationRef.current])
    gsap.to(navigationRef.current, {
      duration: DURATION,
      y: show ? 0 : '105%',
      ease: 'Power3.easeOut',
    })
  }, [data, activeRestaurant])

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }

    setTimeout(() => {
      setData(activeRestaurant)
    }, DURATION * 1.1 * 1000)
  }, [activeRestaurant])

  const setAsPreferredContent = (
    <div className={styles.setAsPreferredContainer}>
      <input
        type="checkbox"
        id={CHECKBOX_ID}
        name={CHECKBOX_ID}
        className={styles.checkboxInput}
        checked={favouriteRestaurant?.slug === data?.slug}
        onChange={e => {
          if (e.target.checked) {
            setFavouriteRestaurant(data)
          } else {
            setFavouriteRestaurant(null)
          }
        }}
      />
      <label
        className={styles.setAsPreferred__label}
        htmlFor={CHECKBOX_ID}
      >
        {i18n('setAsPreferred')}
      </label>
      <span
        className={styles.checkboxBox}
        onClick={() => {
          if (favouriteRestaurant) {
            setFavouriteRestaurant(null)
          } else {
            setFavouriteRestaurant(data)
          }
        }}
      >
        <span className={styles.checkboxBox__circle} />
        <Icon
          name="check"
          className={styles.checkboxBox__check}
        />
      </span>
    </div>
  )

  if (!data) return null

  return (
    <nav
      className={classnames(styles.RestaurantNavigation, className)}
      ref={navigationRef}
    >
      <div className={styles.inner}>
        <div className={styles.leftSide}>
          <div className={styles.blurbContainer}>
            <RestaurantOpenClosedBlurb
              timeZone={data.timeZone}
              hours={data.hours}
              holidayHours={data.holidayHours}
              className={styles.restaurantOpenClosedBlurb}
              isComingSoon={data.isComingSoon}
            />
            <div className={styles.setAsPreferredMobile}>{setAsPreferredContent}</div>
          </div>
          <p className={styles.title}>
            {data.title}
            {data?.areaTitle && <span>&nbsp;-&nbsp;{data?.areaTitle}</span>}
          </p>
        </div>
        <div className={styles.rightSide}>
          <div className={styles.setAsPreferredDesktop}>{setAsPreferredContent}</div>
          {data?.reservationLinks && (
            <OrderDropdown
              serviceLinks={data?.reservationLinks}
              className={styles.reserveButton}
              primary
              isReserve
              dropdownPosition={isMobile ? 'topLeft' : 'topRight'}
            />
          )}
          {data?.serviceLinks && (
            <OrderDropdown
              serviceLinks={data?.serviceLinks}
              dropdownPosition="topRight"
              label={i18n('order')}
              className={styles.orderDropdown}
            />
          )}
        </div>
      </div>
    </nav>
  )
}

RestaurantNavigation.displayName = 'RestaurantNavigation'

export default RestaurantNavigation
