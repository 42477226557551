'use client'

import classnames from 'classnames'
import styles from './NavigationMobileDropdown.module.scss'
import { GlobalSettingsContext } from '@/context/GlobalSettings'
import { useContext } from 'react'
import Link from '@/components/Link/Link'
import NavigationButtons from '@/components/Navigation/NavigationButtons/NavigationButtons'
import useStore from '@/store'
import useBreakpoint from '@/hooks/use-breakpoint'
import useCurrentPage from '@/hooks/use-current-page'

type NavigationMobileDropdownProps = {
  className?: string
  scrollingOverSection?: string | null
  navTheme?: SectionBackground
}

const NavigationMobileDropdown = ({ className, scrollingOverSection, navTheme }: NavigationMobileDropdownProps) => {
  const { navigationData } = useContext(GlobalSettingsContext)
  const { footerData } = useContext(GlobalSettingsContext)
  const navIsOpen = useStore(state => state.navIsOpen)
  const { breakpoint } = useBreakpoint()
  const isAllowed = ['mobile', 'tablet', 'laptop'].includes(breakpoint?.name as string)
  const setNavIsOpen = useStore(state => state.setNavIsOpen)
  const { currentSlug } = useCurrentPage()

  if (!navigationData?.links?.length || !footerData?.legal || !isAllowed) return null
  return (
    <div
      className={classnames(styles.NavigationMobileDropdown, className, {
        [styles.navIsOpen]: navIsOpen,
      })}
      data-scrolling-over-section={scrollingOverSection}
      data-theme={navTheme}
    >
      <div className={styles.inner}>
        <div className={styles.linksAndButtons}>
          <ul className={styles.links}>
            {navigationData.links.map((link, i) => {
              const { link: linkData } = link
              const isLinkInternal = typeof linkData === 'object' && linkData !== null && 'slug' in linkData
              return (
                <li
                  key={i}
                  className={classnames(styles.linkItem, {
                    [styles.linkItemActive]: isLinkInternal && currentSlug === linkData?.slug,
                  })}
                >
                  <Link
                    link={link}
                    onClick={() => {
                      setNavIsOpen(false)
                    }}
                  />
                </li>
              )
            })}
          </ul>
          <NavigationButtons currentScrollingOverSection={scrollingOverSection as string} />
        </div>
        <div className={styles.socialMediaAndLegal}>
          <ul className={styles.socialMediaLinks}>
            {footerData?.socialMediaLinks?.map((item, i) => (
              <li
                key={i}
                className={styles.socialMediaLinks__item}
              >
                <Link
                  link={item}
                  className={styles.socialMediaLinks__link}
                  onClick={() => {
                    setNavIsOpen(false)
                  }}
                />
              </li>
            ))}
          </ul>
          <div className={styles.legalContainer}>
            <p className={styles.legalText}>{footerData.legal}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

NavigationMobileDropdown.displayName = 'NavigationMobileDropdown'

export default NavigationMobileDropdown
