import { imageAsset } from '@/data/sanity/fragments'
import { getMainDataOverrides, getMergedLanguageQueryString } from '@/data/sanity/utils'

export const fields = `
  title,  
  ...(content) {
    ${getMergedLanguageQueryString('Content', [
      'publishedDate',
      'link',
      'source',
      {
        name: 'logo',
        fields: imageAsset.fields,
      },
    ])}
  },
  ${getMainDataOverrides({
    objectSuffix: 'Content',
    mainObjectName: 'content',
    mainDataOverrides: ['title'],
  })}
`

export const fragment = (name: string) => `${name}{
	${fields}
}`

const exports = { fields, fragment }

export default exports
