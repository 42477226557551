'use client'

import classnames from 'classnames'
import styles from './Header.module.scss'
import Icon from '@/components/Icon/Icon'

const Header = ({ item, isOpen, toggleItem, index, headerId, accordionId }: AccordionHeaderProps) => {
  return (
    <div className={styles.Header}>
      <button
        onClick={() => toggleItem(index)}
        aria-expanded={isOpen || false}
        className={classnames(styles.dropdownButton, isOpen && styles.dropdownButtonOpenState)}
        aria-controls={accordionId}
        id={headerId}
      >
        {item.title && (
          <div
            className={styles.title}
            data-accordion-title
          >
            {item.title}
          </div>
        )}
        <div
          className={classnames(styles.iconWrapper, isOpen && styles.iconWrapperOpenState)}
          data-accordion-icon-container
        >
          <Icon
            name={'caretDown'}
            className={styles.icon}
          />
        </div>
      </button>
    </div>
  )
}

Header.displayName = 'Header'

export default Header
