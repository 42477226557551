import sections from './sections'
import { pageMetadataFields } from './_shared'
import { DEFAULT_LANGUAGE, LANGUAGES } from '@/data'

/* eslint-disable */
export const getFields = () => {
  return `
    ${pageMetadataFields},
    "sections": select(
      ${Object.values(LANGUAGES)
        .map(
          lang => `$language == "${lang}" => coalesce(sections.${lang}Sections, sections.${DEFAULT_LANGUAGE}Sections),`,
        )
        .join('')}
    )[] -> {
      ${sections.getFields()}
    }
  `
}
/* eslint-enable */

export const getSections = () => {
  return `
    ${sections.fragment('sections')}   
  `
}

const exported = {
  getFields,
  getSections,
}

export default exported
