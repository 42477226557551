export const fields = `
	_type,
`

export const fragment = (name = 'test') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
