'use client'

import React from 'react'

type GlobalSettingsContextType = {
  navigationData: SanityNavigation | null
  footerData: SanityFooter | null
  isPreviewMode: boolean
  hasSanityPreviewToken: boolean
}

export const GlobalSettingsContext = React.createContext({
  navigationData: null,
  footerData: null,
  isPreviewMode: false,
  hasSanityPreviewToken: false,
} as GlobalSettingsContextType)

GlobalSettingsContext.displayName = 'GlobalSettingsContext'

export const GlobalSettingsProvider = ({
  children,
  footerData,
  navigationData,
  isPreviewMode,
  hasSanityPreviewToken,
}: {
  children: React.ReactNode
  footerData: SanityFooter | null
  navigationData: SanityNavigation | null
  isPreviewMode: boolean
  hasSanityPreviewToken: boolean
}) => {
  return (
    <GlobalSettingsContext.Provider
      value={{
        navigationData,
        footerData,
        isPreviewMode,
        hasSanityPreviewToken,
      }}
    >
      {children}
    </GlobalSettingsContext.Provider>
  )
}

GlobalSettingsProvider.displayName = 'GlobalSettingsProvider'
