import { imageAsset } from '@/data/sanity/fragments'
import { getMainDataOverrides, getMergedLanguageQueryString } from '@/data/sanity/utils'

export const fields = `
  title,  
  ...(content) {
    ${getMergedLanguageQueryString('Content', [
      '_id',
      '_key',
      'slug',
      'status',
      'price',
      'description',
      'collectionName',
      'fitDetails',
      'materialAndCare',
      'availabilityDescription',
      {
        name: 'images',
        isArray: true,
        fields: `
          ...(image) {
            ${imageAsset.fields}
          }
        `,
      },
    ])}
  },
  ${getMainDataOverrides({
    objectSuffix: 'Content',
    mainObjectName: 'content',
    mainDataOverrides: ['title'],
  })}
`

export const fragment = (name: string) => `${name}{
	${fields}
}`

const exports = { fields, fragment }

export default exports
