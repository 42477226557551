'use client'

import { ScrollProvider } from '@/context/Scroll'
import { useEffect } from 'react'
import styles from './Layout.module.scss'
import useStore from '@/store'
import { deviceInfo } from '@/utils'
import Navigation from '@/components/Navigation/Navigation'
import { useParams } from 'next/navigation'
import PreviewButton from '@/components/PreviewButton/PreviewButton'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import MerchandiseSlideout from '@/sections/MerchandiseSlideout/MerchandiseSlideout'
import RestaurantNavigation from '@/components/RestaurantNavigation/RestaurantNavigation'
import ContentWithStickyNavigationMobileMenuContainer from '@/components/ContentWithStickyNavigation/ContentWithStickyNavigationMobileMenuContainer/ContentWithStickyNavigationMobileMenuContainer'
import VideoPopout from '@/components/VideoPopout/VideoPopout'

ScrollTrigger.config({
  ignoreMobileResize: true,
})

gsap.registerPlugin(ScrollTrigger)

export const SCROLL_CONTAINER_CLASS = styles.scrollContainer
export const SCROLL_CONTENT_CLASS = styles.scrollContainerInner

const Layout = ({ children }: SanityLayout) => {
  const canInteract = useStore(state => state.canInteract)
  const setActiveRestaurant = useStore(state => state.setActiveRestaurant)
  const params = useParams()

  useEffect(() => {
    if (!params?.restaurantSlug) {
      setActiveRestaurant(null)
    }
  }, [params, setActiveRestaurant])

  // Set high-level body & document attributes
  useEffect(() => {
    // Device Info
    document.body.dataset.browser = deviceInfo.browser.name
    document.body.dataset.device = deviceInfo.device.type
    document.body.dataset.os = deviceInfo.os.name

    // Language
    document.documentElement.lang = params.language as string

    // Can interact
    document.body.dataset.enableInteraction = `${canInteract}`
  }, [params.language, canInteract])

  return (
    <ScrollProvider>
      <Navigation />
      <RestaurantNavigation />
      <PreviewButton />
      <MerchandiseSlideout />
      <ContentWithStickyNavigationMobileMenuContainer />
      <VideoPopout />
      <main className={SCROLL_CONTAINER_CLASS}>
        <div className={SCROLL_CONTENT_CLASS}>{children}</div>
      </main>
    </ScrollProvider>
  )
}

Layout.displayName = 'Layout'

export default Layout
