import { pageMetadataFields } from '@/data/sanity/fragments/_shared'
import cmsSettings from '../cmsSettings'
import link from '../link'
import pressHighlight from '../pressHighlight'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  sectionBackground,
  title,
  ${link.fragment('cta')},
  items[]->{
    ${pageMetadataFields},
    _type == "pressHighlight" => {
      ${pressHighlight.fields}
    },
  },
  mobileStyle
`

export const fragment = (name = 'threeUp') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
