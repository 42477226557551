import { DEFAULT_LANGUAGE, LANGUAGES, SERVICE_TYPES } from '@/data'

export const getMergedLanguageQueryString = (
  objectSuffix: string,
  fieldsArray: (string | { name: string; fields: string; dereference?: boolean; isArray?: boolean })[],
) => {
  let defaultQueryString = ''
  let localizedQueryString = ''

  fieldsArray.forEach(item => {
    if (typeof item === 'string') {
      defaultQueryString += `${item},`
      localizedQueryString += `defined(${item}) => {"${item}": ${item}},`
    } else if (typeof item === 'object' && item !== null) {
      const arrString = item.isArray ? '[]' : ''
      defaultQueryString += `${item?.dereference ? `${item.name} ${arrString}->` : `${item.name}${arrString}`} {${
        item.fields
      }},`
      localizedQueryString += `
      defined(${item.name}) => {
        ${item?.dereference ? `'${item.name}': ${item.name} ${arrString}->` : `'${item.name}${arrString}': `} {
          ${item.fields}
        }
      },
      `
    }
  })

  const query = `
    ${Object.values(LANGUAGES)
      .map(language => {
        if (language === DEFAULT_LANGUAGE) {
          return `
            $language == "${DEFAULT_LANGUAGE}" => {
              ...(${DEFAULT_LANGUAGE}${objectSuffix}) {
                ${defaultQueryString}
              }
            }
          `
        } else {
          return `
            $language == "${language}" => {
              ...(${DEFAULT_LANGUAGE}${objectSuffix}) {
                ${defaultQueryString}
              },
              ...(${language}${objectSuffix}) {
                ${localizedQueryString}
              }
            }
          `
        }
      })
      .join(',')}
  `

  return query
}

export const getMainDataOverrides = ({
  objectSuffix,
  mainDataOverrides,
  mainObjectName,
}: {
  objectSuffix: string
  mainObjectName: string
  mainDataOverrides: string[]
}) => {
  let query = ''
  if (mainObjectName && mainDataOverrides?.length) {
    query = `
     ${Object.values(LANGUAGES)
       .map(lang => {
         if (lang === DEFAULT_LANGUAGE) return ''
         return `
        ${mainDataOverrides
          .map(overrideString => {
            const objectPath = `${mainObjectName}.${lang}${objectSuffix}.${overrideString}`
            return `
            $language == "${lang}" => {
              defined(${objectPath}) => {"${overrideString}": ${objectPath}}
            },
          `
          })
          .join('')}
      `
       })
       .join('')}
  `
  }

  return query
}

export const getDomainNameFromUrlString = (urlString: string) => {
  const url = new URL(urlString)
  const domain = url.hostname
  return domain
}

export const getServiceNameKeyFromUrl = (url: string) => {
  let serviceNameKey = ''

  if (url.toLowerCase().includes('yelp.c')) {
    serviceNameKey = 'yelp'
  } else if (url.toLowerCase().includes('ubereats') || url.toLowerCase().includes('order.store')) {
    serviceNameKey = 'uberEats'
  } else if (url.toLowerCase().includes('trycaviar')) {
    serviceNameKey = 'caviar'
  } else if (url.toLowerCase().includes('doordash')) {
    serviceNameKey = 'doorDash'
  } else if (url.toLowerCase().includes('grubhub')) {
    serviceNameKey = 'grubHub'
  } else if (url.toLowerCase().includes('postmates')) {
    serviceNameKey = 'postmates'
  } else if (url.toLowerCase().includes('goldbelly.com')) {
    serviceNameKey = 'goldbelly'
  }
  return serviceNameKey
}

export const getServiceLinksByService = (serviceLinks: SanityLocationServiceLinkType[]) => {
  const items: { [key: string]: { url: string; serviceName?: string }[] } = {
    [SERVICE_TYPES.GENERAL_RESERVATION]: [],
    [SERVICE_TYPES.DINING_ROOM]: [],
    [SERVICE_TYPES.BAR_COUNTER]: [],
    [SERVICE_TYPES.CATERING]: [],
    [SERVICE_TYPES.DELIVERY]: [],
    [SERVICE_TYPES.PICKUP]: [],
    [SERVICE_TYPES.RESERVATIONS]: [],
  }

  if (serviceLinks?.length) {
    serviceLinks.forEach(item => {
      if (items[item.serviceType]) {
        const serviceName = getServiceNameKeyFromUrl(item.url)
        items[item.serviceType].push({ url: item.url, serviceName })
      }
    })
  }

  return items
}
