'use client'

import classnames from 'classnames'
import styles from './MerchandiseSlideout.module.scss'
import useStore from '@/store'
import Icon from '@/components/Icon/Icon'
import Accordion from '@/components/Accordion/Accordion'
import useI18n from '@/hooks/use-i18n'
import { useContext, useEffect, useRef, useState } from 'react'
import SanityImage from '@/components/SanityImage/SanityImage'
import { ScrollContext } from '@/context/Scroll'
import { wait } from '@/utils'
import gsap from 'gsap'

type MerchandiseSlideoutProps = {
  className?: string
}

const MerchandiseSlideout = ({ className }: MerchandiseSlideoutProps) => {
  const merchSlideoutData = useStore(state => state.merchSlideoutData)
  const setMerchSlideoutData = useStore(state => state.setMerchSlideoutData)
  const { i18n } = useI18n()
  const accordionData = []
  const [activeImage, setActiveImage] = useState<SanityImage | undefined>(undefined)
  const { scroll } = useContext(ScrollContext)
  const bgRef = useRef<HTMLDivElement | null>(null)
  const innerRef = useRef<HTMLDivElement | null>(null)
  const buttonRef = useRef<HTMLButtonElement | null>(null)
  const lastElementInFocus = useStore(state => state.lastElementInFocus)

  let priceContent = (
    <span>
      {merchSlideoutData?.collectionName && (
        <span className={styles.collectionName}>{merchSlideoutData?.collectionName} - </span>
      )}{' '}
      {merchSlideoutData?.price}
    </span>
  )
  if (merchSlideoutData?.status === 'soldOut') {
    priceContent = <span>{i18n('soldOut')}</span>
  } else if (merchSlideoutData?.status === 'soldOutComingSoon') {
    priceContent = <span>{i18n('soldOutComingBackSoon')}</span>
  } else if (merchSlideoutData?.status === 'comingSoon') {
    priceContent = <span>{i18n('merchComingSoon')}</span>
  }

  const animateContent = (animateOut?: boolean, callback?: () => void) => {
    const duration = animateOut ? 0.3 : 0.6
    const ease = 'Power3.easeOut'
    gsap.killTweensOf([innerRef.current, bgRef.current])
    gsap.to(innerRef.current, {
      x: animateOut ? '105%' : 0,
      duration,
      ease,
    })
    gsap.to(bgRef.current, {
      autoAlpha: animateOut ? 0 : 0.6,
      duration,
      ease,
      onComplete: () => {
        if (!animateOut) {
          if (buttonRef.current) {
            buttonRef.current.focus()
          }
        }
        if (callback) callback()
      },
    })
  }

  const handleCloseClick = () => {
    animateContent(true, () => {
      setMerchSlideoutData(null)
    })
  }

  useEffect(() => {
    const fire = async () => {
      if (!merchSlideoutData) return
      await wait(10)
      animateContent()
    }

    fire()
  }, [merchSlideoutData])

  if (merchSlideoutData?.fitDetails) {
    accordionData.push({
      title: i18n('fitAndDetails') || '',
      description: merchSlideoutData?.fitDetails,
    })
  }

  if (merchSlideoutData?.materialAndCare) {
    accordionData.push({
      title: i18n('materialAndCare') || '',
      description: merchSlideoutData?.materialAndCare,
    })
  }

  useEffect(() => {
    if (!scroll) return
    if (merchSlideoutData) {
      scroll.stop()
    } else {
      scroll.start()
    }
  }, [merchSlideoutData, scroll])

  useEffect(() => {
    setActiveImage(merchSlideoutData?.images[0])
  }, [merchSlideoutData?.images])

  if (!merchSlideoutData) return null

  return (
    <div className={classnames(styles.MerchandiseSlideout, className)}>
      <div
        className={styles.bg}
        ref={bgRef}
        onClick={handleCloseClick}
      />
      <div
        className={styles.inner}
        ref={innerRef}
      >
        <div
          className={styles.content}
          data-lenis-prevent
        >
          <button
            ref={buttonRef}
            className={styles.closeButton}
            onClick={handleCloseClick}
          >
            <Icon
              name="close"
              className={styles.closeIcon}
            />
          </button>
          <div className={styles.textSide}>
            <h1 className={styles.title}>{merchSlideoutData.title}</h1>
            <p className={styles.price}>{priceContent}</p>
            {merchSlideoutData.description && <p className={styles.description}>{merchSlideoutData.description}</p>}
            {accordionData?.length > 0 && (
              <Accordion
                className={styles.accordion}
                items={accordionData}
              />
            )}
            {merchSlideoutData?.availabilityDescription && (
              <p className={styles.availabilityDescription}>{merchSlideoutData?.availabilityDescription}</p>
            )}
          </div>
          <div className={styles.imagesSide}>
            <div className={styles.imagesSideInner}>
              <div className={styles.activeImageContainer}>
                {activeImage && (
                  <SanityImage
                    className={styles.activeImage}
                    image={activeImage}
                    breakpoints={{
                      tablet: {
                        width: 1100,
                        image: activeImage,
                      },
                      xs: {
                        width: 800,
                        image: activeImage,
                      },
                    }}
                  />
                )}
              </div>
              <ul className={styles.imagesList}>
                {merchSlideoutData?.images.map((image, i) => {
                  return (
                    <li
                      key={i}
                      className={classnames(styles.imagesList__item, {
                        [styles.isActive]: activeImage?.asset?.url === image?.asset?.url,
                      })}
                      onClick={() => {
                        setActiveImage(image)
                      }}
                    >
                      <button
                        className={styles.imagesList__itemButton}
                        aria-label={i18n('ariaViewImageNumber', { number: i + 1 })}
                      >
                        <SanityImage
                          image={image}
                          className={styles.imagesList__image}
                          breakpoints={{
                            tablet: {
                              width: 140,
                              image: image,
                            },
                            xs: {
                              width: 100,
                              image: image,
                            },
                          }}
                        />
                      </button>
                    </li>
                  )
                })}
              </ul>
              <button
                className={styles.focusFix}
                onFocus={() => {
                  handleCloseClick()
                  if (lastElementInFocus) {
                    lastElementInFocus.focus()
                  }
                }}
              >
                &nbsp;
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

MerchandiseSlideout.displayName = 'MerchandiseSlideout'

export default MerchandiseSlideout
