import cmsSettings from '../cmsSettings'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  sectionBackground,
`

export const fragment = (name = 'giftCardIframe') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
