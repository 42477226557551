export default function X({ className }: SvgProps) {
  return (
    <svg
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.39758 5.71698L17.2541 18.4449L17.7284 22.2132L18.3312 17.3677L5.47476 4.6398L5.00045 1.00004L4.39758 5.71698Z"
        fill="currentColor"
      />
      <path
        d="M4.63977 17.5257L17.4963 4.6692L17.9706 1.02944L18.5734 5.74638L5.71695 18.6029L5.24264 22.2426L4.63977 17.5257Z"
        fill="currentColor"
      />
    </svg>
  )
}
