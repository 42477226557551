import { imageAsset, link, videoAsset } from '@/data/sanity/fragments'
import cmsSettings from '../cmsSettings'
import pressRelease from '../pressRelease'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  sectionBackground,
  mainContent,
  ${imageAsset.fragment('image')},
  ${videoAsset.fragment('videoLoopDesktop')},
  ${videoAsset.fragment('videoLoopMobile')},
  title,
  description,
  ${link.fragment('cta')},
  items[]{
    contentType,
    title,
    description,
    pressReleases[]->{
      ${pressRelease.fields}
    }
  }
`

export const fragment = (name = 'textAndAccordion') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
