export default function HeaderLogo({ className }: SvgProps) {
  return (
    <svg
      version="1.1"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 163 64"
    >
      <path
        fill="currentColor"
        d="M18.6,57.3c-0.7-0.5-1.6-0.7-3-0.7c-1.1,0-3,0.2-3,0.5c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.4-0.1,0.5-0.1c0.2,0,0.4,0.2,0.4,0.6
	v4.9c0,0.5,0,0.8-0.6,0.8c-0.2,0-0.4,0-0.4,0.2c0,0.2,0.1,0.2,0.2,0.2c0.1,0,1.1,0,1.4,0c0.3,0,1.8,0,2.1,0c3,0,3.8-2.4,3.8-3.6
	C20.1,59.1,19.5,58,18.6,57.3 M15.9,63.5c-0.9,0-1.1-0.4-1.1-1.1v-4.6c0-0.6,0.1-0.9,0.8-0.9c2.4,0,3.2,2.1,3.2,3.4
	C18.7,62.1,17.4,63.5,15.9,63.5"
      />
      <path
        fill="currentColor"
        d="M23.5,62.6c0,0.5,0,0.8,0.6,0.8c0.3,0,0.4,0,0.4,0.2c0,0.2-0.2,0.2-0.2,0.2c-0.2,0-0.6,0-1.4,0c-0.7,0-1.2,0-1.3,0
	c-0.1,0-0.2,0-0.2-0.2c0-0.2,0.2-0.2,0.4-0.2c0.6,0,0.6-0.3,0.6-0.8v-4.8c0-0.5,0-0.8-0.6-0.8c-0.3,0-0.4,0-0.4-0.2
	c0-0.2,0.1-0.2,0.2-0.2c0.2,0,0.7,0,1.4,0c0.8,0,1.2,0,1.4,0c0.1,0,0.2,0,0.2,0.2c0,0.2-0.2,0.2-0.4,0.2c-0.6,0-0.6,0.3-0.6,0.8
	V62.6z"
      />
      <path
        fill="currentColor"
        d="M32,61.9l0-3.5c0-1.4-0.1-1.4-0.6-1.4c-0.3,0-0.5,0-0.5-0.2c0-0.2,0.2-0.2,0.3-0.2c0.1,0,0.8,0,0.9,0c0.2,0,1,0,1.2,0
	c0.1,0,0.2,0,0.2,0.2c0,0.1-0.1,0.2-0.4,0.2c-0.4,0-0.6,0.1-0.6,0.7l0,6c0,0.1,0,0.3-0.2,0.3c-0.1,0-0.2,0-0.4-0.3L27,57.7l0,0
	l0,3.9c0,1.7,0.2,1.8,0.7,1.8c0.5,0,0.5,0,0.5,0.2c0,0.2-0.2,0.2-0.3,0.2c-0.1,0-0.6,0-0.9,0c-0.6,0-1.1,0-1.3,0c0,0-0.2,0-0.2-0.2
	c0-0.1,0-0.1,0.5-0.2c0.5-0.1,0.6-0.1,0.6-1.7v-3.8c0-0.9-0.1-1-0.6-1c-0.2,0-0.4,0-0.4-0.2c0-0.1,0.1-0.2,0.4-0.2c0.1,0,0.6,0,1,0
	c0.3,0,0.6,0,0.7,0c0.2,0,0.3,0.2,0.4,0.3L32,61.9L32,61.9L32,61.9z"
      />
      <path
        fill="currentColor"
        d="M81.5,62.4c0,0.4,0,0.7,0.5,0.7c0.2,0,0.4,0,0.4,0.2s-0.1,0.2-0.2,0.2c-0.3,0-0.9,0-1.2,0c-0.2,0-1,0-1.2,0
	c-0.1,0-0.2,0-0.2-0.2c0-0.1,0.1-0.2,0.4-0.2c0.5,0,0.5-0.3,0.5-0.7v-4.8c0-0.3-0.1-0.3-0.3-0.3c-0.6,0-1.3,0-1.8,1
	c-0.1,0.2-0.1,0.2-0.2,0.2c-0.1,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0-0.2l0.4-1.4c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.1,0.1,0.1,0.1
	c0.1,0.3,0.2,0.3,0.5,0.3h3.3c1.1,0,1.1-0.1,1.2-0.3c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0.2,0.1,0.4l-0.1,1.3c0,0.2,0,0.3-0.2,0.3
	c-0.1,0-0.1,0-0.2-0.2c-0.2-1-0.7-1-1.3-1c-0.6,0-0.6,0-0.6,0.3V62.4L81.5,62.4z"
      />
      <path
        fill="currentColor"
        d="M89.9,63.2c-0.4-0.1-0.5-0.3-0.9-1.1l-1.6-3.9c-0.1-0.2-0.2-0.5-0.3-0.8C87,57,87,56.9,86.8,56.9c-0.1,0-0.1,0-0.5,1
	c-0.7,1.8-1.6,4.2-1.9,4.8c-0.2,0.4-0.3,0.4-0.6,0.4c-0.2,0-0.3,0-0.3,0.2c0,0.2,0.1,0.2,0.2,0.2c0.1,0,0.7,0,0.9,0c0.3,0,0.9,0,1,0
	c0.2,0,0.4,0,0.4-0.1c0-0.1-0.1-0.2-0.4-0.2c-0.2,0-0.5,0-0.5-0.3c0-0.1,0.1-0.4,0.1-0.4l0.3-1.1c0.1-0.2,0.2-0.3,0.4-0.3h1.2
	c0.3,0,0.3,0,0.4,0.3l0.4,1.2c0,0,0.1,0.2,0.1,0.3c0,0.2-0.1,0.2-0.4,0.2c-0.2,0-0.4,0.1-0.4,0.2c0,0.1,0.1,0.1,0.2,0.1
	c0,0,0.6,0,1.2,0c0.2,0,1,0,1.2,0c0.1,0,0.2,0,0.2-0.1C90.2,63.2,90.1,63.2,89.9,63.2 M87,60.7h-1c-0.2,0-0.2,0-0.2-0.1
	c0-0.1,0-0.2,0-0.2l0.6-1.7l0.7,1.8c0,0.1,0.1,0.1,0.1,0.2C87.2,60.7,87.2,60.7,87,60.7"
      />
      <path
        fill="currentColor"
        d="M92.7,62.4c0,0.4,0,0.7,0.5,0.7c0.2,0,0.4,0,0.4,0.2c0,0.1-0.1,0.1-0.2,0.1c-0.2,0-0.6,0-1.2,0c-0.6,0-1.1,0-1.2,0
	c-0.1,0-0.2,0-0.2-0.2c0-0.1,0.2-0.2,0.4-0.2c0.5,0,0.5-0.3,0.5-0.7v-4.3c0-0.4,0-0.7-0.5-0.7c-0.2,0-0.4,0-0.4-0.2
	c0-0.1,0.1-0.1,0.2-0.1c0.2,0,0.6,0,1.2,0c0.7,0,1.1,0,1.2,0c0.1,0,0.2,0,0.2,0.2c0,0.1-0.2,0.2-0.4,0.2c-0.5,0-0.5,0.3-0.5,0.7
	V62.4z"
      />
      <path
        fill="currentColor"
        d="M132.8,57.8c0-0.5,0-0.8-0.6-0.8c-0.2,0-0.4,0-0.4-0.2c0-0.2,0.1-0.2,0.2-0.2c0,0,0,0,0.3,0c0.3,0,0.9,0,2,0h0.1
	c1.2,0,1.8,0,2.1,0c0.3,0,0.3,0,0.3,0c0.2,0,0.2,0.1,0.2,0.2v1.2c0,0,0,0.2-0.1,0.2s-0.2-0.2-0.2-0.2c-0.3-0.6-0.5-1-2.1-1
	c-0.4,0-0.6,0-0.6,0.6v1.5c0,0.6,0,0.6,0.2,0.6c0.2,0,0.5,0,0.7,0c0.9,0,1-0.3,1.2-0.8c0-0.1,0.1-0.2,0.2-0.2c0.1,0,0.1,0.1,0.1,0.2
	c0,0.2,0,0.9,0,1.1c0,0.1,0,0.3,0,0.6l0,0.5c0,0.1,0,0.2-0.1,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1-0.1-0.4-0.1-0.4
	c-0.2-0.6-0.9-0.6-1.2-0.6c-0.6,0-0.8,0.2-0.8,0.5v1.9c0,0.4,0,0.8,0.5,0.8c0.3,0,0.5,0,0.5,0.2c0,0.2-0.1,0.2-0.2,0.2
	c-0.4,0-1,0-1.4,0c-0.4,0-1,0-1.4,0c-0.1,0-0.2,0-0.2-0.2c0-0.2,0.2-0.2,0.4-0.2c0.6,0,0.6-0.3,0.6-0.8L132.8,57.8L132.8,57.8z"
      />
      <path
        fill="currentColor"
        d="M144.1,58.1c0-1-0.1-1.1-0.7-1.1c-0.2,0-0.4,0-0.4-0.2c0-0.1,0.2-0.1,0.3-0.1c0.2,0,0.4,0,1,0c0.4,0,0.9,0,1.1,0
	c0.2,0,0.2,0.1,0.2,0.2c0,0.2-0.2,0.2-0.4,0.2c-0.5,0-0.6,0.1-0.6,1v3.5c0,0.6,0,2.3-2.5,2.3c-1.5,0-3-0.4-3-2.3v-3.7
	c0-0.5,0-0.8-0.6-0.8c-0.2,0-0.4,0-0.4-0.2s0.2-0.2,0.2-0.2c0.1,0,0.6,0,1.3,0s1.3,0,1.4,0c0.1,0,0.2,0,0.2,0.2
	c0,0.2-0.2,0.2-0.4,0.2c-0.6,0-0.6,0.3-0.6,0.8v3.6c0,0.8,0,2.2,1.9,2.2c1.8,0,1.9-1.2,1.9-1.9L144.1,58.1L144.1,58.1z"
      />
      <path
        fill="currentColor"
        d="M152.8,61.9l0-3.5c0-1.4-0.1-1.4-0.6-1.4c-0.3,0-0.5,0-0.5-0.2s0.2-0.2,0.3-0.2c0.1,0,0.8,0,0.9,0c0.2,0,1,0,1.2,0
	c0.1,0,0.2,0,0.2,0.2c0,0.1-0.1,0.2-0.4,0.2c-0.4,0-0.6,0.1-0.6,0.7l0,5.9c0,0.1,0,0.3-0.2,0.3c-0.1,0-0.2,0-0.4-0.3l-4.8-6l0,0
	l0,3.9c0,1.6,0.1,1.8,0.7,1.8c0.5,0,0.5,0,0.5,0.2c0,0.2-0.2,0.2-0.3,0.2c-0.1,0-0.6,0-0.9,0c-0.6,0-1.1,0-1.3,0c0,0-0.2,0-0.2-0.1
	c0-0.1,0-0.1,0.5-0.2c0.5-0.1,0.5-0.1,0.5-1.7V58c0-0.9-0.1-1-0.6-1c-0.2,0-0.4,0-0.4-0.2c0-0.1,0.1-0.2,0.3-0.2c0.1,0,0.6,0,1,0
	c0.3,0,0.6,0,0.7,0c0.2,0,0.3,0.2,0.4,0.3L152.8,61.9L152.8,61.9L152.8,61.9z"
      />
      <path
        fill="currentColor"
        d="M160.6,61.3c0-0.6-0.2-0.7-0.7-0.7c-0.3,0-0.4-0.1-0.4-0.2c0-0.1,0.1-0.1,0.5-0.1c0.2,0,0.5,0,1.3,0c0.2,0,1.3,0,1.4,0
	c0,0,0.2,0,0.2,0.1c0,0.1-0.1,0.2-0.4,0.2c-0.5,0-0.6,0.2-0.6,0.7v0.1c0,0.1,0,0.5,0,1.1c0,0.5,0,0.5-0.3,0.7
	c-1,0.6-2.1,0.6-2.4,0.6c-3.2,0-3.9-2.5-3.9-3.6c0-1.7,1.4-3.7,4.4-3.7c1.1,0,1.7,0.3,1.8,0.4c0.1,0.1,0.2,1,0.2,1.1
	c0,0.1-0.1,0.2-0.1,0.2c-0.1,0-0.2-0.1-0.3-0.3c-0.2-0.3-0.6-1.1-1.9-1.1c-1.8,0-2.8,1.9-2.8,3.4c0,1.7,1,3.2,2.8,3.2
	c1,0,1.2-0.4,1.2-1.3L160.6,61.3L160.6,61.3z"
      />
      <path
        fill="currentColor"
        d="M44.6,49.1c0,0-0.1,0-0.1,0.1c0,0,0,0,0,0c0-0.2,0-0.2,0.2-0.3c0-0.1,0-0.1-0.1-0.2c0,0,0-0.1-0.1-0.1
	c-0.1,0-0.1-0.1-0.2-0.1c0,0,0-0.1,0-0.1c0,0-0.1,0-0.1-0.1c-0.1,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0.2,0.2,0.2c0,0,0-0.1,0-0.1
	c0,0,0.1,0,0.1-0.1c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.3-0.2-0.2c-0.2,0-0.3-0.1-0.4-0.2c0,0-0.1,0-0.1,0h0c0,0,0.1-0.1,0.1-0.2
	c0.1,0,0.1,0,0.2,0c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0v0c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1,0-0.1-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.2
	c0-0.1-0.2,0-0.2,0v0c0,0,0,0,0-0.1h0v0c0,0,0,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2-0.1c0,0,0-0.1-0.1-0.2c0,0-0.1,0-0.1-0.1
	c-0.1,0-0.1,0-0.2,0h0c0,0.1-0.5,0-0.5,0c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0
	c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2-0.1-0.3-0.1h0v0c-0.2,0-0.3,0.1-0.5,0.2c-0.1,0-0.2,0-0.3,0c0,0,0,0-0.1,0
	c-0.1,0-0.1,0-0.1,0.1c-0.1,0.1-0.2,0.1-0.2,0.2c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0.1,0c0,0.2-0.1,0.1-0.2,0.1v0h0v0.1
	c0,0-0.2-0.1-0.2-0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0c-0.1,0-0.3,0-0.3,0.1c-0.2,0-0.4,0-0.5-0.1h0c0,0,0,0-0.1,0.1
	c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
	c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1,0c-0.2,0-0.4,0-0.6-0.2h0c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0
	c-0.1,0-0.2-0.1-0.3-0.1c-0.2,0-0.3-0.1-0.4-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.1c-0.1,0-0.2,0-0.3-0.1c0,0-0.1,0-0.1,0
	c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.3,0-0.4-0.1h0c0,0,0,0,0,0c0,0.1-0.2-0.1-0.3-0.1c0,0-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1
	c0,0-0.1,0-0.1-0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1-0.1-0.2-0.1c0-0.1-0.1-0.1-0.1-0.2
	c-0.1-0.1-0.2-0.1-0.2-0.1c0,0,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1h0c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1,0-0.1
	c0-0.1-0.1,0-0.2,0c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2h0c0,0,0,0-0.1,0c-0.1,0,0-0.2,0-0.3c0-0.1-0.1-0.2-0.2-0.2c0,0,0-0.1,0-0.1
	c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.1c0-0.1,0-0.2,0-0.3c0-0.1-0.3-0.5-0.1-0.6v0
	c0,0,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.2,0-0.3c0,0,0,0,0-0.1c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.1
	c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1-0.1-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1,0-0.1c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1
	c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.3,0-0.4h0v0
	c0,0,0,0.1-0.1,0.1v0c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1
	c0,0,0-0.1-0.1-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3
	c0.1,0,0.1,0,0.2,0c0,0,0.2,0,0.3-0.1v0c0.1,0,0.3,0.2,0.3,0.1c0,0,0-0.1,0-0.1c0-0.1,0.1,0,0.1,0.1h0c0,0,0.1,0,0.1,0
	c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.2,0.1c0,0,0-0.1,0-0.1h0v0c0,0,0,0,0.1,0c0.1,0.1,0.2,0.2,0.3,0.1c0,0,0,0,0,0.1
	c0,0,0,0,0.1,0c0,0.1,0.2,0.1,0.3,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0,0-0.1,0.1-0.1h0c0.1,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
	c0,0,0,0,0,0c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2-0.1c0,0,0.1,0,0.1,0.1c0.1,0,0.2,0,0.3-0.1c0,0,0.2,0,0.2,0c0,0,0,0,0,0
	c0.1-0.1,0.3,0.1,0.5,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0.1c0,0,0.2,0,0.3,0
	c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0c0,0.1,0.1,0.1,0.2,0c0.1,0,0.1,0.2,0.2,0.2c0.1,0,0.2,0,0.2,0c0,0,0.1,0,0.1,0
	c0,0,0.1,0,0.1,0c0.1,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0,0.2c0,0-0.2,0.1-0.2,0.1C36,38.5,36,38.5,36,38.6c-0.1,0.1,0.1,0.5,0,0.5v0
	c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.2,0.1,0.3,0.1,0.4c0,0.1,0,0.3,0,0.4c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0h0v0
	c0,0-0.2,0.2-0.2,0.2c0,0-0.1,0-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0-0.1,0.1
	c0,0,0,0.1-0.1,0.1c0,0.1,0,0.1,0,0.2c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.1-0.1,0.2c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.1-0.1,0.2
	c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.2-0.1,0.2c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.1c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.1,0.1,0.1
	c0,0.1,0,0.1,0,0.2c-0.1,0-0.1,0.5,0,0.5c0,0,0.1,0,0.1,0v0.1h0c0,0-0.1-0.1-0.2-0.1h0v0c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1l0,0
	c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0,0,0.1,0.1,0.1c0,0.1,0.1,0.2,0.1,0.2c0,0,0,0.1,0.1,0.1
	c0,0,0,0.1,0.1,0.1c0,0,0.1,0,0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.1c0.1,0,0.2,0,0.2-0.1h0v0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2-0.1
	c0,0,0,0,0,0v0c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0
	l0.1,0c0,0,0,0,0-0.1c0.1-0.1,0.1,0,0.2,0.1c0,0,0.1,0,0.1,0h0c0,0,0-0.1,0-0.1c0.1,0,0.3-0.2,0.3-0.2c0-0.1,0-0.2,0-0.2v0
	c0.1,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1,0c0,0,0-0.1,0-0.1c0.1,0,0.1,0.2,0,0.2v0h0v0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.1,0.1,0.2,0.1
	c0.1,0,0.2,0,0.2,0c0,0,0.1-0.2,0.1-0.2c0,0,0.1,0,0.1-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0.1,0,0.1,0,0.2,0
	c0.1,0,0.2-0.1,0.2-0.2c0,0,0.1-0.2,0.1-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.3c0.1,0,0.1,0,0.1,0v0h0c0,0-0.1,0-0.1,0v0
	c0,0-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0-0.1-0.1-0.1-0.2-0.2h0c0,0,0.1,0,0.1,0v0h0c0,0,0-0.1,0-0.1h0c0,0,0,0,0,0c0,0,0,0.1,0,0.1v0
	c0,0,0.1,0,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c0-0.1-0.1-0.2-0.1-0.3c0,0,0,0,0.1,0c0,0,0-0.1,0-0.1h0c0,0-0.1,0-0.1,0
	c-0.1,0-0.1-0.1,0-0.1c0,0,0.1,0,0.1,0c0,0,0.2-0.3,0.2-0.4c0-0.1-0.1,0-0.2-0.1c0,0,0,0-0.1,0c0,0-0.1,0-0.2,0c0,0,0-0.1,0-0.1
	c-0.1,0,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0.1,0,0.2,0,0.3-0.1c0,0,0-0.1,0-0.1c0,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.1,0.1-0.2
	c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0.1-0.1v0c0,0-0.1-0.2-0.1-0.3c0,0,0.1,0,0.1,0v0h0v0h0v0c0,0-0.1,0-0.1,0
	c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.2c0,0,0,0,0-0.1c0,0,0-0.1,0-0.2c0,0-0.1,0-0.1,0v0c0,0,0-0.1,0-0.1h0v0c0,0,0.1,0,0.1,0
	c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.2c0-0.1,0-0.5,0-0.6c0,0,0.1,0,0.1,0v0h0v0c0,0,0,0,0,0
	c0-0.1,0-0.4-0.1-0.5v0h0c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.3,0.2v0c0,0,0,0,0,0h0v0h0v0c0,0,0,0,0.1,0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0
	c-0.1,0-0.1-0.1-0.2-0.2c0,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.1,0.1c0,0,0,0-0.1,0c-0.1-0.1,0.2-0.3,0.3-0.3c0,0,0.1,0,0.1,0c0,0,0,0,0,0
	c0-0.1,0-0.6,0.2-0.4c0,0,0.1,0,0.1,0c0.1,0,0.3,0,0.3-0.2c0,0,0.1,0,0.1,0c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0
	c-0.1,0.1-0.1-0.1-0.2-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0.1,0.1c0.1,0,0.1-0.2,0.1-0.3h0c0,0-0.1,0-0.1,0v0c0,0-0.1,0-0.1,0
	c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c-0.1-0.1,0-0.2,0-0.3h0c0,0,0.1,0.1,0.1,0.1c0.1,0,0-0.2,0-0.3v0c0,0,0,0-0.1,0c0-0.1,0-0.1,0-0.2
	c0-0.1,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1v0h0c0,0,0,0,0,0.1c0,0.1,0,0.2,0,0.3h0
	c0-0.1,0-0.3-0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2-0.1-0.3c0-0.2,0-0.2-0.2-0.3c0,0-0.1,0-0.1,0
	c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1h0c0,0-0.1,0-0.1,0c0-0.1-0.1,0-0.1,0c0,0,0,0,0,0
	c0,0,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.2
	c-0.1-0.1-0.1-0.1-0.2-0.2c0,0-0.1,0-0.1,0h0v0c0,0,0,0,0,0h0c0,0-0.1,0-0.1,0h-0.1c-0.1,0-0.2,0-0.2,0v0c0,0-0.1-0.1-0.1-0.1
	c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.3,0c-0.1,0-0.1,0-0.2,0c0,0,0,0.1-0.1,0.1c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0,0-0.2-0.1-0.3,0
	c-0.1,0-0.2,0-0.3-0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.3,0.1c-0.1,0-0.1,0-0.2,0
	c0,0,0,0,0-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.3-0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.3,0
	c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1,0-0.2,0.1c0,0,0,0,0,0c-0.1,0-0.2-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0c0,0.1-0.1,0-0.1,0c0,0,0,0,0,0
	c-0.1,0-0.2,0-0.3,0.1v0h0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0c0.1,0.1,0.2,0,0.3,0.1c0,0.1-0.1,0.1-0.2,0.1
	c0,0-0.1,0-0.1,0h0c0,0,0,0-0.1,0.1c-0.2,0.1-0.4-0.1-0.6-0.2v0c-0.1,0-0.2,0.1-0.2,0.1v0c-0.1,0-0.1-0.1-0.2,0c0,0-0.1,0-0.1,0
	c0,0,0,0.1,0,0.1h0v0c0,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1v0c0,0-0.1,0-0.1,0.1c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
	c0,0,0,0-0.1-0.1c0,0-0.1,0-0.1,0c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0-0.1-0.1-0.1-0.1-0.2
	c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.2,0-0.2c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.3,0-0.4c0-0.1-0.1-0.2,0-0.2
	c0-0.1,0-0.2,0-0.3l0-0.2c0,0,0-0.1-0.1-0.1c0,0-0.1,0-0.1,0v0c0,0,0,0,0-0.1c0-0.1-0.1-0.1-0.1-0.1c0-0.1,0.1-0.1,0.1-0.2
	c0-0.1,0.1-0.2,0-0.3c0-0.1-0.1-0.2-0.1-0.3c0-0.2,0-0.4,0-0.6c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.2-0.1-0.3
	c0,0,0-0.1,0-0.1c0,0,0,0-0.1,0c0,0,0,0,0-0.1c0-0.1,0-0.2,0.1-0.2c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1-0.1,0.2-0.1
	c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0,0,0,0,0.1,0
	c0,0,0-0.1,0-0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.2,0c0.1,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.2,0.2-0.1c0,0,0.1,0,0.1,0.1
	c0,0,0.3,0,0.3-0.1c0-0.1,0-0.2,0.1-0.2h0l0,0.1c0,0.1,0,0.1,0,0.2h0v0h0c0,0,0.1-0.1,0.1-0.1c0.1,0,0.3,0,0.4-0.1c0,0,0.1,0,0.1,0
	v0c0,0,0-0.1,0-0.1h0c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0,0,0-0.1,0-0.1c0.1,0,0.1,0.1,0.2,0.1
	c0.2,0,0.5-0.1,0.6-0.2c0,0,0.1-0.1,0.1,0h0c0,0,0,0.1,0,0.1H33c0,0,0-0.1,0-0.1v0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.1
	c0,0,0.1,0,0.1,0c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0.1,0.1c0,0,0.1,0,0.1-0.1c0.1,0,0.1,0,0.2,0v0
	c0,0-0.1,0.1-0.1,0.2c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0,0.1,0.1,0.1,0.2,0.1c0,0,0,0,0,0l0,0c0,0,0.1,0,0.2,0.1
	c0,0,0.1,0.1,0.1,0.1c0,0,0.2-0.1,0.2-0.1c0.1,0,0.1,0,0.2-0.1c0.1,0,0.2,0,0.2-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0
	c0,0,0,0,0.1,0c0,0,0,0,0,0h0c0,0,0.1,0.1,0.2,0c0,0,0,0,0.1,0v0c0.1,0,0.1-0.1,0.2-0.2v0c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.1-0.1v0
	c0,0,0,0,0,0c0,0.1,0.1,0.1,0.2,0.1c0-0.1,0-0.1,0.1-0.2v0c0.1,0,0.2,0,0.3-0.1c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.1,0
	c0.1,0,0.2-0.1,0.3,0c0.1,0,0.4,0,0.4-0.1v0c0.1,0,0.1,0,0.2-0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1c0.1,0,0.1,0,0.2-0.1
	c0,0,0,0,0,0c0-0.1,0-0.2,0.1-0.2c0.1,0,0.1,0,0.2,0l0.1,0c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.2,0.2-0.2c0,0,0-0.1,0-0.1
	c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.1,0-0.2c-0.1,0-0.1-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0
	c0.2-0.2,0-0.2-0.1-0.4c0,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2-0.1-0.3c0,0-0.1,0-0.1,0c0,0-0.1-0.1-0.1-0.2c0-0.1,0.1-0.2,0-0.3
	c0,0,0,0,0,0c0-0.1-0.1-0.1-0.2-0.2c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1
	c0-0.1,0-0.1-0.1-0.2c0,0-0.1,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.3-0.1-0.4-0.1
	c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1-0.2-0.1-0.2c-0.1,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1,0c0,0-0.1-0.1-0.1-0.1
	c-0.1-0.1-0.2-0.1-0.3-0.2c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1
	c-0.1,0-0.2,0.1-0.3,0c0,0,0-0.1,0-0.1c0-0.1,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0.1-0.1,0.2-0.2,0.3-0.2c0,0,0.1,0,0.1,0
	c0,0,0,0,0-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0-0.1,0-0.1c0,0,0,0,0.1,0c0,0,0-0.1,0-0.1
	c-0.1-0.1,0-0.3,0.1-0.4c0-0.1,0-0.3,0-0.4c0,0,0-0.1,0-0.1l0-0.1c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2
	c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.3,0-0.4c0-0.1,0-0.3,0-0.4c0-0.1,0-0.1,0-0.2
	c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2h0c0,0,0,0,0,0v0
	c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.4,0-0.3c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2h0c0,0,0,0,0,0c0,0,0,0,0,0.1
	h0c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1c0.1,0,0.1,0,0.2-0.1c0,0,0-0.1,0-0.2c0-0.2,0.1-0.4,0.1-0.6c0-0.1,0-0.3,0-0.4
	c0-0.1-0.1-0.2,0-0.3c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.2c0-0.1,0-0.1,0.1-0.1c0-0.1,0-0.1,0-0.2c0-0.2,0-0.3,0-0.5
	c0-0.1,0.1-0.3,0.2-0.3c0,0,0,0,0,0.1c0.1,0,0.1-0.1,0.1-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1h0c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1
	c-0.1,0-0.1,0-0.2,0c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1,0-0.1c0-0.2,0-0.4-0.1-0.5c0-0.1-0.1-0.1-0.1-0.2
	c0,0,0-0.1,0-0.1c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1,0-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2-0.1-0.2c0,0,0-0.1-0.1-0.1
	c0-0.1,0-0.2,0-0.3c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.2,0c0,0,0-0.1,0-0.1h0c0,0,0,0.1,0,0.1c-0.1,0-0.3-0.1-0.3-0.1v0h0c0,0,0,0,0,0
	c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.3,0c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0c-0.1,0.1-0.1,0.2-0.1,0.3c0,0-0.1,0-0.1,0
	c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.1-0.4,0.1-0.5,0.3c-0.1,0.1-0.1,0.2-0.1,0.3c0,0-0.1,0.1-0.1,0.1c0,0.1-0.1,0.2-0.1,0.2
	c0,0,0,0.1-0.1,0.1c0,0.1-0.1,0.2-0.1,0.2c-0.1,0.1-0.2,0.2-0.2,0.4s-0.1,0.3-0.1,0.4c0,0.1-0.1,0.2-0.1,0.4c0,0,0,0.1,0,0.1
	c0,0.1,0,0.2-0.1,0.3c0,0.1,0,0.3-0.1,0.3c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0,0.2
	c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.1c0,0.1-0.1,0.1,0,0.2c0,0.1,0.1,0.1,0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.3,0,0.5
	c0,0.1,0,0.1,0,0.2c0,0.2,0,0.3,0,0.5c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.4c0,0.1,0,0.2,0,0.2
	c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0.3,0,0.5-0.1,0.7c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0.1-0.1,0.2,0,0.3c0,0,0,0.1,0,0.1
	c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.1-0.1,0.2c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3c0,0-0.1,0.1-0.1,0.1
	c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0.1,0.3c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.2,0.1,0.3c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0
	c0,0,0,0,0,0c0.1,0,0.1,0,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1c0,0.1,0,0.1,0.1,0.1c0,0,0.2,0.1,0.2,0.1c0,0-0.1,0-0.1,0.1
	c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0.1-0.1,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c0,0.1,0,0.1-0.1,0.1v0
	c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.3,0.1c-0.1,0.1-0.1,0.1-0.3,0.1c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.2,0-0.3,0.1
	c-0.1,0-0.2,0-0.3,0.2c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.1,0.1v0h0c0,0,0,0,0,0v0c0,0,0,0,0,0h0c0,0-0.2,0-0.2,0.1c0,0,0,0-0.1,0h0
	c0,0-0.2,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0.1-0.3,0
	c0,0-0.3,0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.1,0-0.2,0-0.2,0.1c-0.1,0-0.1,0-0.2,0
	c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c0,0-0.2,0.1-0.3,0.1v0c0,0-0.1,0-0.1-0.1c0,0,0,0-0.1,0.1c0,0-0.1,0-0.1,0
	c0,0-0.1,0-0.1,0h0c0,0,0,0,0,0c-0.1,0-0.2,0-0.2,0.1c0,0,0,0.1,0,0.1c0,0.2-0.1,0.3-0.3,0.3c0,0,0-0.1,0-0.1c0,0,0,0,0,0v0h0
	c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3,0.5-0.5,0.6h0c0,0.1,0,0.2-0.1,0.2c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1-0.1,0.1
	c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0.1c0,0,0,0.1,0,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0-0.1,0h0c0,0.1,0,0.1-0.1,0.1c0,0.1-0.1,0.2,0,0.3
	h0.1c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.2,0.2-0.2,0.3c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0.2-0.1,0.3,0.1,0.4c0,0.1,0,0.2,0,0.2
	c0,0.1,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1,0,0.1v0c0,0,0,0,0,0c0,0.1,0,0.2,0,0.3
	c0,0.2-0.1,0.5,0.1,0.6c0,0.1,0,0.3-0.1,0.4v0c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.1
	c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0.1,0.3c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1
	c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0.1,0,0.2,0,0.3c0,0.1,0.1,0.1,0.1,0.2c0,0.2,0,0.3,0,0.5
	c0,0.1,0.1,0.2,0.1,0.4c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0.1,0.2c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2
	c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.4c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.2,0.1,0.2
	c0,0.1,0,0.2,0,0.2c0,0,0,0.1,0.1,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1
	c0,0.1,0.1,0.2,0,0.2c0,0,0,0-0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2c0,0,0,0-0.1,0c0,0,0,0.1,0,0.1
	c0,0,0,0.1,0.1,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3
	c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0.1,0.1c-0.1,0,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2l0,0.2
	c0,0.1,0,0.2,0,0.2c0,0.2,0,0.3,0,0.5c0,0.2,0,0.3,0,0.5c0,0.1,0,0.2,0,0.3h0c0,0-0.1-0.1-0.1-0.1v0h0c0,0.1,0.1,0.1,0.2,0.2
	c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0.1,0c0.1,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0.1,0.4c0,0.1,0,0.1,0,0.2
	c0.1,0.2,0.1,0.5,0.2,0.7c0,0.2,0,0.4,0.1,0.6c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2
	c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1h0c0,0,0,0,0.1,0c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.2,0.1,0.3c0,0,0,0,0,0
	c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.1,0,0.2c0.1,0,0.2,0.2,0.2,0.3c0.1,0,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.1,0.2,0.2
	c0,0.1,0.1,0.2,0.1,0.2c0,0.1-0.1,0.3,0,0.4c0,0.1,0.1,0.1,0.1,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0.1
	c0,0,0.1,0,0.1-0.1h0c0,0,0.1,0.3,0.1,0.3c0,0,0.1,0.1,0.2,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0.1,0.1c0,0.1,0,0.1,0,0.2
	c0,0.1,0.2,0.1,0.1,0.2c0,0,0,0.1,0,0.1c0.1,0,0.1,0,0.2,0.1c0.1,0.1,0.2,0.1,0.2,0.2c0,0,0,0.1,0.1,0.1c0,0,0.1,0,0.1,0v0
	c0,0,0,0,0,0c0,0.1,0,0.2,0.1,0.3c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0.2,0.2,0.3,0.2c0,0,0,0.1,0,0.1h0c0,0,0,0,0.1,0
	c0.1,0,0.2,0,0.3,0c0,0,0,0.1,0,0.1c0,0.1,0.1,0.1,0.2,0.1c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0.1,0,0.1,0.1,0.1c0,0,0.1,0,0.1,0.1
	c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1-0.1v0c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0.1
	c0.1,0,0.1,0,0.2,0c0,0,0-0.1,0-0.1h0c0,0,0.1,0.1,0.1,0.1v0.1c0.1,0,0.2,0,0.3,0.1h0c0,0,0-0.1-0.1-0.1h0c0,0,0,0,0-0.1
	c0.1,0,0.1,0,0.2,0c0,0,0,0.1,0,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1,0.1,0.1c0,0,0.4,0.1,0.4,0.1c0.1,0,0.1-0.1,0.1-0.2h0v0h0
	c0,0,0.1,0,0.1,0.1c0,0,0.1-0.1,0.1-0.1c0,0.1,0.1,0.2,0.1,0.2v0c0,0,0.1,0,0.1,0v0c0,0,0,0.1,0,0.1c0.1,0,0.1,0,0.1,0
	c0,0,0,0.1,0,0.1c0.1,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0.1,0.2,0.2c0.1,0,0.3,0.1,0.4,0.1c0,0,0,0,0.1-0.1h0v0h0
	c0,0,0,0.1,0,0.1c0,0,0.1,0,0.1-0.1c0.1,0,0.2,0,0.3,0.1c0.1,0,0.2-0.1,0.3,0c0,0,0,0.1,0,0.1c0,0,0.1,0,0.1,0c0,0,0,0,0-0.1
	c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.2,0,0.4,0,0.4,0.2h0v0c0.1,0,0.1-0.1,0.2,0
	c0,0,0.1,0,0.1,0c0-0.1,0.1-0.1,0.1-0.1c0,0,0,0.1,0,0.1v0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0.2,0
	c0,0,0-0.1,0.1-0.1h0.1v0c0,0,0,0.1,0,0.1h0c0,0,0.1,0,0.1,0c0,0.1,0.2,0.2,0.3,0.2c0,0,0.1,0,0.1,0c0,0.1,0.3,0.1,0.4,0.1
	c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0.2,0,0.3,0.1,0.4,0c0,0,0,0,0,0c0.1,0,0.2,0.1,0.2,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0
	c0,0,0,0,0.1,0c0.1,0,0-0.1-0.1-0.1h0c0-0.1,0.2-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0v0c0.1,0,0.1,0,0.2,0l0.2,0l0.1,0
	c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0
	c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0.1,0,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2-0.2,0.3-0.2c0.1,0,0.1,0.1,0.2,0.2v0h0
	c0-0.1,0-0.2,0.1-0.2c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0v0c0,0,0.1-0.1,0.1-0.1h0c0,0,0,0,0,0c0.1,0.1,0.1-0.1,0.1-0.1v0h0
	c0,0,0,0,0,0.1v0h0v0c0,0,0,0,0,0c0-0.1,0-0.2,0.1-0.2v0c0,0,0.1,0,0.1,0c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2
	c0.1,0,0.1,0,0.2,0c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1-0.1c0.1-0.1,0.2-0.2,0.2-0.3c0.1,0,0.1,0,0.1,0v0h0l0-0.1
	c0-0.1,0.2-0.1,0.2-0.1c0,0,0,0.1,0,0.1v0h0v0c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0.1,0,0.1-0.1
	c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1c0.1-0.1,0-0.1-0.1-0.2h0c0,0,0-0.1,0.1-0.1c0,0,0.1,0.1,0.1,0.1v0h0v0
	c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0v0h0v0h0c0,0,0,0,0,0c-0.1,0-0.1-0.2-0.1-0.3c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.1
	c0-0.1,0-0.2,0.1-0.2c0,0,0.1,0,0.1-0.1c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.2-0.2v0c-0.1,0,0.1,0.1,0.1,0.2v0h0
	C44.6,49.4,44.6,49.2,44.6,49.1L44.6,49.1L44.6,49.1z M39.1,40.5c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0-0.1,0.2-0.1,0.1-0.2
	c0,0,0,0,0,0c0,0,0-0.1,0-0.2v0c0,0,0.1,0,0.1,0c0,0,0-0.1,0-0.1h0c0,0,0,0,0-0.1c0-0.1,0.1-0.1,0.1-0.2c0.1,0,0.1,0.1,0.2,0h0v0
	c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2c0,0.1-0.1,0.1-0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.2,0.2
	c-0.1,0-0.1,0-0.1,0.1l0,0c0,0,0,0,0,0C39.1,40.6,39.1,40.6,39.1,40.5z M39,41.4C39,41.4,39,41.4,39,41.4L39,41.4L39,41.4
	C39,41.5,39,41.4,39,41.4 M29.7,35.1C29.7,35.1,29.7,35.1,29.7,35.1c0-0.2,0-0.2,0-0.3c0,0,0.1,0,0.1,0c0.1,0,0.1-0.2,0.1-0.2
	c0.1,0,0.2,0,0.3,0c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.1,0.2-0.1,0.3C30,35.3,29.8,35.2,29.7,35.1 M39.3,47.7
	C39.3,47.6,39.3,47.6,39.3,47.7C39.3,47.6,39.4,47.6,39.3,47.7L39.3,47.7z M39.4,47.7C39.4,47.7,39.4,47.7,39.4,47.7
	C39.4,47.7,39.4,47.6,39.4,47.7L39.4,47.7C39.5,47.6,39.5,47.7,39.4,47.7z"
      />
      <path
        fill="currentColor"
        d="M25,23.6c0.1,0,0.2-0.1,0.3-0.1c0,0,0-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0,0,0-0.1,0-0.1c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0
	c0.1,0,0.1,0,0.2-0.1c0,0,0,0.1,0,0.1h0c0,0,0,0,0.1,0c0.1,0,0.2,0,0.2,0c0,0,0.3-0.1,0.3-0.1c0,0,0-0.1,0.1-0.1c0,0,0.1,0,0.1,0
	c0.2-0.1,0.3-0.1,0.3-0.3c0.1,0,0.1-0.1,0.2-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2
	c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.1,0-0.2
	c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.2c0,0,0.1-0.1,0.1-0.1c0-0.1,0-0.3,0.2-0.2c0,0,0.1-0.1,0.1-0.2c0,0,0.1,0,0.1,0
	c0,0,0,0,0-0.1c0-0.1,0-0.2,0.1-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.3,0.1-0.3c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2c0,0.2-0.1,0.4-0.1,0.6h0
	c0,0,0-0.1,0-0.1c0-0.2,0.1-0.4,0.1-0.6c0.1-0.3,0.1-0.6,0.1-1c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0-0.2c0,0-0.1,0-0.1,0
	c0,0,0,0-0.1,0v0h0c0-0.1,0-0.1,0-0.2h0v0c0,0,0.1,0,0.2,0c0-0.1-0.1-0.2-0.1-0.3c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
	c0-0.1,0-0.1,0-0.2v0c-0.1,0,0,0.1-0.1,0.1c0,0,0,0-0.1,0h0c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0-0.1,0-0.2,0-0.3
	c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3h-0.1v0h0v-0.1h0c0,0,0.1,0,0.1-0.1c0-0.1,0-0.2,0-0.3c-0.1,0-0.1-0.1-0.1-0.1v0h0
	c0,0,0,0,0,0v0h0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0-0.2c0,0,0.1-0.1,0.1-0.1c0-0.1,0-0.2-0.1-0.2h0
	c0-0.1,0-0.1,0-0.2V14c0,0,0-0.1,0-0.1c0-0.2,0.1-0.3,0.1-0.4c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2
	c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0.1-0.1c0,0,0,0,0-0.1c0,0-0.1-0.1-0.1-0.1
	c0-0.1,0-0.1,0.1-0.2c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.1c0-0.1,0-0.1,0-0.2
	c0,0,0-0.1,0.1-0.1c0-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0,0,0-0.1-0.1c0,0,0-0.1,0-0.2
	c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0-0.1h0v0c-0.1,0-0.1,0-0.1-0.1c0,0,0.1-0.1,0.1-0.1c0-0.1,0-0.1,0-0.2c-0.1,0-0.1,0-0.1,0
	c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0-0.1
	c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0.1-0.3c0,0,0-0.1,0-0.1c0-0.1,0.1-0.1,0.1-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1
	c0-0.1,0-0.1,0-0.2h0v0h0v0c0,0,0,0.2,0,0.3h0c0,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2
	c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1v0h0c0-0.1,0-0.1,0-0.1c0-0.1,0.1-0.4,0.1-0.6v0h0
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0-0.1c0-0.1-0.1-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.2h0v0h0c0,0,0,0,0,0v0h0
	c0-0.1,0-0.1-0.1-0.2v0h0v0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.2,0-0.3c0,0-0.1,0-0.1,0
	c0,0,0,0.2,0,0.2c-0.2,0,0.1-0.5,0.1-0.5h0v0c0,0-0.1,0-0.1,0c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0.1-0.1,0.1v0c-0.1,0-0.1,0-0.2,0
	c0,0,0-0.1,0-0.1c0,0,0.1,0,0.1,0c0.1-0.1,0-0.3-0.1-0.4c0,0,0-0.1,0-0.1v0h0c0,0.1,0,0.1,0,0.1c0,0,0,0,0,0c0,0.1,0.1,0.1,0,0.3
	c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0.1-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0,0c0-0.1-0.1-0.3-0.1-0.4
	c-0.1,0-0.2,0-0.2,0.1h-0.1v0h0c0,0,0-0.1,0-0.1c0-0.1-0.1-0.3-0.2-0.3c0,0,0-0.1,0-0.1h0c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.2
	c0,0-0.1,0-0.1,0c0,0-0.1-0.1-0.1-0.2c-0.1,0-0.1,0-0.2-0.1c0-0.1,0-0.2,0-0.2c0-0.1-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1
	c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1v0h0v0h0c0,0,0,0,0,0l0-0.1c0,0-0.2-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.2c0,0-0.1,0-0.1,0
	c0,0,0,0.2-0.1,0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1c-0.1,0-0.2-0.2-0.2-0.2v0c0.1,0,0.1,0,0.2,0
	c0,0,0-0.1,0-0.1c0-0.1-0.2,0-0.2,0c-0.1,0-0.1-0.1-0.1-0.1C25,1,25,1,24.9,1c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.1-0.1
	c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.2-0.1-0.2-0.1c0-0.1-0.2-0.1-0.2-0.2c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0.1
	c-0.1,0-0.2,0-0.3-0.1v0c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.2-0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0
	c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.1-0.1
	c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0.1c-0.1,0-0.1,0-0.2-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0v0h0v0
	c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0-0.1,0
	c-0.2,0-0.3,0.1-0.5,0.2c0,0-0.1,0-0.1,0c-0.1,0-0.3-0.1-0.4,0c-0.1,0.1-0.1,0.1-0.1,0.2c0,0.1-0.1,0.2-0.2,0.2c0,0-0.1,0-0.1,0
	c-0.1,0-0.2,0.1-0.2,0.1c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0.1-0.2,0.3-0.3,0.4
	c-0.1,0.1-0.1,0.2-0.2,0.3c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.3-0.2,0.4c0,0.1,0,0.2,0.1,0.3c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0,0.1
	c0,0,0,0,0,0c0,0.1,0.1,0.1,0.1,0.1c0,0.1,0,0.1,0.1,0.2c0,0.1,0.2,0.1,0.2,0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.2,0.2c0,0,0.1,0,0.1,0
	c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.1,0.2,0.2,0.2c0,0,0.1,0,0.2,0c0.1,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0v0
	c0,0,0,0,0,0v0c0,0,0.1,0,0.1,0c0,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.2,0c0.1,0,0.3-0.1,0.4,0c0,0,0,0,0,0c0.1,0,0.3,0,0.4,0
	c0.1,0,0.1,0,0.2,0c0.2,0,0.3,0.1,0.5,0.1c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0,0.2,0c0,0,0.1-0.1,0.1-0.1c0.1,0,0.3,0,0.4,0
	c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0.1,0.1,0.1c0.1,0,0.1,0,0.2,0.1c0,0,0,0,0,0c0,0,0,0,0.1,0c0.1,0.1,0.2,0.2,0.3,0.3
	c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0.1,0,0.1v0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0.1,0.1,0.2,0.2,0.2c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0
	c0.1,0,0.1,0.1,0.1,0.2h-0.1c0,0,0,0.1,0,0.1c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.2-0.1,0.2,0c0,0.1,0.1,0.1,0.1,0.2
	c0,0.1,0,0.1,0.1,0.2C25.1,6,25.2,6,25.3,6.1c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
	c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2h0c0,0,0.1,0.1,0.1,0.1
	c0,0.2,0,0.3-0.1,0.4c0,0.1,0,0.3-0.1,0.4v0c0.1,0,0.1,0.1,0.1,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
	c0,0.1,0,0.3,0,0.4c0,0.1-0.1,0.2-0.1,0.2c0,0.1,0,0.2-0.1,0.2c0,0,0,0.1,0,0.1c0,0,0,0-0.1,0c0,0,0,0.1,0,0.2
	c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0c0,0.1-0.1,0.3,0,0.4c0,0,0.1,0,0.1,0c0,0,0.2,0,0.2,0c0-0.1,0.1-0.1,0.1-0.1c0,0,0,0,0,0
	c0,0,0,0.1,0,0.1v0h0v0h0v0c0.1,0,0,0.2,0,0.2c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1l0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
	c0,0.1,0,0.2-0.1,0.2c0,0,0,0.1,0,0.1h0c0,0-0.1-0.1-0.1-0.1v0c0,0,0-0.1,0-0.2h0c0,0,0.1,0,0.1,0c0,0,0-0.1-0.1-0.1v0h0
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0.2-0.2,0.2c0,0.1,0,0.2,0,0.2l-0.1,0.2c0,0.1,0,0.2,0,0.2c0,0,0,0.1-0.1,0.1
	c0,0,0,0.1,0,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.1
	c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0,0,0-0.1,0c-0.1,0.1-0.1,0.3-0.1,0.5c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.2-0.1-0.3-0.1
	c-0.1,0-0.2-0.1-0.3-0.2c0,0,0-0.1-0.1-0.1c-0.1,0-0.4-0.1-0.4-0.1v0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0-0.1-0.1
	c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.3-0.3-0.4c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.1-0.2-0.2-0.2c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
	c-0.1,0-0.2,0-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.3,0-0.4-0.1c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2-0.1
	c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2-0.1-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1-0.1
	c-0.1,0-0.2-0.1-0.3-0.1c0,0.1-0.2,0-0.2,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0.1,0,0.1l-0.1,0.1c-0.1,0.1-0.1,0.2-0.2,0.3
	c0,0.1,0,0.2,0,0.3c0,0,0,0-0.1,0c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0c-0.2,0.1-0.1,0.2-0.2,0.4c0,0.1-0.1,0.1-0.1,0.2
	c0,0,0,0.1,0.1,0.1c0,0,0,0.2-0.1,0.2c0,0.1,0.1,0.1,0.1,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2h0v0c0,0-0.1,0-0.1,0v0
	c0,0,0,0,0,0c0,0.1,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0.2v0c0.1,0,0.2,0.2,0.1,0.3c0,0,0,0.1-0.1,0.1h0c0,0.1,0,0.2,0,0.3
	c-0.1,0-0.1-0.1-0.2-0.1c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2-0.1-0.3
	c0-0.1-0.1-0.1-0.1-0.1l0-0.1c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0-0.1-0.1
	c0-0.1-0.1-0.2-0.1-0.2v0c0,0,0-0.1,0.1-0.1c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2
	c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.3,0-0.4c0,0,0-0.1,0-0.1c0,0,0,0,0,0
	c0,0,0,0,0,0h0c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3h0v0c0,0,0,0,0.1,0v0h0v0h0c0,0-0.1,0-0.1,0c0-0.1,0-0.3,0-0.4
	c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0.1,0v0c0,0,0,0,0-0.1h0c0,0,0,0,0,0c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0.1
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0v0c0.1,0,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0.1c0,0,0,0,0.1,0c0,0,0,0,0,0
	c0.1,0.1,0.1,0.1,0.2,0.1c0,0.1,0.1,0.1,0.1,0.1v0c0,0,0.1,0.1,0.2,0.1h0.1l0.1,0.1c0.1,0,0.2,0,0.3,0c0,0,0.1,0.1,0.1,0.1
	c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0
	c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0.1c0,0.1,0.1,0.2,0.1,0.3c0,0,0,0,0.1,0v0c-0.1,0,0.1,0.1,0.1,0.1c0,0.1,0,0.2,0.1,0.2c0,0,0,0,0.1,0
	c0,0,0,0,0.1,0c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.1,0v0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.2,0,0.3,0
	c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0,0.1,0c0.1,0,0.1-0.1,0.2-0.1l0-0.2c0,0,0-0.1,0.1-0.1c0,0,0,0,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2
	c0-0.1,0.1-0.2,0.1-0.3c0,0,0-0.2,0-0.2c0-0.1,0-0.1,0-0.1c0-0.1,0.1-0.1,0.1-0.1c0.1-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.1,0-0.2
	c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0-0.1c0-0.1,0.1-0.1,0.1-0.1c0-0.1,0-0.1,0.1-0.2c0,0,0,0,0,0
	c0,0,0,0,0,0c0.1-0.1,0.1,0,0.2-0.1c0,0,0.1,0,0.1,0c0.1,0,0.2-0.1,0.3,0c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.4,0,0.4,0
	c0,0,0.1-0.1,0.1-0.2c0-0.3-0.3-0.4-0.4-0.5c-0.1-0.1-0.1-0.1-0.2-0.2c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0-0.1,0-0.1-0.1
	c-0.1,0-0.1,0-0.2-0.1c0,0,0-0.1,0-0.2c0-0.1-0.1-0.3-0.1-0.4c0-0.1-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1c0,0-0.1-0.1-0.1-0.1
	c-0.1-0.1-0.1-0.1-0.2-0.2c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.3-0.3-0.5-0.3c0,0,0-0.1,0-0.1c-0.1,0-0.2,0-0.2-0.1c0,0,0,0,0-0.1
	c0,0-0.1,0-0.1-0.1c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1-0.1c-0.1,0-0.3,0.1-0.3,0l-0.1-0.1
	c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.3-0.1v0c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.1-0.2-0.3-0.1v0c-0.1,0-0.1-0.1-0.2,0
	c-0.1,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.2-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1,0-0.1-0.1
	c0,0-0.1-0.1-0.1-0.1h0c0,0,0,0,0,0c-0.1,0-0.2,0-0.2-0.1c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.2-0.1-0.3-0.1c0,0-0.1,0.1-0.1,0.1
	c0,0-0.1,0-0.1-0.1c-0.1,0-0.2,0-0.2,0c-0.1,0-0.1,0.1-0.3,0v0c0,0-0.3,0.2-0.3,0.3c-0.1,0-0.1,0-0.2,0.1c0,0.2-0.2,0.2-0.3,0.4
	c-0.1,0,0,0-0.1,0.1c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0-0.2-0.1-0.2c0,0-0.1-0.1-0.1-0.1h0
	c0,0,0.1-0.1,0.1-0.2c0,0,0-0.2-0.1-0.2c0,0,0-0.1-0.1-0.1c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.2-0.1-0.3c0,0,0-0.1-0.1-0.1
	c0-0.1,0-0.2,0-0.2v0h0v0c0,0,0,0,0,0c-0.1,0.1-0.2,0-0.2,0h0c0,0,0.2-0.1,0.2-0.1c0,0,0-0.1,0-0.1h0c0,0-0.2,0-0.2,0
	c0-0.1,0-0.1,0-0.1c-0.1,0-0.1-0.1-0.1-0.2c0,0,0.1-0.1,0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.3-0.2-0.4
	c0-0.1-0.1-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.4-0.4-0.6-0.4c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.1-0.1-0.2,0c0,0,0,0,0,0
	c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.1-0.1,0.1c0,0.1,0,0.1-0.1,0.2c0,0.1,0,0.2,0,0.3
	c0,0,0,0.1-0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1-0.1,0.2c0,0.1,0,0.1,0,0.1c0,0.1,0.1,0.3,0,0.3c0,0.2-0.1,0.3-0.1,0.5
	c0,0-0.1,0-0.1,0h0c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0,0.2c0,0-0.1,0.1-0.1,0.1
	c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.3,0,0.4c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0c0,0.1-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0,0.2
	c0,0.1,0,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2v0.2c0,0,0,0.1-0.1,0.1h0v0c0,0,0,0.1,0.1,0.1C13.1,8.8,13.1,9,13,9.1
	c0,0,0,0.1,0,0.1c0,0.1-0.1,0.2,0,0.3C13,9.5,13,9.6,13,9.6c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2-0.1,0.3c0,0.1,0.1,0.2,0.1,0.3
	c0,0,0,0,0,0c0,0,0.1,0.2,0,0.3c0,0.1,0.1,0.2,0,0.4c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0.1,0.2,0,0.2c0,0,0,0,0,0
	c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.1c0,0.1,0,0.2,0,0.2c0,0.2,0,0.3,0,0.5
	c0,0.2-0.1,0.3-0.1,0.5c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.4
	c0,0.7,0.2,1.5,0.3,2.2c0,0,0,0.1,0.1,0.1c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.1,0.2,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0
	c0.1,0,0.1-0.2,0.1-0.3h0c0,0,0,0.2,0,0.2c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0.1c0,0,0.1,0.1,0.1,0.1
	c0,0,0,0.1,0,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1-0.1,0.2,0,0.3c0,0.1,0.1,0.2,0.2,0.2c0,0.1,0,0.1,0,0.2v0c0,0,0,0,0,0h0.1
	c0,0,0.1,0.1,0.1,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.2,0,0.2
	c0,0.1,0.2,0,0.2,0.2c0,0.1,0,0.1,0,0.1c0,0.1,0,0.1,0.1,0.2c0,0.1,0,0.2,0.2,0.2c0,0,0,0.1,0.1,0.1c0.1,0.2,0.1,0.2,0.3,0.3
	c0.1,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0h0v0h0c0,0.1,0,0.1,0.1,0.1c0,0,0,0.1,0,0.1h0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.1
	c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.2,0.1,0.2,0c0,0,0-0.1,0-0.1c0.1,0,0.1,0.1,0.2,0h0v0h0c0,0,0,0,0.1,0c0,0,0.1-0.1,0.1-0.1h0v0
	c0,0,0,0,0,0.1c0,0,0.1,0,0.1,0c0,0.1,0.2,0.1,0.3,0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0v0c0,0,0,0,0.1-0.1c0,0,0.1,0,0.1,0
	c0,0,0.1,0,0.1-0.1v0h0v0h0v0c0,0,0,0,0,0v0h0v0h0c0,0,0-0.1,0-0.1c0.1,0,0.2-0.1,0.3-0.1c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1
	c0,0,0.1,0,0.1,0h0c0,0,0-0.1-0.1-0.1c0,0,0-0.1,0.1-0.1c0.1-0.1,0.1,0.1,0.3,0c0.1-0.1,0-0.2,0.2-0.3c0,0,0,0,0,0c0,0,0-0.1,0-0.1
	c0,0,0.1,0,0.1,0c0.1,0,0.2-0.1,0.2-0.2c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.1-0.3c0,0,0-0.1,0-0.1
	c0-0.1,0.1-0.2,0.2-0.3c0-0.1,0-0.2,0-0.4c0-0.1,0-0.1,0.1-0.2c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1-0.1-0.1-0.1-0.2
	c0,0,0,0,0.1-0.1c0.1-0.1,0.1-0.2,0-0.3c0,0,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0
	c0-0.1,0-0.3-0.1-0.4c0,0-0.1,0-0.1,0c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1
	c0-0.1,0-0.3,0-0.4c0,0,0-0.1-0.1-0.1c0,0,0,0-0.1,0c-0.2-0.2-0.1-0.3-0.1-0.5h0c0.1,0,0.1,0.1,0.2,0.2c0.1,0,0.1,0,0.2,0
	c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1-0.1,0.1-0.1l0,0c0.1,0.1,0.1,0,0.2,0.1c0.1,0,0.1,0.1,0.1,0.2c0,0,0.1,0,0.1,0h0c0,0,0,0.1,0,0.1
	c0.1,0.1,0.2,0,0.3,0c0.1,0,0.2,0.1,0.2,0.2c0,0,0.1,0,0.1,0c0.1,0,0.1,0.1,0.2,0.2c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0.1,0.2,0.1
	c0.1,0,0.2,0,0.2,0h0c0,0.1,0,0.2,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.3,0.1,0.2,0.2c0,0,0,0.1,0,0.1c-0.1,0-0.1,0.1-0.1,0.1
	c0,0,0,0-0.1,0c0,0-0.1,0.1-0.1,0.1h-0.1c0,0,0,0-0.1,0h0c0,0.1,0,0.1,0,0.1c0,0-0.3,0.2-0.4,0.2c0,0,0,0-0.1,0h0c0,0,0,0,0,0
	c0,0-0.1,0-0.1,0.1c0,0.1,0,0.1,0,0.2c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0.1
	c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.1-0.2,0.2c0,0.1,0,0.2,0,0.2
	c0,0-0.2,0-0.2,0.1c0,0.1,0,0.2,0.1,0.3c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2l-0.1,0.1c0,0,0.1,0.3,0.1,0.3
	c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0.1,0.3c0,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1
	c0.1,0,0.2,0,0.2,0c0,0,0.2,0.3,0.2,0.3h0v0c0,0,0.1,0,0.1-0.1c0.1,0,0.2,0.1,0.3,0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0.2,0.2,0.2
	c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.1c0.1,0,0.2,0,0.2,0c0,0,0,0,0,0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0.1,0.2,0.1
	c0.1,0,0.1,0,0.2,0v0.1h0c0,0,0.1,0,0.1,0.1c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0.1,0.4,0c0,0,0,0.1,0.1,0.1
	c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0,0.1,0.1,0.1,0.1,0.1c0.1,0,0.1-0.1,0.1-0.1c0,0,0.1,0.1,0.2,0.1c0,0,0,0,0-0.1
	c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0,0v0c0.1,0,0.1,0,0.2,0
	c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0.1,0c0,0,0,0,0,0C24.8,23.6,24.9,23.7,25,23.6 M28.3,6.1C28.3,6.1,28.3,6.1,28.3,6.1
	C28.3,6.1,28.3,6.1,28.3,6.1C28.3,6.1,28.3,6.1,28.3,6.1z M28.2,6C28.2,6,28.2,6,28.2,6L28.2,6C28.2,6,28.3,6,28.2,6
	C28.3,6,28.2,6,28.2,6L28.2,6L28.2,6z M28.1,5.8C28.1,5.8,28.1,5.8,28.1,5.8L28.1,5.8L28.1,5.8C28.1,5.8,28.1,5.8,28.1,5.8
	C28.1,5.8,28.1,5.8,28.1,5.8C28.1,5.8,28.1,5.8,28.1,5.8z M24.6,15.9c0,0.1,0,0.1-0.1,0.2c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1
	c0,0,0,0.1,0,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.1-0.3,0.1c0,0-0.1,0.1-0.1,0.2c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
	c0,0.1,0,0.1-0.1,0.2v0c0,0,0,0,0,0v0c-0.1,0-0.1,0-0.1-0.1v0c0,0-0.2,0.1-0.2,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1-0.3,0.3-0.4,0.3
	C23,17.9,23,18,22.9,18.1c0,0,0,0-0.1,0.1c0,0,0,0.1,0,0.1l-0.2,0.2c0,0,0,0.1-0.1,0.1c0,0,0,0,0,0c-0.1-0.1-0.2,0-0.3,0
	c-0.1,0-0.1,0-0.2,0h-0.1c-0.1,0-0.5,0-0.5-0.2c0-0.2,0.3-0.7,0.5-0.4c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0.1
	c0.2,0.1,0.3,0,0.4-0.1c0,0,0,0,0.1,0c0,0,0,0-0.1,0c0,0,0,0,0-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1c0,0,0.1,0,0.1-0.1
	c0.1-0.1,0-0.2,0.1-0.3c0.1-0.1,0.2-0.1,0.2-0.2c0-0.1,0-0.2,0-0.3c0.1-0.2,0.2-0.2,0.3-0.4c0-0.1,0-0.1,0-0.2
	c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.2,0-0.3,0.1-0.5c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1-0.1,0.2-0.1
	c0.1,0,0.1,0,0.2,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1,0c0,0,0.1,0.3,0.1,0.4c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.1,0,0.2
	C24.7,15.8,24.6,15.8,24.6,15.9"
      />
      <path
        fill="currentColor"
        d="M19.9,34.1c0-0.1,0-0.1,0.1-0.2c0,0,0,0-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0,0-0.2,0-0.3c0,0,0.1,0,0.1,0v-0.1h-0.1
	c0-0.1,0-0.1,0-0.2c0-0.1,0-0.3-0.1-0.4h0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.2,0-0.4-0.1-0.6c0-0.1,0-0.1-0.1-0.2c0,0,0,0,0-0.1
	c0,0,0-0.1,0-0.1h0.1c0,0.1,0,0.1-0.1,0.1c0,0.1,0,0.1,0.1,0.2h0v0h0v0c0,0,0.1-0.3,0-0.4c0,0,0,0,0,0.1c0,0,0-0.1,0-0.1
	c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0.1,0,0.1-0.1c0.1-0.2-0.1-0.2-0.1-0.4c0,0,0.1,0,0.1,0h0c0-0.1-0.1-0.2-0.2-0.3
	c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.1-0.1-0.2c0,0,0-0.3,0.1-0.3c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.1-0.4c0,0,0.1-0.1,0.1-0.1
	c0-0.1,0-0.3,0-0.5c0-0.1,0-0.3-0.1-0.4c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0-0.2c-0.1-0.2-0.1-0.4-0.2-0.5c0,0,0,0,0,0
	v0c0,0-0.1,0-0.1,0.1h0c0,0-0.1,0-0.1,0v0h0c0-0.1,0.1-0.2,0.1-0.3c0,0-0.1,0-0.1-0.1c0,0,0-0.1,0-0.1c0-0.1-0.1-0.1-0.2-0.2
	c0,0,0-0.1,0-0.1c0-0.2,0-0.3-0.1-0.5c0,0-0.1-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.2-0.1-0.3-0.2
	c-0.1-0.1-0.1-0.2-0.2-0.3c-0.2-0.1-0.4-0.2-0.5-0.4c-0.1-0.1-0.2-0.2-0.3-0.2c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.2-0.1
	c-0.2-0.1-0.3-0.1-0.5-0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.2,0
	c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0-0.2,0.1-0.2,0.2v0c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.3,0-0.3,0.1
	c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.2,0v0h0v0c0,0,0,0,0,0c0,0.1-0.3,0.1-0.4,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1-0.1-0.1-0.1v0
	c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0.2,0,0.3v0c0,0,0,0,0,0c0,0-0.2,0.1-0.2,0.1
	c0,0,0,0.1,0,0.1h0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0.1c0,0-0.1,0-0.2,0c-0.3,0-0.1-0.2-0.1-0.4c0-0.1,0-0.2,0.1-0.3h0v0h0
	c0,0-0.1,0-0.1,0v0c0,0,0-0.1,0-0.2c0-0.2,0-0.4,0-0.6c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.4c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
	c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.2-0.1-0.2c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0-0.1-0.2-0.2-0.2
	c0,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1c0-0.1,0.1-0.1,0-0.2c0-0.1-0.1-0.2-0.1-0.3c0-0.2-0.1-0.3-0.1-0.5c0-0.1,0-0.1,0-0.2
	c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.2,0-0.2h0c0,0,0,0,0-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
	c0-0.1-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0-0.2
	c0,0,0,0-0.1,0h0v0h0v0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0-0.2-0.1-0.4-0.1-0.6v0c0,0,0,0.1,0,0.1c-0.1,0-0.1-0.1-0.2-0.2
	c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0h0c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0v0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1-0.1-0.1-0.1-0.1
	c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.2-0.1-0.3h0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2h0v0c0,0,0,0,0,0h0c0-0.1,0-0.1,0-0.2
	c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1
	C9.1,16.9,9,16.7,9,16.5H8.9v0h0c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.1c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0
	c-0.1,0-0.2-0.1-0.2-0.2c0,0,0,0,0-0.1c0-0.1-0.1-0.1-0.1-0.2c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1
	C8,15,7.9,15,7.8,14.9c0,0,0,0,0,0c0-0.1,0-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1
	c-0.1-0.1-0.2-0.2-0.2-0.4h0v0h0c0,0,0,0,0,0.1c0,0-0.3-0.2-0.3-0.2H6.9v0c0,0,0,0,0-0.1c0,0-0.1-0.1-0.1-0.1l-0.1-0.1
	c-0.1,0-0.1,0-0.2-0.1c0,0,0-0.1,0-0.1c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.2-0.1-0.2c0,0-0.2-0.1-0.3-0.2c0,0-0.1,0-0.1,0
	c0,0-0.1-0.1-0.1-0.1v0c-0.1,0-0.2,0-0.3,0v0c-0.1,0-0.1,0.3-0.1,0.3c0,0,0,0.1-0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1
	c0,0,0.1,0.1,0,0.1l-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0.1c0,0.1,0.1,0.4,0,0.5c0,0,0,0,0,0.1
	c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0.2,0.2,0,0.3c0,0.1,0.1,0.4,0.2,0.5c0,0,0,0,0,0.1c0,0,0,0,0,0c0,0.1,0,0.2,0.1,0.2
	c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0.2,0.1,0.3c0,0,0,0,0,0c0.1,0,0.2,0.3,0.2,0.3c0,0-0.1,0-0.1,0.1
	c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0.1,0C6,17,5.9,17,6,17.2c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.1
	c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2C6,18,6,18,6,18.1c0,0,0.1,0,0.1,0c0,0,0.1,0.2,0.1,0.2h0
	c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.1,0.1,0.1c0.1,0.4,0.1,0.7,0.2,1.1c0,0.1,0.1,0.1,0.1,0.2
	c0,0.1,0,0.2,0.1,0.3c0,0,0,0.1,0.1,0.1c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0c0.1,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.1,0,0.2L7,21.7
	c0,0.1,0,0.1,0.1,0.2c0,0,0,0,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0.1,0.2c0,0.1,0,0.2,0,0.3c0.1,0.2,0.1,0.4,0.2,0.6
	c0,0.1,0.1,0.2,0.1,0.2c0,0.1,0,0.3,0,0.4c0,0.1,0,0.1,0.1,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
	c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.1,0.1
	c0,0,0,0.1,0,0.1c0,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.1,0.1-0.1,0.2c-0.1,0-0.1,0-0.2,0c0,0,0,0.1,0,0.1h0c0,0,0,0,0,0h0
	c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1-0.1,0.2c0,0,0,0.1,0,0.1v0c0,0-0.1,0.1-0.1,0.1
	c0,0,0,0.2,0,0.2c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.2,0,0.2c0,0.1-0.1,0-0.1,0.1C6,27.8,6,27.9,6,28c0,0.1,0,0.1,0.1,0.2c0,0,0,0,0,0
	c0.1,0,0.3,0.1,0.3,0.2c0,0.1,0,0.1,0,0.2c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2,0.1-0.3,0c0,0,0-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1
	c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.3,0.1,0.3,0.3c-0.1,0-0.1,0.1-0.1,0.1v0c0,0-0.1,0-0.1,0
	c-0.1,0-0.2-0.1-0.3-0.2c0,0,0,0,0,0c0,0.1,0,0.2,0,0.3c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0.1,0.1h0c0,0,0,0,0,0.1
	c0,0,0,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0.1,0,0.2,0.1,0.3,0.2c0.1,0,0.2,0.1,0.2,0.1
	c0.2,0,0.3,0.1,0.4,0.2c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0c0,0,0,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1,0,0.2,0.1
	c0,0,0.1,0,0.1,0c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.3,0,0.3,0.1c0.1,0,0.2,0,0.3,0c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0.1c0,0,0,0,0.1,0
	c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.3,0.1,0.4-0.1c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1,0,0.2,0c0,0,0.2-0.2,0.2-0.1
	c0,0,0,0-0.1,0v0.1c0.1,0,0.2-0.1,0.2-0.2v0c0.1,0,0.2,0,0.2,0h0c0,0,0,0,0,0c0.1,0,0.3,0,0.3-0.1c0,0,0.1,0,0.1,0
	c0-0.1,0.1-0.1,0.1,0h0v0c0,0,0,0,0,0h0v0h0v0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0.1,0,0.1,0
	c0.1,0,0.1,0,0.2-0.1c0-0.1,0-0.1,0.1-0.1c0,0.1,0.1,0.1,0.2,0.1c0.1,0,0.1-0.1,0.2-0.1v0c0,0,0,0,0.1,0c0-0.1,0.1,0,0.1,0
	c0,0,0.2-0.1,0.2-0.1c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.1-0.1c0.1,0,0.3,0,0.3-0.2c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.1-0.1
	c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1,0c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0v-0.1h0v0c0,0,0.1,0,0.1,0
	c0,0,0,0,0.1,0c0.1,0,0.2-0.1,0.3-0.1h0c0,0.1-0.1,0.1-0.1,0.2c0.1,0,0.1-0.1,0.2-0.1c0,0,0-0.1,0-0.1c0,0,0.1,0,0.1,0h0v0
	c0,0,0,0.1,0,0.1c0,0,0.1,0,0.1,0h0c0-0.1,0-0.2,0-0.3c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.2-0.1l0.2,0v0c0,0,0-0.1,0.1-0.1
	c0.1-0.1,0.2-0.1,0.3,0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0.1,0.1,0.1,0.2,0.1h0c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.1
	c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.3,0,0.4c0,0.1,0,0.1,0,0.2c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2
	c0,0.1,0,0.3,0,0.3c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1l0,0.3c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0.1,0.2c0,0.1,0,0.3,0,0.4
	c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.2-0.1,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.2c0,0.1,0,0.2,0,0.4c0,0,0,0.1,0,0.1
	c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1
	c0,0,0,0.2,0,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0.2-0.1,0.3-0.1,0.4c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2
	c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.2-0.1,0.3
	c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1v0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.1
	c0,0.1-0.1,0.2-0.1,0.3c0,0.1-0.1,0.2-0.1,0.4c0,0.2,0,0.3,0,0.5c0,0.1,0.1,0.2,0,0.3c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
	c0,0.1-0.1,0.1-0.1,0.1c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.1c0,0,0,0.1,0,0.1
	c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.1c0,0.2,0,0.4-0.1,0.7v0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.1-0.1,0.2
	c0,0.1,0,0.3,0,0.4c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2v0c0,0-0.1,0-0.1,0v0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.2
	c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.1-0.1,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
	c-0.1,0-0.1,0-0.2,0.1c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.2-0.3-0.3c0,0,0,0.1,0,0.1c0,0,0,0,0.1,0.1
	c0.1,0.1,0.1,0.1,0.2,0.2c0,0.1,0.1,0.2,0.1,0.2c0,0,0,0,0,0c0,0,0.1,0.2,0.1,0.2c0,0,0,0,0,0c0.1,0.1,0.1,0.2,0,0.2
	c0,0.1,0,0.1,0.1,0.2c0,0,0,0,0,0c0,0-0.1,0-0.1,0v0c0,0,0,0.1,0,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2,0.2-0.2,0.2h0
	c0,0.1,0,0.1,0,0.2c-0.1,0-0.1-0.1-0.2,0c-0.1,0-0.1,0.2,0,0.2v0c-0.1,0-0.1,0.2-0.2,0.3c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0.1,0,0.1
	c0,0.1,0.1,0.1,0.1,0.1c0,0.1-0.2,0.1-0.2,0.1c0,0.1-0.1,0-0.1,0.2h0c0,0-0.1,0-0.1,0v0c0,0,0,0.1,0,0.1c0,0.1,0,0.2-0.1,0.3
	c0,0.1,0,0.1,0,0.2c0,0-0.1,0.1-0.1,0.1v0c0,0,0,0.2,0,0.2c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.1,0,0.2h-0.1
	v0c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1,0.1,0.3v0c0,0-0.1,0-0.1,0h0c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0.1,0,0.1,0,0.2,0
	c0.1,0,0.1,0.2,0.2,0.2c0,0.1,0.1,0,0.2,0.1c0,0,0,0,0,0v0c-0.1,0-0.1,0-0.1,0h0c0,0.1,0.1,0.1,0.1,0.1h0c0,0,0,0.1,0,0.1
	c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0.1,0.1,0.1,0.2,0.2c0,0,0,0.1,0,0.1c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0.1,0,0.2,0,0.2,0
	c0,0,0.1,0,0.1,0.1c0.1,0,0.3,0,0.4,0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0.1,0.1,0.1c0.1,0,0.4-0.1,0.4-0.2
	c0,0,0-0.1,0-0.1c0-0.1,0.1-0.1,0.1-0.1c0.1-0.1,0.3-0.1,0.4-0.2c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.2
	c0.1-0.1,0.1-0.2,0.3-0.2c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.1,0.3-0.1c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.1,0.1-0.2
	c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1-0.1h0c0,0,0,0,0,0.1v0h0c0,0,0,0,0,0c0.1-0.1,0.1-0.1,0.2-0.2c0,0,0-0.1,0.1-0.1
	c0.1-0.1,0.2-0.3,0.2-0.4c0-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.1,0.2-0.3c0-0.1,0-0.2,0.1-0.3v-0.2c0-0.1,0-0.2,0-0.2
	c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0.1-0.3c0,0,0-0.1,0-0.1v-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
	c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.3
	c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.1,0.1-0.2h0v0c0,0,0,0-0.1,0v0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0,0-0.1,0-0.1
	c0-0.1,0-0.2,0-0.3c0,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1
	c0-0.1,0.1-0.1,0.1-0.1v0c0,0,0.1,0,0.1,0c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0,0-0.1c-0.1,0,0-0.2,0-0.2c0,0,0-0.1,0-0.1
	c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0-0.1h0v0c0,0-0.1,0-0.1,0c-0.1-0.1,0.1-0.2,0.2-0.3c0,0,0,0,0,0v0h0c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0-0.1,0c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0-0.1c0-0.1,0.1-0.1,0.1-0.2h0v0c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0
	c0,0,0,0,0,0c0-0.1-0.1-0.4,0.1-0.4v0c0,0-0.1,0-0.1,0v0h0v0c0,0,0.1,0,0.1,0c0.1-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2
	c0-0.1,0-0.2,0-0.3c0,0,0,0,0.1,0c0,0,0.1,0,0.1-0.1c0.1-0.1,0.2-0.1,0.1-0.3h0c0,0-0.1,0.2-0.2,0.2v0h0c0,0,0-0.1,0-0.1
	c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2,0.1-0.3c0-0.1-0.1-0.1,0-0.2c0-0.1,0.1-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1
	c0-0.1,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2h0v0h0c0,0,0,0,0,0h0c0-0.1,0-0.2,0-0.2v0c0,0,0,0,0,0h0c0-0.1,0-0.2-0.1-0.2
	v0c0,0,0-0.1,0-0.1c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.3,0-0.4c0-0.1,0.1-0.2,0-0.4h0v0h0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2
	c0,0,0-0.1,0-0.2c0,0,0,0,0.1-0.1c0,0,0-0.1,0-0.1c-0.1,0,0-0.2,0-0.3c0,0-0.1,0-0.1,0v0c0,0,0,0,0-0.1c0-0.1,0.1-0.1,0.1-0.3
	c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1v0c0.1,0,0-0.1,0.1-0.1c0-0.2-0.1-0.2-0.1-0.4c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0.1-0.1
	c0-0.1,0-0.2,0-0.3c0-0.1,0.1-0.3,0-0.4c0,0,0-0.1,0-0.1C19.9,34.4,19.9,34.3,19.9,34.1"
      />
      <path
        fill="currentColor"
        d="M12.1,35c0-0.1,0.1-0.1,0.1-0.2c0-0.1-0.1-0.2-0.1-0.2c0-0.1,0-0.1,0-0.2c0,0,0,0,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0.1,0,0.1v0c0,0,0,0-0.1,0c-0.1,0,0.1-0.2,0.1-0.2c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1
	c-0.1-0.1-0.2-0.1-0.3-0.1c0,0,0,0-0.1,0c-0.2,0-0.3-0.2-0.5-0.3c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1
	c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.2,0-0.2,0.1c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.3,0c-0.1,0-0.2,0.1-0.3,0.2c0,0,0,0,0,0
	c0,0-0.1,0-0.1,0v0h0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0-0.2,0.1c-0.1,0-0.1,0-0.1-0.1v0
	c0,0-0.2,0.1-0.2,0.1c-0.1,0-0.3,0-0.4,0.1C8.1,34,8.1,34,8,34.1c-0.1,0-0.1,0-0.2,0c0,0,0,0.1-0.1,0.1c0,0-0.1,0-0.1,0
	c0,0-0.1,0.1-0.1,0.1v0c-0.1,0-0.1,0-0.1,0c0,0,0,0.1-0.1,0.1c0,0-0.1,0-0.2,0c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2,0-0.2,0
	c-0.1,0-0.1,0.1-0.1,0.1c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0-0.1,0-0.1,0
	c-0.1,0-0.2,0.1-0.2,0.1v0h0c0-0.1-0.1,0-0.2,0v0c0,0-0.1-0.1-0.1-0.1v0h0v0c0,0,0,0,0,0.1c0,0.1-0.1,0.1-0.2,0.2c0,0,0,0.1,0,0.1
	c0,0.1-0.1,0.1-0.2,0.2c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0v0c0,0,0,0,0,0c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.2,0.1-0.3,0.1
	c-0.1,0-0.2,0.1-0.3,0.2c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0
	c0,0-0.1,0-0.1,0c-0.1,0-0.1-0.1-0.2-0.2c-0.1,0-0.2,0.1-0.3,0.1c0,0-0.2,0-0.2,0c0-0.1,0.1-0.2,0.2-0.2c0-0.1,0.1-0.1,0.2-0.1
	l0-0.1h0v0h0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0.1-0.2,0.1-0.2,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0-0.1,0-0.2,0.1-0.3
	c0-0.1,0.2-0.3,0.3-0.3c0.2,0,0.3,0,0.5,0c0,0,0-0.1,0-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.2,0c0-0.1-0.2-0.1-0.2-0.2
	c-0.1,0-0.1-0.2-0.2-0.1h0v-0.1h0v0h0c0,0-0.1,0-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0c0,0-0.1,0.1-0.1,0.1h0c0,0,0,0,0,0
	c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0-0.2,0c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.3,0.2-0.4,0.3C1.1,35.9,1.1,36,1,36.1
	c0,0-0.1,0-0.1,0.1c-0.1,0.1,0,0.2-0.2,0.1c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2v0h0c0,0,0,0,0,0c0,0,0,0.1,0,0.1
	c0,0.1-0.1,0.2-0.1,0.2c0,0,0,0.1,0,0.1c0,0-0.1,0.1-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0.1-0.1,0.1c0,0.1,0,0.3,0,0.4
	c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0,0.1
	c0,0.1,0.1,0.2,0.1,0.2c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0,0.1,0.1,0.1l0.1,0.1
	c0,0,0,0.1,0.1,0.1c0.2,0.2,0.5,0.4,0.8,0.5c0.1,0,0.2,0.1,0.3,0.1c0.2,0.1,0.4,0.2,0.5,0.2c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0
	l0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0.2,0,0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.4,0c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0
	c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0.1,0.4,0c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2-0.1,0.3
	c0,0.1,0,0.3,0,0.5c0,0.1,0,0.2-0.1,0.3c0,0.3,0,0.6,0,0.8c0,0.1-0.1,0.2-0.1,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
	c0,0.1,0,0.1,0,0.2c0,0.2,0,0.5,0,0.7c0,0,0,0,0,0c0,0.1,0,0.3,0,0.4c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2L5,44.7c0,0,0,0.1,0,0.1
	c0,0,0,0.1-0.1,0.1c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.1-0.1,0.1c0,0.1,0,0.1-0.1,0.2c-0.1,0.1-0.2,0.2-0.3,0.2c0,0-0.1,0-0.1,0v0h0
	c0,0,0,0.1,0,0.1c-0.1,0-0.4,0.3-0.6,0.4c0,0.1,0,0.1,0.1,0.1c0,0.1,0,0.1,0,0.1c0,0-0.1,0.2-0.2,0.2c0,0-0.1,0.1-0.1,0.1
	c0,0.1,0,0.2,0,0.2c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1,0,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1,0,0.2c0,0-0.1,0.1-0.1,0.1
	c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0.1,0,0.1c0,0-0.1,0.1-0.1,0.1c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.2
	c-0.1,0.1-0.1,0.3-0.1,0.5c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0.1-0.1,0.1-0.1,0.1c0,0.1,0.2,0.3,0.3,0.3h0c0,0.1,0.1,0.2,0.1,0.2v0
	c0,0,0.1-0.1,0.1-0.1c0,0,0,0.1,0.1,0.1c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0.1c0.1,0,0.1-0.1,0.1-0.1h0c0,0,0,0.1,0,0.1
	c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0,0.2,0.1,0.2c0,0,0,0,0,0c0.1,0,0.1-0.2,0.2-0.2v0h0
	c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.1,0.1,0.2c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0c0,0,0.1,0.1,0.1,0.1h0v0c0,0,0-0.1,0-0.1v0
	c0.1,0,0.1,0.3,0.1,0.4h0c0,0,0.1,0,0.1,0v0h0C5,50.9,5,51,5.1,51c0-0.1,0.1-0.1,0.2-0.1c0,0,0.2,0.1,0.2,0.1v0l0,0
	c0.1-0.1,0.2,0,0.2-0.3c0,0,0.1,0,0.1-0.1c0.1,0,0.2,0,0.3,0.1c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0v0
	c0,0,0,0,0,0c0-0.1,0.1-0.2,0.1-0.3c0.1,0,0.1-0.1,0.2-0.2c0,0,0,0,0.1-0.1c0-0.1-0.1-0.1,0-0.2c0-0.1,0.1-0.1,0.1-0.1
	c0,0,0-0.1,0.1-0.1c0.1-0.1,0.2-0.1,0.2-0.3c0,0,0,0,0,0c0.1-0.1,0.3-0.1,0.4-0.2c0,0,0,0,0,0c0-0.1,0.1-0.2,0.1-0.3h0
	c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1c0,0,0,0,0.1-0.1c0,0,0.1,0,0.1-0.1c0,0,0-0.1,0-0.1c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1-0.2,0-0.2
	C8,48.1,8,48.2,8,48.3v0h0c0,0,0-0.1,0-0.1v0h0c0,0-0.2,0.1-0.2,0c0,0,0-0.1,0-0.1c0,0,0.3-0.1,0.4-0.1c0,0,0-0.1,0-0.1h0
	c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.1c0-0.1,0-0.1,0.1-0.2c0-0.1,0-0.2,0.1-0.2c0,0,0,0,0,0c0.1,0,0.1-0.2,0.1-0.3
	c0-0.1,0-0.1,0.1-0.1v0h0c0,0,0,0.1,0,0.1c0.1,0.1,0.1-0.2,0.1-0.2c0,0,0,0,0.1-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.3
	C9,46,9,45.9,9.1,45.9c0,0,0-0.1,0.1-0.1c0-0.1,0-0.1,0-0.1v0c0.1,0,0.1-0.1,0.1-0.2c0,0-0.1,0-0.1,0.1h0v0c0,0,0-0.1,0-0.1
	c0,0,0-0.1,0-0.1c0,0,0.1-0.1,0.1-0.1c0-0.1,0-0.1-0.1-0.1c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2v0h0v0c0,0,0,0.1,0,0.1c0,0,0.1,0,0.1-0.1
	c0,0,0,0,0,0c0-0.1,0-0.3,0-0.4h0c0,0,0,0,0,0c0,0.1,0,0.1-0.1,0.2v0h0c0,0,0-0.1-0.1-0.1c0-0.1,0-0.2,0-0.2h0v0h0v0c0,0,0,0,0,0.1
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1-0.2,0.1-0.2c0-0.1,0-0.1,0-0.2c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0-0.1-0.1-0.2-0.1-0.3
	c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0C9,43.4,9,43.6,9,43.7c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0v0c0,0,0-0.3-0.1-0.4h0c0,0,0,0,0,0v0h0
	c0,0,0,0,0-0.1c0,0,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1-0.1-0.2
	c0-0.1,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0,0,0.1,0c0,0,0-0.1,0-0.1
	c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.1-0.1-0.2c0,0,0,0,0-0.1s0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
	c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1-0.1-0.1h0c0-0.1,0-0.1,0.1-0.1c0-0.1,0-0.1,0-0.2
	c0-0.1,0-0.3,0-0.4c0,0,0.1-0.1,0.1-0.1C8.9,38.8,9,38.7,9,38.7c0,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.1
	c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.1,0.1,0,0.2-0.1c0,0,0.1,0,0.1-0.1h0c0,0.1,0.2,0,0.2,0c0,0,0.1-0.1,0.1-0.1
	c0,0,0,0,0,0c0,0,0,0,0,0l0,0v0c0,0,0,0.1,0,0.1h0v0c0.1,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c-0.1-0.1,0.3-0.3,0.3-0.3c0,0,0.1,0,0.1,0
	c0-0.1,0.1-0.1,0.1-0.2c0,0,0,0,0.1,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0.1,0c0.1-0.2,0.2-0.2,0.3-0.1c0,0,0,0,0.1-0.1c0,0,0,0,0-0.1
	c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3-0.1c0,0,0-0.1,0-0.2c0,0,0,0,0,0c0-0.1-0.1-0.3,0-0.3c0,0,0,0,0,0h0
	c0,0,0,0,0,0c0,0,0,0,0,0c0-0.2-0.2-0.2-0.2-0.4c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.1,0.1-0.2c0-0.1,0-0.2,0-0.2
	C12.2,35.1,12.2,35.1,12.1,35 M5,36.9C5.1,36.9,5.1,36.9,5,36.9c0,0-0.1,0.1-0.2,0.2c0,0-0.1,0-0.1,0c0,0,0,0,0,0
	c0-0.1-0.1-0.1-0.1-0.2c0,0.1-0.1,0-0.2,0c0-0.1,0.1-0.2,0.2-0.2c0-0.2,0.4-0.1,0.4-0.2c0,0.1,0,0.1-0.1,0.2C5,36.8,5,36.8,5,36.9
	 M7.4,34.9C7.3,34.9,7.3,35,7.4,34.9c-0.2,0.1-0.3,0.2-0.4,0.2c-0.1,0-0.2,0.1-0.3,0.1c0,0,0,0-0.1,0.1l0,0c-0.1,0-0.1,0-0.2,0.1
	c-0.1,0-0.2,0-0.3-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.2c0.1-0.1,0.3-0.2,0.5-0.2c0,0,0.1,0,0.1,0c0,0,0,0,0,0h0v0h0c0,0,0,0,0-0.1
	c0,0,0,0,0,0c0,0,0-0.1,0.1-0.1c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0.1c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0C7.5,34.9,7.5,35,7.4,34.9
	 M8.9,34.5c-0.1,0-0.1-0.1-0.1-0.2c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.1C8.9,34.4,8.9,34.5,8.9,34.5"
      />
      <path
        fill="currentColor"
        d="M114.6,41.1c0-0.2,0.1-0.5,0.1-0.7c0-0.2-0.1-0.3-0.1-0.4c-0.1-0.3-0.1-0.6-0.3-0.8c0,0,0.3-0.6,0.3-0.6c0,0,0,0,0,0
	c-0.1,0-0.3-0.3-0.4-0.4c0,0-0.1,0-0.1,0c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0,0-0.1,0-0.2c-0.1,0-0.3-0.5-0.4-0.6
	c-0.3,0-0.7-0.9-0.9-1.1c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1,0-0.3-0.1-0.4
	c-0.1-0.2-0.4-0.2-0.6-0.4c-0.1-0.1-0.1-0.2-0.3-0.2c0,0.1,0,0-0.1,0c0,0,0,0,0,0c-0.2,0-0.4-0.1-0.5-0.1c0,0.1-0.3,0.1-0.3,0.1
	c-0.2,0-0.5-0.2-0.7-0.2c-0.2-0.1-0.4,0-0.6-0.2c0,0,0,0,0,0c-0.2,0-0.3,0.1-0.5,0.1c0,0,0,0,0,0c0,0.1-0.2,0.2-0.3,0.2
	c0,0.2-0.1,0.2-0.3,0.2c0-0.1-0.3-0.2-0.3-0.4c0-0.1,0.1-0.3,0.2-0.4c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-1.2-0.7-1.3-0.9
	c0,0-0.1,0-0.1,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.1-0.1c0,0,0,0-0.1,0c0,0.1,0,0.1-0.1,0.1c-0.3,0.2-0.5-0.6-0.3-0.8
	l0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.2-0.2-0.4-0.4-0.7-0.6c-0.1-0.1-0.2-0.2-0.3-0.3
	c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1,0-0.1,0c0-0.1,0-0.1-0.1-0.2c0,0,0,0,0,0c0,0.1-0.2,0.1-0.2,0.2v0c-0.1,0-0.1-0.1-0.1-0.2
	c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0-0.2-0.3-0.2-0.4c-0.1-0.2,0-0.4,0-0.5c0-0.1-0.1-0.2-0.2-0.3c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0-0.3,0.2-0.3,0.2c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0-0.2-0.1-0.3-0.1-0.5c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1c-0.1,0,0,0,0,0.1c-0.2,0-0.7-0.5-0.8-0.7c0,0,0,0-0.1,0c0,0,0,0.1,0,0.1c0,0,0,0-0.1,0
	c0-0.1-0.2-0.1-0.3-0.1c-0.1,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.2-0.4-0.3-0.7c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.2,0.1
	c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0-0.1-0.1-0.2-0.2-0.2c-0.3,0-0.4-0.3-0.4-0.6c0,0-0.6-0.8-0.7-0.9c0-0.1,0,0-0.1,0
	c-0.3,0-0.5-0.3-0.7-0.3c-0.1,0,0,0,0,0c0.1,0-0.1,0-0.1,0c0,0,0,0,0,0.1c-0.1,0-0.2-0.2-0.3-0.2c0,0-0.3,0.1-0.3,0.3c0,0,0,0-0.1,0
	c0-0.1-0.2-0.2-0.3-0.3c-0.1-0.1,0-0.2-0.1-0.2c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.1-0.2-0.2-0.3-0.4
	c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.2l0,0c0,0-0.1,0-0.1,0c0,0.1,0,0.2-0.1,0.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
	c-0.1,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0.1c0,0.1,0,0.1-0.1,0.1c0,0,0,0,0,0
	c-0.2-0.1-0.3-0.2-0.6-0.3c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0,0,0,0,0,0c-0.6-0.2-0.8-0.8-1.1-1.2c-0.1-0.2-0.3-0.3-0.3-0.5
	c0.2,0,0.3,0,0.5,0.1c0-0.1,0-0.1,0-0.1c0.1,0,0.2-0.1,0.2-0.2c0,0,0,0,0-0.1c0.1,0,0.1,0.1,0.1,0.2c0,0,0,0,0.1,0
	c0-0.1,0.1-0.1,0.1-0.2c0.1,0,0.1,0.1,0.1,0.2c0.1,0,0.3-0.2,0.3-0.2c0,0.1,0.6,0.2,0.8,0c0.1,0,0.1,0.1,0.2,0.1c0-0.1,0-0.2,0-0.4
	c0.3,0,0.2,0.2,0.2,0.3c0,0.2,0.2-0.1,0.3-0.1c0-0.1,0,0,0.1,0c0,0.1,0,0.1,0.1,0.1c0.3,0.2,0.5-0.1,0.7-0.2c0.1,0,0.1,0,0.2,0
	c0-0.1,0-0.1,0.1-0.1c0-0.1,0,0,0.1,0c0.1,0,0.2,0,0.3,0c0.1,0.1,0.2,0.1,0.3,0.1c0-0.1,0.2-0.1,0.3-0.1c0.2,0,0.4-0.1,0.6-0.2
	c0.1,0,0.1,0,0.2,0c0.1,0,0.2-0.1,0.3-0.1c0.2,0.1,0.3,0.2,0.5,0.3c0.2,0,0.3,0,0.5,0.1c0.1,0,0.2,0.1,0.4,0.2c0.2,0,0.4,0,0.6,0.1
	c0.2,0,0.4,0.2,0.6,0.2c0.1,0,0.2,0,0.3,0c0.5,0,0.7-0.1,1-0.5c0.1-0.2,0.3-0.2,0.3-0.5c0.1,0,0.3-0.3,0.4-0.4
	c0-0.3-0.1-0.4,0.2-0.6c0,0,0,0,0,0c0-0.1,0-0.2,0-0.2c0-0.1,0-0.3,0-0.4c0-0.1,0.1-0.2,0.1-0.4c0-0.3-0.1-0.7,0-0.9c0,0,0,0,0,0
	c-0.1,0-0.1,0-0.2-0.1c-0.1,0,0-0.1,0-0.1c0-0.1,0-0.3-0.2-0.4c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.2-0.2-0.3-0.4-0.4
	c0-0.4-0.7-0.4-1-0.6c0,0,0,0,0,0c0,0.1-0.1,0.1-0.1,0.1c0,0.1-0.3,0-0.3-0.1c0,0,0,0.1,0,0.1c-0.1,0-0.1,0-0.2,0v0
	c-0.1,0-0.2,0.1-0.2,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.2,0-0.4-0.1c0,0.1,0,0.1-0.1,0.1c0,0,0,0,0,0
	c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0.1-0.1,0.1-0.2,0.1c0,0.1,0,0-0.1,0c-0.1,0-0.2,0.1-0.3,0.1c0,0,0,0,0,0
	c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.2,0-0.3,0.1-0.4,0.3
	c0,0-0.1,0-0.1,0c-0.1,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c-0.1,0-0.3,0-0.4,0.1c0,0.1,0,0.1,0,0.2c0,0-0.1,0-0.1,0c0-0.1,0-0.1-0.1-0.2
	c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.3,0.1c0,0,0,0,0,0c0,0.1-0.2,0.1-0.3,0.1c0,0,0,0,0,0c0,0,0,0,0,0c0-0.2-0.5-0.1-0.5-0.1
	c0,0,0,0,0,0c0,0,0,0-0.1,0c0-0.1,0,0,0,0c0,0.1-0.2,0.1-0.2,0.1c-0.3,0-0.5-0.1-0.7,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.3-0.2,0.3
	c-0.1-0.6-0.8-0.3-0.8-0.3c-0.2,0.1-0.6,0.1-0.6,0.1c-0.4,0-0.2,0.3-0.2,0.3c0.1,0.2-0.2,0.2-0.2,0.2c-0.1-0.1-0.3,0-0.3,0V19
	c0-0.3-0.4-0.1-0.4-0.1C92.7,19,92.6,19,92.6,19c0.1,0.1,0.3,0.2,0.3,0.2c0.3,0.2-0.6,0.3-0.6,0.3c-0.4,0.1-0.1,0.4-0.1,0.4
	c-0.2,0-0.6,0.3-0.6,0.3c-0.7,0.4-1.3,0.3-1.3,0.3c0.2-0.1,0.6-0.3,0.6-0.3c-0.2,0-0.7,0.1-0.7,0.1c-0.3,0.1-0.9,0.1-0.9,0.1
	c0.9-0.2,1-0.5,1-0.5C90.2,20,90.1,20,90.1,20c0.2-0.2,0.6-0.3,0.6-0.3c-0.2,0-0.7,0.1-0.7,0.1l0.7-0.3l-0.7,0.2
	c-0.7,0.2-1.3-0.4-1.3-0.4C88.1,19,87.8,19,87.8,19c-0.2,0-0.7-0.4-0.7-0.4c-0.9-0.5-1.7,1.3-1.7,1.3c-0.3,0.1-0.7,0.6-0.7,0.6
	c-0.9,0.1-2.3,1-2.3,1c-0.3,0.2-0.4,0.1-0.4,0.1c-0.3,0,0.1,0.4,0.1,0.4c0-0.2,0.3-0.3,0.3-0.3c0.1,0.1,0.3,0,0.3,0
	c0.3,0-0.1,0.3-0.1,0.3c0.4,0-0.1,0.2-0.1,0.2c-0.2,0-0.3,0.1-0.3,0.1l-0.3,0c-0.9,0.8-0.6,0-0.6,0c-0.2,0-0.3-0.2-0.3-0.2
	c0.1-0.1,0.2-0.2,0.2-0.2c0.3,0,0.2-0.4,0.2-0.4h-0.1c-0.3,0-0.7,0-0.9,0.2c-0.1,0-0.2,0-0.3,0c0-0.4,0.3-0.4,0.5-0.6
	c0.1-0.2,0.1-0.4,0.3-0.6c0.2-0.1,0.4-0.2,0.5-0.4c-0.1,0-0.2,0-0.3-0.1c0-0.2,0.5-0.4,0.7-0.5c0.1-0.1,0.2-0.2,0.4-0.3
	c0.2,0,0.3-0.1,0.5-0.1c0.2-0.1,0.3-0.2,0.5-0.2c0.2-0.1,0.4,0,0.6-0.1c0.3-0.1,0.6-0.3,0.9-0.5c0.1,0,0.2-0.1,0.3-0.1
	c0.1,0,0.2,0,0.3-0.1c0.2-0.1,0.3-0.2,0.5-0.3c0.4-0.1,0.8-0.1,1.1-0.1c0.3-0.1,0.5-0.2,0.8-0.3c0.1,0,0.2,0,0.3,0
	c0.2,0,0.1-0.3,0.5-0.1c0,0,0-0.1,0-0.1c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.3,0,0.4-0.1c0.2-0.1,0.2-0.4,0.5-0.3c0.1,0,0.1-0.1,0.2-0.1
	c0.1,0,0.3-0.1,0.4-0.1c0.2-0.1,0.3-0.2,0.5-0.1c0-0.1,0.2-0.2,0.2-0.3c0.1,0,0.2,0.1,0.2,0.2c0,0,0,0,0.1,0c0,0,0-0.1,0-0.1
	c0,0,0,0,0,0c0-0.1,0.5,0,0.6,0c0-0.1,0.1-0.1,0.1-0.1c0,0,0,0,0.1,0c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0c0,0,0,0,0,0
	c0-0.1,0.1-0.1,0.2-0.2c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0-0.1,0.3-0.1,0.3,0c0-0.1,0.3-0.1,0.3-0.2l0,0c0.2,0,0.3,0,0.5,0.1
	c0.1,0,0.1,0,0.2,0.1c0.2,0,0.4-0.1,0.6,0c0.1,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0.1,0.1,0.1c0.1,0,0.3-0.1,0.4-0.1
	c0.1,0,0.3,0.1,0.4,0.1c0.3,0,1-0.2,1.1-0.6c0.1-0.2-0.1-0.5-0.1-0.7c0-0.1,0-0.2,0.1-0.3c0-0.2,0-0.5,0-0.7c-0.2,0-0.2-0.2-0.3-0.3
	c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.2-0.5-0.1-0.8-0.2c-0.3,0-0.6-0.1-1,0c-0.1,0-0.1,0-0.2,0.1c0,0,0,0,0,0c-0.2-0.1-0.5,0.1-0.7,0.2
	c0,0.1-0.1,0-0.2,0c-0.1,0.1-0.3,0.2-0.4,0.2c0,0,0,0,0,0c0-0.2-0.3,0.1-0.3,0.2c0-0.1-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.2,0-0.2,0.1c0,0,0,0,0,0c0,0,0,0,0,0c-0.3,0-0.8,0.2-1,0.4c-0.3,0-0.5,0.1-0.7,0.1
	c0,0,0,0,0,0c-0.1,0-0.2,0.2-0.4,0.2c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.1-0.4,0.1-0.6,0.1c0,0,0,0,0,0c-0.1,0-0.1,0-0.2-0.1
	c-0.2,0-0.3,0.1-0.4,0.1c-0.4,0-0.7,0.1-1,0.1c0,0,0,0,0,0c0,0.1-0.8,0.2-0.9,0.3c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0
	c0,0,0,0,0,0c-0.3,0-0.6,0.1-0.9,0.2c0,0.1-0.1,0-0.1,0c0,0.1-0.1,0.1-0.1,0.1c0,0.1-0.2,0-0.3-0.1c0,0.1-0.1,0.1-0.1,0.1
	c-0.1,0-0.2,0-0.2,0c-0.2,0-0.3,0.1-0.5,0.1c0,0.1,0,0-0.1,0c0-0.1,0-0.2,0-0.3c0-0.2,0.3-0.5,0.4-0.7c0.1-0.1,0.2-0.2,0.2-0.3
	c0.4,0,0.5-0.3,0.7-0.4c0.1-0.1,0.3-0.1,0.5-0.2c0,0,0.2,0,0.3,0c0.2-0.1,0.2-0.3,0.4-0.3c0,0.1,0,0.1,0.1,0.1
	c0.1,0,0.3-0.2,0.3-0.3c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1,0,0.1-0.1,0.2c0,0,0,0,0,0c0-0.2,0.5-0.4,0.6-0.2
	c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.1,0.2-0.2c0.1,0,0.2,0,0.3,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.1,0,0.3-0.2,0.4-0.3c0-0.1,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0.1,0,0.2-0.4,0.3-0.4
	c0-0.1,0,0,0.1,0c0,0.1,0,0.2,0,0.4c0,0,0,0,0.1,0c0-0.1,0.2-0.2,0.2-0.4c0,0,0,0,0,0c0.2,0,0.3-0.2,0.5-0.2c0.1,0,0.2,0,0.3,0
	c0.2,0,0.3-0.2,0.4,0c0,0,0.1,0,0.1,0c0-0.1,0.2-0.2,0.2-0.3c0.1,0,0.2,0,0.2,0c0.2,0,0.2-0.2,0.5-0.1c0-0.1,0.1-0.1,0.1-0.1
	c0.5-0.1,0.9-0.2,1.4-0.4c0.2,0,0.4,0,0.5-0.1c0.2-0.1,0.3-0.4,0.3-0.7c0-0.1,0.3-0.1,0.4,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
	c0.1,0,0,0,0-0.1c0-0.3-0.1-0.6-0.4-0.8c-0.1,0-0.1-0.2-0.1-0.2c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.1,0.1,0.1,0.1c0,0,0,0,0.1,0
	c0.1-0.1,0.1-0.2,0.2-0.3c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.1c-0.2-0.2-0.5-0.2-0.8-0.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0-0.1,0c-0.1,0-0.3,0.1-0.4,0.1c0-0.1-0.5,0-0.5,0c0,0,0,0,0,0c-0.2,0-0.4,0-0.7,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0
	c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0.1c0,0,0,0,0,0c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1,0,0-0.1,0c-0.2-0.1-0.9,0-1,0.1
	c-0.1,0-0.2,0-0.4,0c0,0,0,0,0,0c0,0,0,0-0.1,0c0,0.1-0.1,0-0.1,0.1c-0.3,0-0.8,0.3-1.1,0.1c-0.1,0-0.1,0.2-0.2,0.2c0,0,0,0,0,0
	c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0.1c0,0-0.1,0-0.1,0c0,0.1-0.7,0.3-0.9,0.3c0,0,0,0,0,0c0,0,0,0,0,0
	c0.1-0.2,0.1-0.3,0.2-0.5c0.1-0.3,0.1-0.5,0.2-0.8c0.1-0.1,0.1-0.3,0.2-0.4c0.1-0.3,0.2-0.6,0.2-0.9C86.9,6.5,86.9,6.2,87,6
	c0.1-0.2,0.1-0.4,0.2-0.6c0.1-0.3,0.1-0.7,0.2-1c-0.1-0.1-0.3-0.1-0.4-0.2C87,4.1,87.2,4,87.2,4c0-0.1-0.1-0.1-0.1-0.2
	c-0.1-0.2-0.1-0.4-0.2-0.5c-0.1-0.1-0.2-0.2-0.2-0.3c-0.1-0.1-0.1-0.3-0.2-0.3c0,0-0.1-0.1-0.1,0c-0.1,0-0.2,0.1-0.3,0.1
	c0,0-0.1,0.3-0.2,0.3c0,0.1,0,0-0.1,0c0,0,0-0.1,0-0.1c0,0-0.1,0-0.1,0c0-0.1,0.1-0.1,0.1-0.2c0-0.1-0.2-0.2-0.3-0.3
	c0,0-0.1,0.1-0.1,0.1c-0.1,0,0.1,0.2,0.1,0.2c0,0,0,0.1,0,0.1c0,0,0,0,0,0c-0.1,0,0,0,0,0.1c0,0.1,0,0.1,0,0.2
	c0,0.3-0.3,0.6,0.2,0.6c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.3,0.2C85.4,4,85.2,4,85,4c0,0,0,0,0,0.1c0,0,0,0,0,0
	c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0.1c-0.1,0-0.1,0.1-0.1,0.1c-0.2,0.3-0.4,0.7-0.5,1
	c-0.1,0.2-0.2,0.4-0.2,0.6c-0.2,0.3-0.2,0.6-0.4,0.8c-0.2,0.4-0.5,0.8-0.7,1.2c-0.4,0.8-0.7,1.8-1.5,2.3c-0.2,0.2-0.6,0.1-0.8,0.2
	c-0.3,0-0.6,0-0.9,0.2c-0.2,0-0.5,0-0.7,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3-0.1c0,0,0,0,0,0c0,0.1-0.1,0.1-0.2,0.1
	c-0.2,0-0.4-0.1-0.6,0c-0.3,0-0.9-0.2-1.1-0.1c-0.3,0-0.3,0-0.2,0.2c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0.1
	c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0c0,0,0,0-0.1,0
	c0,0,0,0,0,0.1c0,0,0,0-0.1,0c0-0.1-0.1,0-0.2,0c-0.3,0-1.3,0.5-1.5,0.7c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.2-0.4,0.4-0.2,0.7
	c0,0,0,0,0,0c0,0.1-0.1,0.2-0.1,0.2c0,0,0,0,0,0c0,0.1,0,0.1,0,0.1c0.3,0,0.7,0.5,0.8,0.8c0,0.1,0,0.2,0.1,0.3c0,0.1,0.1,0.1,0.2,0
	c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0.3,0,0.4-0.2,0.6-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1,0,0.1-0.1,0.1c-0.1,0,0,0.1,0,0.1
	c0.1,0.1,0.4,0.1,0.6,0.1c0.1,0,0.2-0.1,0.4-0.1c0,0,0,0,0.1,0c0,0,0,0,0.1,0c0.1,0,0.4,0,0.4-0.1c0,0,0,0,0,0c0.1,0,0.2,0,0.3,0
	c0,0,0,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1-0.3-0.1-0.4-0.2c0,0,0,0-0.1,0c0,0,0,0,0,0c0.1,0,0.2-0.1,0.2-0.1
	c0.3-0.1,0.3,0.2,0.3,0.4c0,0,0,0,0,0c0.4,0,0.7,0,1.1,0c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.2,0c0.2,0,0.5-0.1,0.7,0
	c0.1,0,0,0.1,0,0.1c0.1,0,0.1,0,0.2,0c0,0,0,0.1,0,0.1c-0.1,0-0.1,0.2-0.2,0.2c-0.3,0.3-0.5,0.7-0.8,1c-0.1,0.1-0.2,0.2-0.3,0.3
	c-0.2,0.2-0.4,0.3-0.6,0.5c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0.1-0.6,0.4-0.7,0.5c0,0,0,0,0,0c-0.1,0,0-0.1,0-0.1c0,0,0,0-0.1,0
	c0,0.2-0.3,0.2-0.4,0.3c-0.1,0-0.1,0.1-0.1,0.2c-0.2,0-0.3,0.1-0.5,0.1c-0.4,0.1-0.9,0.2-1.2,0.4c0,0,0,0,0,0v0c0,0,0.1,0,0.1,0
	c0,0.1-0.2,0-0.2,0c-0.1,0-0.3-0.1-0.5-0.1c-0.1,0-0.1,0.1-0.2,0.2c-0.4,0.3-0.7,0.5-1.1,0.8c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0
	c0,0.3,0,0.4,0.1,0.6c0.1,0,0,0,0,0c-0.1,0.2,0.3,0.4,0.4,0.4c0.8,0.2,1.5,0.1,2.3,0.2c0.3,0,0.6,0,0.8,0c0.2,0,0.3-0.1,0.5-0.1
	c0.1,0,0.1,0.1,0.1,0.1c0.1,0-0.3,0.6-0.3,0.7c-0.1,0,0,0.1-0.1,0.1c-0.2,0.4-0.4,0.7-0.7,0.9c0,0.3-0.5,0.5-0.7,0.7
	c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0.1c0.1,0-0.1,0.1-0.1,0.1c0,0.1,0,0-0.1,0
	c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0,0,0,0,0c0,0.1-0.2,0.1-0.3,0.2c0,0,0,0,0,0c0,0,0,0-0.1,0
	c0,0,0-0.1,0-0.1c0,0,0,0-0.1,0c0,0.1-0.8,0.5-1,0.6c0,0,0,0,0,0c0-0.1,0,0-0.1,0c-0.2,0-0.7,0.4-0.9,0.4c-0.2,0.1-0.5,0.2-0.7,0.3
	c0,0-0.1,0-0.1,0c0-0.1-0.1,0.1-0.2,0.1c0.1,0,0,0,0,0.1c0,0,0,0,0,0c-0.2,0-0.6,0.2-0.7,0.4c0,0,0,0,0,0c0,0,0,0,0,0
	c-0.1,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.4,0.1c0,0.1-0.1,0-0.1,0c-0.1,0-0.1,0.1-0.2,0.1c-0.3,0.1-0.8,0.3-1,0.5
	c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1-0.1,0-0.1,0c-0.1,0.1-0.2,0.2-0.3,0.2c-0.3,0.2-0.7,0.3-1,0.4c0,0,0,0,0,0c-0.1,0-0.6,0.3-0.8,0.3
	c0,0,0,0,0,0c-0.3,0-0.4,0.2-0.6,0.3c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1,0-0.1,0-0.2-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0.2,0.2,0.3,0.1,0.6
	c-0.1,0.4-0.9,0.5-0.9,0.9c-0.1,0-0.1,0.1-0.1,0.1c-0.3,0.2-0.5,0.7-0.5,1.1c0,0.2,0.3,0.7,0.5,0.8c0.1,0.1,0.3,0.2,0.5,0.3
	c0.1,0.1,0.2,0.1,0.4,0.2c0.8,0.2,1.6,0.1,2.3-0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2-0.1
	c0.4-0.1,0.7-0.2,1.1-0.4c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.3,0,0.4,0c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0.1,0.3,0.1
	c0.2,0,0.3-0.1,0.4-0.2c0.2-0.1,0.5-0.1,0.7-0.3c0.1-0.1,0.1-0.2,0.1-0.2c0.1,0,0.1,0.1,0.1,0.2c0.2,0,0.5-0.3,0.8-0.3c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1-0.3,0.4-0.3,0.5c-0.2,0.3-0.4,0.7-0.6,1c-0.2,0.3-0.4,0.5-0.6,0.8c-0.9,1.7-2,3.2-3,4.9
	c-0.2,0.3-0.4,0.5-0.5,0.8c-0.1,0.1-0.2,0.3-0.2,0.4c-0.1,0.2-0.3,0.3-0.4,0.5c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.2-0.1-0.6-0.1-0.9
	c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.1,0.5-0.1,0.6c0,0-0.1,0-0.1,0c0,0.1,0,0-0.1,0c0,0,0-0.1,0-0.1c0,0,0,0-0.1,0
	c0,0.1-0.1,0.2-0.2,0.2c0,0,0,0,0,0c-0.2,0-0.3,0.4-0.4,0.5c-0.1,0-0.2,0-0.3,0c0,0.5-0.2,0.8-0.5,1.1c-0.1,0.1-0.3,0.1-0.4,0.3
	c-0.1-0.1-0.3-0.1-0.4-0.2c0,0,0,0,0,0c0,0,0,0,0,0.1c-0.1,0,0,0.1,0,0.1c0,0,0,0,0,0c-0.1,0-0.1,0-0.1-0.1c-0.1,0-0.1,0.1-0.1,0.1
	c-0.1,0.4-0.2,0.9-0.2,1.3c-0.1,0-0.1,0.3-0.2,0.4c0,0.1,0,0.2,0.1,0.3c-0.1,0-0.1,0.1-0.1,0.1c0,0.1,0,0.2,0,0.3
	c0.2,0.4,1,0.6,1.4,0.8c0.1,0,0.2,0.2,0.3,0.2c0.1,0,0.2,0,0.4,0c0.1,0,0.7,0.2,0.7,0.3c0.1,0,0.2-0.1,0.2-0.1
	c0.3-0.1,0.5-0.2,0.8-0.4c0.1-0.1,0.2-0.2,0.3-0.2c0-0.1,0.1,0,0.1,0c0.3,0,0.6-0.2,0.8-0.4c0.1-0.1,0.1-0.2,0.2-0.3
	c0.1-0.1,0.3-0.2,0.4-0.3c0.2-0.3,0.4-0.6,0.6-0.8c0.1-0.2,0.3-0.3,0.4-0.5c0-0.1,0-0.2,0-0.3c0.2,0,0.4-0.7,0.7-0.8c0,0,0,0,0,0
	c0.1,0,0,0,0-0.1c0-0.1,0-0.3,0-0.4c0.1-0.2,0.2-0.1,0.2-0.4c0.1,0,0.1,0,0.1,0c0,0,0.2-0.4,0.4-0.4c0,0,0,0,0,0c0,0,0,0,0-0.1
	c0,0,0,0-0.1,0c-0.2,0,0.2-0.2,0.3-0.2c0-0.2,0.2-0.4,0.3-0.6c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0-0.1,0
	c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0.1-0.2,0.1-0.4c0-0.1,0.2,0,0.2,0c0,0,0,0.1,0,0.1c0.1,0,0.1-0.2,0.1-0.3c0.2,0,0.8-0.9,0.8-1.2
	c0-0.1,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0-0.1,0.1-0.3,0.2-0.4c0,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1,0,0.1-0.1
	c0,0,0,0,0,0c0-0.5,0.2-0.5,0.5-0.8c0.1-0.2,0.2-0.4,0.4-0.6c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1,0.1-0.4,0.2-0.4c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0.1-0.1,0.2-0.2,0.1-0.3c0-0.1,0,0,0.1-0.1c0.1,0,0.8-1.3,0.9-1.5c0.1-0.2,0.1-0.3,0.2-0.4
	c-0.1,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0.2,0.1,0.2,0.1c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.4-0.7,0.3-1
	c0.1,0,0.5-0.5,0.5-0.6c0-0.1,0-0.3,0-0.4c0-0.1,0.2-0.1,0.2-0.2c0.2-0.3,0.2-0.7,0.5-0.9c0.2-0.2,0.4-0.3,0.5-0.4
	c0.2-0.2,0.4-0.4,0.7-0.6c0.3-0.1,0.7-0.3,1-0.4c0.1,0,0.3-0.1,0.4-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0,0.2,0,0.3,0
	c0.2,0,0.3-0.2,0.5-0.3c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.2,0.1,0.3,0.1c0.2,0.1,0.4,0.1,0.6,0c0-0.1,0.1,0,0.1,0
	c0.2,0,0.2-0.1,0.4-0.2c0.1,0,0.3,0,0.4,0c0.2,0,0.4-0.2,0.7-0.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1,0,0.1-0.1,0.2
	c0,0,0,0,0,0c0-0.2,0.2-0.1,0.3-0.1c0-0.1,0.4-0.3,0.5-0.3c0.1,0,0.2,0.1,0.2,0.1c0,0,0,0,0,0c0.1,0,0.1-0.1,0.1-0.2
	c0,0,0.4,0.1,0.4,0.1c0.1,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0.1,0.2,0.1c0-0.1,0.3-0.2,0.4-0.2c0.1,0,0.1,0.1,0.1,0.1c0,0.1,0.1,0,0.1,0
	c0.1,0,0.2,0.2,0.3,0.2c0.1,0.1,0.3,0.1,0.4,0.2c0.2,0.2,0.4,0.4,0.7,0.6c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.3,0.4,0.4
	c0.2,0.2,0.4,0.3,0.5,0.5c0.3,0.3,0.5,0.6,0.8,0.9c0.5,0.5,1,0.8,1.5,1.2c0.7,0.4,1.3,0.9,2,1.3c1,0.7,1.9,1.5,2.9,2.2
	c0.6,0.5,1.1,0.9,1.6,1.5c0.1,0.1,0.2,0.2,0.4,0.4c0.1,0,0.1,0.1,0.2,0.1c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.2,0.1,0.4,0.2
	c0.2,0.2,0.6,0.5,0.7,0.8c0.2,0,0.2,0.2,0.2,0.3c0.1,0,0.1,0,0.2-0.1c0,0,0,0,0,0c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.2,0.3
	c0.2,0.3,0.5,0.5,0.8,0.8c0.4,0.4,0.7,0.9,1.1,1.3c0.2,0.2,0.5,0.2,0.6,0.5c0.2,0,0.3,0.1,0.4,0.1c0,0,0,0,0,0
	c0.1,0,0.2,0.3,0.3,0.3c0.3,0.2,0.5,0.3,0.8,0.5c0.1,0.1,0.3,0.2,0.4,0.3c0.2,0.2,0.5,0.3,0.8,0.5c0.3,0.3,0.6,0.6,0.8,0.9
	c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.1,0.2,0.2,0.3c0.1,0.1,0.2,0.1,0.2,0.2c0.1,0.1,0.2,0.3,0.2,0.5c0.2,0,0.4,0.2,0.5,0.4
	c0.1,0,0.1,0,0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2c0.1,0,0.2,0.2,0.3,0.3c0,0,0,0,0,0c0.1,0,0.2,0,0.2,0
	c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.1,0.5,0.3,0.8,0.3c0.3,0,0.6,0,0.9,0c0.2,0,0.4,0,0.6,0c0.2,0,0.2-0.2,0.5-0.1c0,0,0-0.1,0-0.1
	c0,0,0,0,0,0c0,0,0,0,0-0.1c0,0,0,0,0,0c0.3-0.4,0.8-0.4,1.2-0.8c0.2-0.2,0.4-0.6,0.7-0.7c0,0,0,0,0,0c0-0.1,0-0.3,0-0.4
	c0,0,0,0,0,0c0.1,0,0.2-0.1,0.2-0.2C114.5,41.7,114.8,41.2,114.6,41.1 M79.4,14.1c0,0-0.1,0-0.1,0c0,0.1-0.2,0-0.2,0c0,0,0,0,0,0
	c0-0.1-0.1,0-0.2,0.1c-0.1,0-0.3-0.1-0.4-0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0.2-0.1,0.2-0.1c0.1,0,0.2,0.1,0.2,0.1
	C79,14,79.2,14,79.4,14.1C79.4,14.1,79.4,14.1,79.4,14.1 M79.8,14.1C79.8,14.1,79.8,14.1,79.8,14.1C79.8,14.1,79.8,14.1,79.8,14.1
	C79.8,14.1,79.7,14.1,79.8,14.1C79.7,14.1,79.7,14.1,79.8,14.1c-0.1,0-0.1,0-0.1,0c0-0.1,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0
	C79.8,14,79.8,14.1,79.8,14.1 M81.3,23.1c0,0,0.6-0.4,1.2-0.8C82.6,22.4,82.6,22.6,81.3,23.1z M91.3,20.8l-0.1-0.2
	c1.4-0.7,2.8-0.7,2.8-0.7C93.5,19.9,91.3,20.8,91.3,20.8"
      />
      <path
        fill="currentColor"
        d="M99.4,46.2c-0.1-0.2-0.3-0.3-0.4-0.4c0-0.1-0.1-0.2-0.1-0.3c-0.1-0.1-0.2-0.2-0.3-0.4c0,0,0,0,0,0c0,0,0,0-0.1,0
	c0,0.1,0,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0c-0.1-0.4-0.5-1-0.9-1.2c-0.1,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0
	c-0.1,0-0.1-0.1-0.1-0.2c0,0,0,0,0-0.1c-0.3,0-0.6-0.2-0.7-0.4c0,0,0,0,0-0.1c-0.1,0-0.3,0-0.3,0c0,0,0,0,0,0
	c-0.1,0-0.2-0.4-0.6-0.3c0-0.1-0.4-0.4-0.5-0.3c0,0,0,0-0.1,0c0,0.1-0.1,0-0.1,0c-0.2-0.1-0.4-0.2-0.6-0.3c-0.2-0.1-0.3-0.3-0.5-0.3
	c0,0,0,0,0,0c-0.2-0.1-0.6-0.1-0.7-0.4c0,0,0,0,0,0c-0.2,0-0.3-0.1-0.4-0.2c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0,0,0,0.1,0,0.1
	c0,0,0,0-0.1,0c0-0.4-0.6-0.4-0.8-0.7c-0.4,0-0.7-0.2-1.1-0.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0
	c-0.2,0-0.5-0.2-0.6-0.4c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0c-0.1,0-0.2-0.1-0.2-0.1c-0.3-0.1-0.6-0.2-0.8-0.5c0,0,0,0,0,0
	c-0.2,0-0.3-0.1-0.5-0.2c0,0,0,0,0,0c0.2,0,0.4,0,0.6,0c0.1,0,0.2-0.1,0.3,0c0.1,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0
	c0.2,0,0.6-0.4,0.7-0.6c0-0.1,0.1,0,0.2,0c0.4,0,0.8-0.6,1.2-0.9c0-0.1,0-0.1-0.1-0.2c0,0,0,0,0,0c0-0.1,0.5-0.4,0.1-0.5
	c0-0.1,0.1-0.1,0.1-0.2c0.1-0.2,0.1-0.3,0.3-0.4c0-0.1-0.1-0.1-0.1-0.2c0.1,0,0.1-0.3,0.1-0.3c0,0,0,0,0-0.1c0,0,0,0,0,0
	c0-0.1,0,0,0.1,0c0.2,0,0.3-0.5,0.4-0.6c0,0,0,0,0,0c0,0-0.1-0.5-0.1-0.6c0-0.1,0-0.3-0.1-0.4c0-0.1-0.1-0.1-0.1-0.2
	c-0.1-0.1-0.1-0.3-0.2-0.4c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-0.3-0.6-0.6-0.9-0.9c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.2-0.1-0.4-0.2-0.5
	c-0.2-0.3-0.4-0.4-0.2-0.7c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.3,0c0,0-0.1,0-0.1,0c-0.2,0.2-0.3,0.5-0.3,0.7
	c0,0.1,0,0.1,0,0.2c-0.1,0.2-0.2,0.3-0.3,0.5c-0.3,0.4-0.7,0.6-1,0.9c-0.1,0.1-0.2,0.2-0.3,0.2v0.1c-0.1,0-0.2,0.3,0.1,0.3
	c0-0.1,0.2-0.2,0.3-0.3c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0.3,0,0.3c-0.1,0.2-0.3,0.4-0.5,0.5c-0.1,0.1-0.2,0.2-0.3,0.3
	c-0.3,0.2-0.6,0.4-0.9,0.6c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.1-0.4,0.2c-0.1,0.1-0.2,0.2-0.3,0.3c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0,0c0,0-0.4-0.4-0.5-0.5c-0.1,0,0-0.3,0-0.4c-0.1,0-0.1-0.1-0.1-0.1c-0.1-0.2,0-0.4,0-0.6c0-0.2-0.1-0.4-0.2-0.6
	c-0.1-0.2-0.1-0.4-0.2-0.5c-0.1-0.2-0.1-0.5-0.2-0.7c-0.1-0.2-0.2-0.4-0.2-0.6c-0.1-0.3-0.1-0.7-0.2-1c-0.1-0.2-0.2-0.4-0.2-0.7
	c-0.1-0.2-0.1-0.4-0.2-0.5c-0.1-0.3-0.4-0.5-0.7-0.7c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.2,0.1-0.5-0.1-0.7
	c0,0.2-0.1,0.2-0.2,0.4c-0.1,0-0.1,0-0.2-0.1c0,0,0,0,0,0c0,0,0,0,0,0c-0.1,0-0.2,0.2-0.2,0.3c-0.1,0.1-0.3,0.2-0.4,0.3
	c-0.1,0.2-0.3,0.5-0.3,0.6c0,0.2,0,0.5-0.1,0.7c-0.1,0.6-0.1,1.3-0.1,1.9c0,0.7,0.1,1.5,0.1,2.2c0,0.1,0,0.2,0,0.3
	c0.1,1.1,0.2,2.1,0.2,3.2c0,1.2,0.1,2.3,0.1,3.4c0,0.5,0.2,1,0.2,1.5c0,0.3,0,0.5,0,0.8c0,0.4,0,0.5-0.2,0.8c-0.1,0-0.2,0-0.3,0
	c0,0,0,0,0,0c0,0.1-0.4,0.2-0.5,0.3c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.2,0-0.3,0c0,0.4-0.6,0.1-0.7,0.3c-0.2,0.3,0,0.8,0.1,1.1
	c0,0.1,0,0.2,0.1,0.4c0,0.1,0.2,0.2,0.2,0.2c0,0,0,0,0,0c0,0,0,0,0,0.1c-0.1,0,0,0-0.1,0.1c0,0.2,0.1,0.2,0.1,0.4
	c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.2,0.1,0.4c-0.1,0-0.1,0-0.1,0.1c-0.1,0,0.1,0.2,0.1,0.2
	c0,0.3,0,0.7,0.3,0.8c0,0,0,0,0,0c-0.1,0,0,0.3,0,0.3c0.1,0.1,0.1,0.3,0.3,0.4c0.1,0,0.2,0,0.2,0.1c0.4,0.4,0.7,0.6,1.2,0.7
	c0.1,0,0.2,0,0.3,0c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0,0.3,0,0.5,0c0-0.1,0,0,0.1-0.1c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.2,0
	c0.2-0.1,0.3-0.2,0.4-0.3c0,0,0,0,0,0c0.3,0,0.5-0.3,0.7-0.5c0.1-0.1,0.1-0.2,0.2-0.2c0-0.1-0.1-0.2,0-0.3c0,0,0,0,0,0
	c0-0.1,0.1,0,0.1-0.1c0.2,0,0.3-0.3,0.3-0.5c0,0,0-0.1,0-0.1c0-0.1,0.1-0.3,0.1-0.5c0-0.1,0-0.2,0-0.3c0,0,0,0,0-0.1
	c-0.2,0,0-0.3,0.1-0.4c0,0,0,0,0,0c-0.1,0-0.2-0.5-0.1-0.6c0-0.1-0.1-0.2-0.1-0.2c0-0.2-0.1-0.3-0.1-0.5c-0.1-0.1-0.1-0.2-0.2-0.2
	c-0.1,0,0,0,0-0.1c0.1-0.4-0.3-1,0.3-0.9c0.3,0,0.7,0.4,0.9,0.5c0.2,0.1,0.5,0.2,0.7,0.2c0.2,0,0.3,0.1,0.5,0.1c0.1,0,0.3,0,0.4,0
	c0.3,0,0.6,0.1,0.8,0.1c0,0.3,1,1.1,1.3,1.2c0,0.1,0,0.2,0.1,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0,0.1,0.1,0.1,0.1,0.2
	c0.1,0.2,0.3,0.3,0.4,0.5c0.1,0,0.2,0,0.2,0.1c0.2,0.1,0.4,0.2,0.6,0.4c0,0,0,0,0,0c0.1,0,0.1-0.1,0.1-0.1c0.3,0,0.6,0.8,1,0.6
	c0,0,0,0,0,0c0,0.1,0.2,0.3,0.2,0.4c0.2,0,0.3,0.2,0.3,0.3c0.2,0,0.6,0.1,0.7,0.3c0.1,0,0.1,0,0.2-0.1c0,0,0,0,0,0
	c0.1,0,0.1,0.1,0.1,0.1c0,0.1,0.1,0,0.2-0.1c0,0,0,0,0,0c0.1,0,0.1,0.1,0.2,0.1c0.3,0.1,0.6,0.2,0.9,0.1c0,0.1,0.2,0.1,0.3,0.2
	c0-0.1,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.2,0c0.2,0,0.3-0.1,0.4,0c0,0,0,0,0,0c0.1,0,0.1,0,0.2-0.1c0.3-0.1,0.6-0.2,0.9-0.4
	c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0,0.2-0.1,0.2-0.1c0.1-0.2,0.2-0.4,0.4-0.6c0,0.1,0.2,0,0.2-0.1c0.1-0.2,0-0.4,0-0.6c0,0,0,0,0,0
	c0,0,0.2-0.1,0.2-0.1c0.1-0.1,0-0.3,0-0.4c0-0.1,0.2-0.2,0.3-0.3c0.2-0.5,0.1-0.7,0-1.2C99.5,46.5,99.4,46.4,99.4,46.2 M87.6,39.2
	C87.6,39.2,87.5,39.2,87.6,39.2c-0.1,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.1-0.1-0.2-0.1c0-0.1,0-0.2,0-0.4c0.2,0.1,0.4,0.2,0.4,0.4
	C87.6,39.3,87.6,39.2,87.6,39.2"
      />
      <path
        fill="currentColor"
        d="M79.2,48.4c0-0.3-0.2-0.5-0.4-0.7c-0.1-0.2-0.3-0.7-0.4-0.9c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.1,0-0.2c0-0.2,0-0.5,0.1-0.7
	c0.1-0.4,0.4-0.7,0.5-1.1c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c-0.3,0.3-0.9,1-0.9,1c-0.3,0.2-0.3-0.1-0.3-0.1c0.2-0.2,0.9-1,0.9-1v-0.2
	c-0.2,0.4-0.7,0.7-0.7,0.7c0.2-0.4,0.2-0.7,0.2-0.7l0.2-0.3L77.7,44l0.1-0.2c-0.2,0.3-0.2,0.1-0.2,0.1l-0.7,0.7
	c-0.2,0-0.3,0.2-0.3,0.2c-0.3,0.4-1.1,0.9-1.1,0.9l0-0.2c-0.2,0-0.2-0.3-0.2-0.3c0.3-0.3,0.2-0.5,0.2-0.5C75.2,45,75.1,45,75.1,45
	c0-0.1,0-0.4,0-0.4c-0.1,0.2-0.2,0.2-0.2,0.2c-0.1-0.2,0-0.5,0-0.5c0,0.2,0.3-0.1,0.3-0.1c0.2-0.1,0.1-0.2,0.1-0.2
	c-0.1-0.1-0.3,0-0.3,0c0.6-0.9,1.1-1,1.1-1c-0.5,0.4-0.3,0.5-0.3,0.5c0.1,0.4,0.4,0.1,0.4,0.1c0.2-0.1,0.4,0,0.4,0
	c0.3,0.1,0.4,0,0.4,0c1.1-0.5,1.9-1.4,1.9-1.4c0.8-0.1,1.3-0.9,1.3-0.9c0-0.2,0.3-0.4,0.3-0.4c0-0.2,0.2-0.2,0.2-0.2
	c0-0.1,0-0.3-0.1-0.3c0,0,0,0,0-0.1c0.1,0,0.1,0,0.1-0.1c0.3-0.3-0.2-0.5-0.3-0.7c0-0.1,0-0.3,0-0.4c0,0-0.1,0-0.1,0
	c0-0.1-0.2-0.5-0.3-0.6c-0.1-0.2-0.1-0.4-0.2-0.6c-0.1-0.2-0.3-0.3-0.4-0.5c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.1-0.3-0.2
	c-0.2-0.2-0.3-0.5-0.5-0.7c-0.2-0.1-0.4-0.3-0.5-0.4c-0.1-0.1-0.2-0.3-0.4-0.3c0-0.1-0.1-0.1-0.1-0.1c-0.1-0.1-0.3-0.2-0.3-0.3
	c-0.2-0.3-0.1-0.7-0.4-0.9c-0.1,0,0-0.1,0-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.2-0.1-0.3-0.1-0.4-0.3c-0.1-0.2,0-0.5-0.2-0.6
	c0,0.1-0.1,0.6-0.3,0.4c0,0,0,0-0.1,0c0,0.4-0.7,0.2-0.8,0.6c-0.1,0-0.3,0.3-0.4,0.4c0,0,0,0,0,0c0,0,0,0,0,0c0,0.1-0.2,0.3-0.2,0.5
	c0,0.2,0.2,0.4,0.3,0.6c0,0.1,0,0.3,0.1,0.4c-0.1,0-0.4,0.6-0.2,0.6c0,0.1,0,0.1,0.1,0.2c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0
	c0.2,0,0.2,0.3,0.3,0.4c-0.1,0-0.2,0-0.3-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0.2,0.1,0.3,0.2,0.5c0.2,0.4,0.6,0.8,0.9,1.2
	c0.1,0.1,0.3,0.3,0.4,0.4c0,0.1,0.1,0.2,0.1,0.3c0.1,0.3,0.3,0.5,0.4,0.8c0,0.2,0,0.3,0,0.5c-0.1,0.2-0.2,0.3-0.3,0.5
	c-0.3,0.4-0.5,0.7-0.8,1.1c-0.4,0.5-0.7,1.1-1.2,1.6c-0.2,0.2-0.4,0.3-0.5,0.5c-0.1,0.1-0.2,0.3-0.3,0.5c-0.1,0.1-0.1,0.2-0.2,0.3
	c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1-0.1,0.2c-0.1,0.1-0.2,0.3-0.2,0.4c-0.3,0.7-0.1,1.9,0.3,2.4c0.3,0.4,0.8,0.6,1.3,0.8
	c0.1,0.1,0.1,0.1,0.2,0.2c0.2,0.1,0.4,0.2,0.6,0.3c0.2,0.1,0.4,0,0.5,0.3c0.5,0,0.8,0.3,1.2,0.4c0.2,0.1,0.6,0.1,0.8,0.2
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0.1,0c0-0.2,0.6-0.3,0.7-0.4c0.1-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0-0.5,0-0.8
	C79.1,48.9,79.2,48.6,79.2,48.4 M80.6,40.6c0,0.1-0.1,0.3-0.1,0.3c-0.2,0.2-0.3,0.2-0.3,0.2C80.3,40.8,80.6,40.6,80.6,40.6
	 M79.4,41.5c0,0-0.3,0.5-1,1c0,0-0.1-0.2-0.5,0C77.9,42.4,79.1,41.5,79.4,41.5z M76.5,42.2c0,0-0.5,0.7-0.9,0.9
	C75.7,43.1,76.1,42.3,76.5,42.2z"
      />
      <path
        fill="currentColor"
        d="M163,20.7c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2
	c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.3c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0
	c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2,0-0.3v0c-0.1,0-0.1-0.3-0.1-0.3c0,0-0.1-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2
	l-0.1-0.2c0,0,0-0.1-0.1-0.1c0-0.1,0-0.1-0.1-0.2c-0.1-0.1-0.1-0.2-0.2-0.3c0,0,0-0.1,0-0.1c-0.1-0.2-0.3-0.4-0.3-0.6h0
	c0,0-0.1,0-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c0,0-0.1,0-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.2c0-0.1-0.1-0.1-0.1-0.2c0,0-0.1-0.1-0.1-0.1
	c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1-0.1-0.1-0.2-0.2-0.2c0,0-0.1,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1
	c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.3-0.3-0.4-0.3c0-0.1-0.6-0.1-0.7-0.2v0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0.1
	c-0.1,0-0.1,0.1-0.1,0.1c0,0,0,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.1,0,0.1c0,0.1,0,0.2,0,0.2c0,0-0.1,0.1,0,0.2
	c0,0.1,0.1,0.2,0.1,0.3c0,0,0,0.1-0.1,0.1c0,0,0.2,0.1,0.2,0.2c0.1,0,0,0.2,0,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2
	c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0,0,0c-0.1,0-0.1,0.1-0.1,0.2c-0.1,0-0.2,0-0.2,0.1c-0.1,0-0.1,0.1-0.1,0.1
	c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1-0.1c-0.2-0.1-0.2-0.2-0.1-0.3c0,0,0-0.1,0-0.1c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1,0-0.1
	c0-0.1-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.2-0.2-0.3c0,0-0.1,0-0.1,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.2-0.2-0.3c0,0,0,0-0.1,0
	c0,0-0.2-0.1-0.2-0.1c0,0,0-0.1,0-0.1c-0.2,0-0.1-0.2-0.2-0.3c0,0,0-0.1,0.1-0.1c0,0-0.1-0.1-0.1-0.2c0-0.1-0.1-0.2,0-0.2
	c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0.1-0.2c0,0,0,0,0.1-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1c0-0.1,0-0.1,0-0.2
	c0-0.1,0.1-0.1,0.1-0.2c0.1,0,0.3,0,0.3-0.1c0,0,0-0.1,0.1-0.1c0,0,0.1,0,0.1-0.1c0.1-0.1,0.1-0.2,0.1-0.2c0.1,0,0.4-0.4,0.4-0.5
	c0-0.1,0.1-0.2,0.1-0.3c0,0-0.1,0-0.1,0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1-0.1-0.1
	c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c0-0.1-0.1-0.1-0.2-0.2c0-0.1,0-0.2-0.1-0.2c-0.1-0.1-0.2-0.1-0.3-0.1c0-0.1,0-0.2,0-0.3
	c0-0.1,0-0.1-0.1-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
	c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0.1-0.2c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0-0.1,0.2,0,0.2,0l0.1-0.1c0,0,0.1,0,0.1,0c0,0,0-0.1,0-0.1
	c0.2,0,0.1,0,0.3-0.1c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0.2-0.3c0,0,0-0.1,0-0.2c0-0.1,0.1-0.2,0.1-0.2c0,0,0.1-0.1,0.1-0.1
	c0,0,0-0.1,0-0.1c0,0,0.2-0.2,0.2-0.3v0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0v0c0,0,0-0.2,0-0.2c0,0,0,0,0,0c0,0,0,0,0.1-0.1
	c0.1-0.1,0.1-0.2,0-0.3h0c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.2-0.3c0,0,0-0.1-0.1-0.1c0,0,0,0-0.1,0c0,0-0.1-0.1-0.1-0.2L157.4,5h0
	c0,0,0,0,0-0.1v0h0v0c-0.1,0-0.1,0-0.1,0c0,0,0-0.1-0.1-0.1c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.2-0.1c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1
	c0,0,0,0-0.1,0c-0.1,0-0.2-0.1-0.2-0.2c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.2-0.1-0.3-0.1-0.5c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
	c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1c0,0,0,0-0.1,0c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.1-0.2-0.2h0c0,0,0,0,0,0.1
	c-0.1,0-0.1-0.1-0.1-0.1l-0.1,0c-0.1,0-0.1-0.1-0.2-0.1c0-0.1-0.2-0.1-0.2-0.1c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1-0.1-0.2-0.1
	c0,0,0,0,0,0c-0.2,0-0.3,0.1-0.5,0.2c-0.1,0-0.2,0-0.2,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0.2-0.2,0.3c0,0-0.2,0-0.2,0
	c0,0-0.1,0-0.1,0c0,0.1,0,0.1,0,0.1c0,0.1-0.3,0.1-0.4,0.1c-0.1,0-0.1-0.1-0.1,0c-0.1,0-0.3,0.1-0.4,0.1c0-0.1-0.2,0-0.3,0
	c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.2,0c0,0-0.1,0-0.1,0.1c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0
	c-0.1,0-0.2,0.1-0.2,0.1c0,0,0,0.1-0.1,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0.1-0.2,0.2-0.3,0.3c0,0.1,0,0.1,0,0.2c0,0-0.1,0.1-0.1,0.1
	l0,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0.1,0.2v0c0,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0.1c0,0.1-0.1,0.2,0,0.3c0,0.1,0.1,0.1,0.1,0.2
	c0,0.1,0,0.1,0,0.2c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.2,0.2v0c0,0,0,0,0,0c0,0.1,0.2,0.2,0.2,0.2c0.1,0.1,0,0.3,0.2,0.3
	c0,0,0.1,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0,0,0,0,0c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0.1
	c0.1,0,0.1,0,0.2,0.1c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0v0c0,0,0,0,0,0.1c0,0.2,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0c0,0,0,0.1,0.1,0.1
	c0.1,0.1,0.1,0.1,0.2,0.1c0,0,0,0,0.1,0.1c0.1,0,0.2,0.1,0.3,0.2c0.1,0,0.1,0,0.2,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0.1,0.1
	c0,0,0,0,0.1,0.1c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0.1,0,0.2,0,0.2h-0.1c0,0,0,0,0,0v0c-0.1,0-0.1,0.2-0.1,0.2
	c-0.1,0-0.1,0.1-0.1,0.2v0h0c0-0.1-0.2,0-0.2,0c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2,0c0,0-0.1-0.1-0.1-0.1
	v0c0,0,0,0-0.1,0.1v0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1-0.1v0c-0.1,0,0,0.1-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.2-0.1
	c0,0,0,0,0,0h0c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.2,0c0,0-0.2,0.2-0.2,0.2h0c0-0.1-0.2,0-0.2,0c0,0.2-0.1,0.5,0,0.7v0
	c0,0,0,0,0,0c0,0.2,0.2,0.3,0.3,0.5c0,0.1,0.1,0.2,0.1,0.3c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.1c0,0.2,0.2,0.2,0.4,0.3
	c0,0,0,0.1,0.1,0.2c0,0,0.1,0,0.1,0c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0c0,0.1,0.1,0.2,0.1,0.3c0.1,0.1,0.2,0.1,0.3,0.1v0
	c0,0,0,0,0,0c0,0.1,0.1,0.3,0.1,0.3c0.1,0.1,0.1,0.1,0.2,0.2c0,0.1,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1v0
	c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.3,0,0.4c0,0-0.2,0.1-0.2,0.1c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0-0.2,0v0
	c-0.1,0-0.2,0-0.2,0h0c0,0,0,0,0,0c0-0.1-0.4,0.1-0.5,0.1v0c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0.1-0.1,0.1c0,0.2,0,0.1-0.2,0.2v0
	c-0.1,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0l-0.1,0.2v0c0,0,0,0-0.1,0v0c0,0,0,0.1,0,0.1c-0.1,0-0.2,0.1-0.2,0.2v0
	c0,0,0,0-0.1,0v0h0c0,0,0,0,0,0v0h0c0,0,0,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.1c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0c0,0.1,0,0.2,0,0.2
	c0,0-0.1,0.1-0.1,0.1c0,0,0.1,0.3,0.1,0.3c0,0.1-0.1,0-0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.1,0.2
	c0,0,0,0.1,0,0.1c0,0,0.2,0.3,0.2,0.3c0,0,0,0.1,0,0.1c0,0.1,0.2,0.1,0.1,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.2,0,0.1,0.1,0.2,0.1
	c0,0,0.1,0,0.1,0c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0,0,0c0.1-0.1,0.3,0.1,0.3,0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0.1,0.3,0.1
	c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0.1,0.2,0.1h0V18h0c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.1,0,0.3,0,0.3,0
	c0,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0,0,0.1,0c0,0.1,0.1,0,0,0.1c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0-0.1,0
	c-0.1,0-0.2,0.1-0.2,0.1c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0.1v0c0,0,0,0,0,0.1c0.1,0.3-0.7,0.5-0.9,0.3h0
	c0,0,0,0,0,0c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2,0-0.3,0.1c-0.2,0-0.3,0.1-0.4,0.1c-0.1,0-0.2,0-0.3,0c0,0,0,0-0.1,0
	c-0.1,0-0.1,0-0.2,0c-0.1,0-0.3,0-0.3-0.1c0-0.1,0.1-0.1,0.1-0.2c0.1,0,0.1-0.2,0.2-0.3c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.2
	c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1,0-0.1s0-0.1,0-0.1c0-0.1,0.1-0.3-0.1-0.4c0-0.1,0.1-0.1,0-0.2c0-0.1-0.1-0.1-0.1-0.2
	c0-0.1,0-0.2,0-0.2c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.3-0.1-0.4c0-0.1,0-0.2,0-0.2
	c0,0,0-0.1,0-0.1h0c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2v0c0,0-0.1,0-0.1,0c0,0,0-0.1-0.1-0.1c0-0.1,0.1-0.1,0.2,0
	c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.1,0-0.2c0,0,0,0,0,0c0-0.1,0-0.1,0-0.1c0-0.1,0-0.3,0-0.4
	c0-0.1,0-0.1-0.1-0.1c0-0.1,0-0.2,0-0.2c0,0,0,0,0,0h0c0,0-0.1,0-0.1-0.1c0-0.1,0-0.2,0-0.2c0,0,0,0-0.1,0c0-0.1,0.1-0.1,0.1-0.1
	c0-0.1,0-0.1,0-0.2c0-0.1,0.1,0,0.2,0h0c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.2c0,0-0.1-0.1-0.1-0.1c0-0.1,0.1-0.2,0.1-0.2
	c0-0.1,0-0.1,0-0.2c0-0.2,0-0.3,0.1-0.5c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3
	c0-0.2,0-0.4,0-0.6c0-0.1,0-0.3,0-0.4c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2-0.1-0.3c0-0.1,0-0.3,0-0.4c0,0,0-0.1,0-0.1
	c0-0.1,0-0.2-0.1-0.3c0-0.1,0-0.6,0-0.7c0,0-0.1,0-0.1,0c0,0,0,0.1-0.1,0.1v0h0c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1,0-0.2,0-0.3
	c0,0,0-0.1,0-0.1c0-0.2,0-0.4-0.1-0.6c0-0.1-0.1-0.2-0.1-0.2c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.2-0.1-0.5-0.2-0.7c0-0.1-0.1-0.1-0.1-0.2
	c0-0.1-0.1-0.3-0.1-0.4c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.1c0-0.1,0-0.1,0-0.1c0-0.1-0.1-0.2-0.1-0.3
	c-0.1,0-0.2-0.4-0.3-0.4c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1-0.1-0.1c0-0.1-0.1-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.1
	c-0.2-0.1-0.5-0.2-0.7-0.3h0c0,0-0.1,0.1-0.1,0.1v0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.3,0.1-0.4,0.2c0,0.1-0.1,0.2-0.1,0.3
	c0,0,0,0.1,0,0.1c0,0.1,0,0.3-0.1,0.4c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1-0.1,0.2c0,0.1,0,0.2,0,0.3
	c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.3,0,0.4c0,0.1,0,0.1,0,0.2
	c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2c0,0.2,0,0.4,0,0.6
	c0,0.1,0,0.3,0,0.4c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.1-0.1,0.2c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.2
	c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1-0.1,0.1
	c0,0.1,0.1,0.1,0.1,0.1c0,0.1-0.1,0.3,0,0.4c0,0,0,0,0,0c0,0.1,0,0.2,0,0.2c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.3,0,0.4
	c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0.2,0,0.2c0,0.1,0.1,0.2,0.1,0.3
	c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.1,0,0.2c0,0.3,0,0.5,0.1,0.7c-0.1,0,0,0.6,0,0.7l0.1,0.3c0,0.2,0,0.4,0.1,0.6c0,0.1,0,0.1,0.1,0.2
	c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0.1,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0,0.1h0c0,0,0,0,0.1,0h0
	c0,0.1,0.1,0.2,0.1,0.3c0,0,0.1,0.1,0.1,0.1c0,0.1,0,0.2,0.1,0.4c0,0,0,0,0,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0,0.1c0,0,0,0,0,0
	c0,0,0,0.1,0,0.1c0.1,0,0,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.2c0.1,0.2,0.1,0.5,0.4,0.5c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0,0,0.1
	c-0.1,0-0.2,0.1-0.4,0.1c0,0-0.3,0.1-0.3,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0.1-0.2,0v0c-0.1,0-0.1,0.2-0.3,0.1c0,0-0.1,0-0.1,0
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0h0c0,0,0,0.1-0.1,0.1v0c0,0,0-0.1,0-0.1c-0.1,0,0,0.1-0.2,0c0,0-0.1,0-0.1,0
	c-0.1,0-0.2,0-0.2,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2,0-0.3,0c0,0,0,0,0,0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0-0.2,0.1-0.4,0.1
	c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.1v0c0,0,0-0.1,0-0.1c0-0.1,0.1-0.1,0.1-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.1,0.1-0.2
	c0-0.1,0-0.2,0-0.2c0-0.1,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0.1-0.3-0.1-0.6,0.3-0.8v0h0c0,0,0,0,0,0c0,0,0-0.1,0-0.1v0l-0.1,0
	c-0.1,0,0-0.2,0-0.2c0.1-0.2,0.2-0.3,0.3-0.4c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.1,0.2-0.1c0.1-0.1,0.2-0.2,0.3-0.3
	c0-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1-0.1-0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.2,0.2c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2,0.1-0.2,0.2
	c0,0-0.3,0.1-0.4,0.2h0c0,0,0,0,0,0c-0.1,0.1-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c0,0-0.1,0-0.1,0c0-0.1,0-0.1-0.1-0.2
	c0,0-0.1,0.1-0.2,0.1c-0.1,0-0.1-0.1-0.1-0.1c-0.1,0-0.1,0-0.2,0c0-0.1,0.1-0.2,0.1-0.3c-0.1,0-0.3-0.3-0.3-0.4
	c-0.1,0-0.2,0-0.2-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c0-0.2-0.1-0.4-0.1-0.6c0-0.1,0-0.2,0-0.3
	c0-0.1-0.1-0.3,0-0.4c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0.2-0.1,0.3-0.2c0.1,0,0.1-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0-0.1,0.2-0.1,0.1-0.3
	c0,0,0.1,0,0.1-0.1c0-0.1,0-0.2,0-0.3c0,0,0-0.1,0.1-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.2,0.1-0.2c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
	c0,0-0.1,0-0.1,0v0h0v0c0,0,0,0,0,0c0.1-0.1-0.1-0.2-0.2-0.2c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.1-0.1-0.1c0-0.1-0.1-0.1-0.1-0.2
	c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.1-0.1-0.2c0-0.1,0-0.1,0.1-0.2c0-0.1,0-0.2-0.1-0.3c0-0.1,0-0.1,0-0.2c-0.1,0-0.1-0.1-0.1-0.2
	c0,0,0.1,0,0.1,0c0-0.1,0-0.1,0-0.2h0c0,0-0.1-0.1-0.2-0.1h0v0c0,0,0.2-0.1,0.2-0.2h0v0h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1
	c0-0.1,0.1-0.1,0.1-0.2c0.1-0.1-0.1-0.3-0.2-0.4c0-0.1,0.2-0.2,0.1-0.3c0,0,0-0.1,0-0.1c0-0.1,0-0.2-0.1-0.3c0,0,0-0.1,0-0.1
	c0-0.1,0-0.2,0-0.3c0,0-0.1,0-0.1,0c0-0.1,0.1-0.2,0.1-0.2c0-0.1,0-0.1,0.1-0.2c0.1-0.2,0.3-0.3,0.4-0.4c0,0,0.1,0,0.1,0
	c0.1,0,0.1-0.1,0.2-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0.1,0,0.5-0.2,0.6-0.3c0.1-0.1,0.2-0.1,0.2-0.2c0,0,0.1,0,0.1,0
	c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0,0.1,0.1v0c0.1,0,0.3-0.3,0.3-0.3v0c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0-0.1,0.1-0.1,0.2-0.2
	c0.1-0.1,0.1-0.2,0.2-0.2c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.3-0.2,0.4-0.2c0.1-0.1,0.1-0.1,0.1-0.2c0,0,0-0.1,0-0.1
	c0-0.1,0.3-0.3,0.3-0.3c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.3,0-0.4c0,0,0,0-0.1,0
	c-0.1-0.1-0.1-0.1-0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.2-0.2c0,0,0-0.1,0-0.1
	c0-0.1-0.1-0.1-0.1-0.1c0,0,0,0-0.1-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0,0,0,0,0
	c-0.2,0-0.5-0.1-0.5,0.2h0c0,0-0.2,0-0.2,0c0,0-0.1,0.1-0.1,0.2v0c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0c0,0-0.1,0.1-0.1,0.1v0
	c0,0,0,0-0.1,0c0,0,0,0.1,0,0.1h0c0,0,0,0,0,0c-0.1,0-0.1,0-0.1,0.1h0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0.1L141,4.5
	c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0c-0.1,0.1-0.1,0.1-0.3,0.1c0,0-0.2,0.1-0.2,0.1c0,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2-0.1
	c0,0,0-0.1,0-0.1c0-0.1-0.1-0.2-0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0,0,0,0-0.1,0c0,0,0-0.1,0-0.1c0-0.2-0.1-0.3-0.2-0.5
	c0-0.1,0-0.1-0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.1-0.2-0.2-0.3c0,0-0.1,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.2,0-0.2,0
	c-0.1,0-0.2,0-0.2,0c-0.1,0-0.2,0-0.3,0c-0.1,0-0.3,0.1-0.3,0.2c-0.1,0.2,0.1,0.3,0.1,0.5c0,0.2,0,0.5,0,0.7c0,0.1,0,0.2,0,0.2
	c0,0.1,0,0.2,0,0.2c0,0.2,0.1,0.3,0.1,0.5c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c-0.1,0.1-0.2,0.1-0.3,0.2
	c-0.1,0-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1
	c-0.1,0-0.2,0.1-0.2,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0.1-0.2,0.1
	c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0,0,0c-0.2,0.1-0.3,0.2-0.4,0.3c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1
	l-0.2,0.1c-0.1,0.1-0.2,0.1-0.2,0.2c0,0,0,0.1-0.1,0.1c-0.1,0.1-0.4,0.3-0.4,0.4c0,0.1,0,0.1,0.1,0.2c0,0-0.1,0.1-0.1,0.1
	c0,0-0.1,0-0.1,0c-0.1,0-0.1,0.1-0.2,0.1c0,0,0-0.1,0-0.1v0h0v0c-0.1,0-0.1,0.1-0.1,0.2h0v0c0,0,0.1,0,0.1,0c0,0.3-0.2,0.1-0.3,0.2
	c0,0,0,0.1,0,0.1c-0.1,0.1-0.1,0.2-0.2,0.3c-0.1,0.1-0.1,0.2-0.2,0.3c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0.1,0.2c0,0.1,0.1,0.1,0.2,0.1
	c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.2,0.1,0.3,0,0.4c0,0,0,0.1,0,0.1c0,0,0.1,0,0.2,0.1c0,0,0.1,0,0.1,0.1c0.1,0,0.1,0,0.2,0.1
	c0.2,0.1,0.3,0.2,0.5,0.2c0,0.1,0.3,0.1,0.4,0.1c0.1,0,0.1,0,0.2-0.1c0.1,0,0.2,0,0.2,0c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1,0
	c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.2,0c0.1,0,0.1-0.1,0.2-0.2c0.1,0,0.1,0,0.2-0.1c0.1-0.1,0.1-0.1,0.2-0.1c0,0,0.2-0.1,0.2-0.2
	c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1-0.1,0.1-0.1
	c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1-0.1
	c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0.1,0.1,0,0.4,0,0.5c0,0.1,0,0.3,0,0.4c0,0.2,0,0.5,0,0.7
	c-0.1,0-0.2,0.2-0.2,0.2c0,0-0.1,0-0.1,0c-0.1,0-0.3,0-0.3,0.1h0v0c0,0,0-0.1,0-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0
	c-0.1-0.1-0.2,0-0.3,0.1v0c-0.1,0-0.1-0.1-0.2,0c-0.1,0-0.1-0.1-0.2-0.1h0v0c0,0-0.2,0.1-0.3,0.2c0,0.2-0.1,0.1-0.2,0.1v0
	c-0.1,0-0.1-0.1-0.1,0C136,12,136,12,136,12c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0-0.2,0c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0-0.1,0-0.1,0c0,0,0,0,0,0c-0.1,0.1-0.1,0.1-0.2,0.2c-0.1,0.1-0.2,0.2-0.3,0.2c0,0.1-0.1,0.1-0.1,0.1c0,0.1,0,0.1,0,0.2
	c0,0-0.1-0.1-0.1-0.1c0,0.1-0.1,0.1-0.1,0.1c0,0.1,0,0.1,0,0.2h0c0,0-0.1,0-0.1,0c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.2
	c0,0.1,0,0.1,0.1,0.2c-0.1,0-0.1,0.2-0.1,0.3c0,0,0,0,0,0c-0.1,0.1,0.1,0.3,0.2,0.3c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.1
	c0.1,0,0.2,0.1,0.3,0.1c0.1,0.1,0,0.2,0.1,0.2c0.1,0.1,0.3,0.3,0.4,0.4c0,0,0.1,0,0.1,0c0.1,0,0.1,0.1,0.2,0.1v0c0,0,0,0.1,0.1,0.1
	c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0.2,0,0.3,0,0.5,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.1-0.1
	c0.1,0,0.2,0,0.3,0c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0.1c0,0,0.2,0.1,0.2,0.1c0,0.1-0.1,0.3-0.1,0.2
	c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.1,0.1c-0.1,0.2,0.1,0.3,0.2,0.4c0,0,0,0.1,0.1,0.1c0,0.1-0.1,0.1-0.2,0.1c0,0,0,0-0.1,0v0h0
	c0,0,0,0-0.1,0.1v0c0.1,0,0.1,0,0.1,0c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0.1
	c-0.1,0.1-0.1,0.1-0.2,0.2c0,0,0,0,0,0c0,0.1,0,0.4,0.1,0.5c0,0,0,0,0,0c-0.1,0.1-0.1,0.2-0.2,0.3v0c0.1,0,0.1,0.3,0.1,0.4
	c0,0,0,0.1-0.1,0.1c0,0.1,0,0.3,0.1,0.3v0c0,0,0,0.1,0,0.2c0,0,0,0,0,0c0,0,0,0,0,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1-0.1,0.2,0,0.3v0
	c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2,0-0.2,0.1c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.2c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0.1v0
	c0,0,0,0-0.1,0v0c-0.1,0-0.2,0.1-0.3,0.2h0c0,0,0,0,0,0v0h0v0h-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0v0
	c-0.1,0-0.1-0.1-0.2-0.1c0,0.1-0.2,0.3-0.2,0.4c-0.1,0.2-0.2,0.3-0.2,0.5c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2
	c0.2,0,0.2,0.2,0.3,0.3c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0.1,0.2,0.1,0.2,0.1c0,0,0.1,0,0.1,0
	c0.3,0.1,0.6,0.1,0.9,0.2c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0c0.2,0,0.3,0,0.5,0c0.1,0,0.2,0,0.2,0c0.1,0,0.1,0,0.2,0
	c0.1,0,0.1,0,0.2-0.1c0.1,0,0.4,0,0.3,0.1c0,0.1-0.1,0.1-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1
	c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.4,0.1-0.5,0.2h0v0h0c0,0,0.1,0,0.1,0v0
	c0,0,0,0,0,0c0,0.1-0.3,0.3-0.4,0.3c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0.1c-0.1,0-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.2,0.1
	c-0.1,0-0.2,0-0.3,0.1c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0v0c0,0,0,0-0.1,0c-0.1,0-0.2,0-0.3,0.1
	c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2,0-0.3,0.1c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0
	c-0.1,0-0.2,0-0.3,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.2,0h0v0c0,0,0,0,0,0h0c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2,0-0.3-0.1
	c0,0,0,0-0.1-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0-0.1,0-0.1-0.1c0-0.1-0.2-0.5-0.2-0.6c0,0,0-0.1,0-0.1h0v0
	c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0.1-0.2,0.1-0.3-0.1-0.4c0,0-0.1,0-0.1-0.1c0-0.1,0-0.1,0-0.2c0-0.2-0.1-0.2,0-0.4
	c0,0,0-0.1,0-0.1c-0.2-0.4,0.2-0.7,0-1.1c0-0.1-0.1-0.2-0.1-0.3c0,0,0.1-0.1,0.1-0.1c0-0.1,0-0.3-0.1-0.4c0-0.1-0.1-0.2-0.2-0.3
	c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.1-0.2-0.2-0.3-0.4c0-0.1-0.1-0.1-0.1-0.2c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.2
	c-0.1-0.1-0.1-0.1-0.2-0.2c-0.1-0.1-0.2-0.2-0.3-0.3c-0.1,0-0.1,0-0.2,0c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.3-0.2,0.4
	c0,0-0.1,0-0.1,0.1c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.1-0.1,0.1-0.1,0.2c-0.1,0.2-0.2,0.4-0.3,0.6c0,0.1-0.1,0.2-0.1,0.4
	c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.1,0,0.2c-0.1,0.3-0.2,0.5-0.3,0.8c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2l0,0.1
	c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.3c0,0.2-0.1,0.5,0,0.7
	c0,0.1,0,0.1,0,0.2c0,0.1,0,0.2,0,0.3c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0.1,0.1c0,0.1,0,0.2,0.1,0.3
	c0,0,0,0.1,0,0.1c0,0.1,0.1,0.1,0.1,0.2c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0.1,0.2c0,0.1,0,0.1,0.1,0.2c0,0,0.1,0.1,0.1,0.1
	c0,0,0,0.1,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1c0,0,0,0.1,0.1,0.1c0.1,0.1,0.2,0.2,0.4,0.3c0,0,0.1,0,0.1,0c0.1,0,0.1,0.1,0.2,0.1
	c0.1,0.1,0.2,0.2,0.3,0.2c0,0,0.1,0,0.2,0c0,0,0.1,0.1,0.1,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.1,0.1,0.2,0.1
	c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0,0.2,0,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0.1c0.1,0,0.1,0.1,0.2,0.1
	c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.4,0.1,0.5,0.2c0.1,0,0.2,0,0.3,0.1c0,0,0.1,0,0.1,0.1c0.1,0,0.1,0,0.2,0c0,0,0,0,0.1,0
	c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c0,0,0-0.1,0-0.1c0,0,0.1,0,0.1,0c0,0,0,0.1,0.1,0.1
	c0.1,0,0.7,0,0.7-0.1c0.1,0,0-0.1,0-0.1v0c0.1,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0v0c0,0,0,0,0,0c0,0.1-0.1,0.2,0,0.2c0,0,0,0,0,0
	c0,0,0.1-0.1,0.1-0.2c0.1,0,0.2,0,0.3-0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0.1,0,0.2-0.1,0.2-0.2v0c0.1,0,0.1,0,0.2-0.1
	c0.1,0,0.1,0.1,0.1,0.1c0.1,0,0.2-0.1,0.3-0.1h0c0-0.1,0-0.1,0-0.2c0,0,0.1,0,0.1,0c0-0.1,0.2,0,0.3,0c0,0,0,0,0.1,0
	c0.1,0,0.2,0.1,0.2,0.1v0h0c0,0,0-0.1,0-0.1c0.1,0,0.1-0.1,0.1-0.1h0c0,0,0.2,0,0.2,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
	c0.1,0,0.2,0,0.2-0.1c0,0,0,0,0.1,0c0.1,0,0.1-0.1,0.2-0.1h0v0c0,0,0,0.1,0,0.1h0v0c0.1,0,0-0.2,0.2-0.2c0,0,0,0,0,0
	c0,0,0,0.1,0,0.1v0c0,0,0.3-0.1,0.3-0.1l0.1-0.1c0.1,0,0.1,0,0.2-0.1c0,0,0-0.1,0.1-0.1c0,0,0.1-0.1,0.2-0.1c0,0,0,0,0.1,0.1
	c0,0.1,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1,0v0c0,0,0,0,0.1,0c0,0,0,0,0,0c0.1,0.1,0.4-0.1,0.4-0.2c0.2,0,0.3-0.2,0.5-0.3
	c0,0,0.1,0,0.1,0c0.1-0.1,0.3-0.2,0.2-0.4v-0.1c0,0,0.1,0,0.1,0v0c0,0,0,0,0,0c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2-0.1
	c0.1,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0.1,0,0.1-0.2,0.1-0.2c0,0,0-0.1,0-0.1v0c0.1,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0
	c0.1,0,0.2-0.1,0.3-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0-0.1,0-0.1,0-0.2h0c0,0.2,0.2,0.1,0.2,0c0-0.1,0-0.2,0-0.2
	c0.1,0,0.1,0.1,0.1,0.2h0v0c0,0,0,0,0.1,0c0.1-0.1,0.1-0.1,0.1-0.2c0.1,0,0.1,0,0.1-0.1c0.1-0.1,0.1-0.1,0.2,0
	c0.1,0,0.1-0.2,0.2-0.2c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.1-0.1,0.2,0v0h0c0,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.2,0.2-0.2
	c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0-0.1,0.2-0.4,0.2-0.2h0v0c0,0,0,0,0,0h0v0c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0
	c0.1,0,0.2,0,0.2,0c0,0,0,0,0-0.1c0-0.1,0.2,0,0.2,0c0,0,0,0,0,0.1h0c0,0,0-0.1,0-0.1h0c0,0,0,0.1,0,0.1c0,0,0,0,0,0
	c0.1,0,0.4,0,0.4-0.1c0,0,0,0,0.1-0.1v0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.2,0h0c0,0,0,0,0,0c0,0,0.1,0,0.2,0
	c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.4,0c0.1,0,0.1,0,0.2,0h0.3c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0
	c0,0,0,0,0.1,0c0,0,0.1,0,0.1-0.1c0,0,0.1,0,0.1,0c0,0,0-0.1,0.1-0.1c0,0,0.1,0,0.1,0.1c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.3-0.1
	c0,0,0-0.1,0.1-0.1c0,0,0,0,0.1,0c0,0,0,0,0,0.1c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1h0v0
	c0,0,0.3,0.2,0.3,0.2h0c0-0.1,0.1-0.1,0.2-0.1c0,0,0.1-0.1,0.2,0c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1-0.1,0.2-0.2c0.1,0,0.2,0,0.3,0
	c0,0,0,0,0-0.1c0-0.1,0.1,0,0.1,0c0,0,0.1,0,0.1,0v0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0-0.1,0.1-0.1c0,0,0.1,0,0.1-0.1
	c0,0,0,0,0,0.1h0c0,0,0,0,0,0c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.1,0.3,0v0h0c0,0,0,0,0-0.1v0c0.1,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0.1
	c0,0,0.1,0,0.1-0.1c0.1,0,0.2,0,0.3,0v0c0,0,0,0,0.1-0.1c0.1-0.1,0.1-0.1,0.2-0.1c0,0.1,0.1-0.1,0.1-0.1h0c0,0,0.1,0.1,0.2,0.1
	c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2-0.1,0.4-0.1c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0,0,0-0.1,0.1-0.1
	c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0,0,0,0.1h0v0h0c0,0,0-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0
	c0.1,0,0.2-0.1,0.3-0.1c0,0,0,0-0.1,0v0c0.1,0,0-0.1,0.2,0v0h0c0-0.1,0.2-0.1,0.3-0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
	c0,0,0,0,0.1,0c0.1,0,0.1-0.1,0.1-0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0.1,0.3,0.1c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1,0,0.2
	c-0.1,0-0.2,0.1-0.3,0.2c-0.2,0.1-0.4,0.2-0.6,0.3c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0.1-0.1,0.1
	c-0.1,0.1-0.2,0.1-0.2,0.2c0,0-0.1,0.1-0.1,0.1c0,0.1-0.1,0.1-0.2,0.2v0h0c0,0,0.1,0,0.1,0c0.2-0.1,0.3-0.2,0.5-0.3
	c0.2-0.1,0.3-0.2,0.5-0.3c0,0,0,0,0,0v0c0,0,0,0,0,0c0,0.1-0.2,0.1-0.2,0.2c0,0,0,0.1,0,0.1h0v0h0c0,0,0.2-0.1,0.3-0.1
	c0,0,0,0.1,0.1,0.1c0,0,0,0,0.1,0c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0.1,0.2,0c0,0,0,0,0,0c0.1,0,0.2,0.2,0.3,0.2c0,0,0.1,0,0.1,0.1
	c0,0.1,0,0.1,0.1,0.2h0c0,0,0,0.1,0,0.1c0,0.1,0.1,0.2,0,0.3c0,0.1,0,0.1,0.1,0.2c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0.1,0.1
	c0,0,0,0.1,0.1,0.1c0,0.1,0.1,0.2,0.1,0.2c0,0,0,0,0.1,0.1c0,0,0,0.1,0.1,0.1c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0.1,0.1,0.1
	c0.1,0,0.1,0,0.2,0c0.2,0,0.2,0,0.3,0.1c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0,0.2,0c0.1,0.1,0.1,0.2,0.3,0.2
	c0.1,0,0.2-0.2,0.3-0.2c0.1,0,0.1,0,0.1,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.3,0,0.4-0.1c0.1,0,0.1,0,0.1,0
	c0.2-0.1,0.2-0.3,0.5-0.2c0,0,0.1-0.1,0.2-0.1c0-0.1,0.1-0.1,0.2-0.2h0c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c0.1,0,0.1-0.1,0.1-0.2
	c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3c0-0.1,0.1-0.1,0.1-0.2c0-0.2,0-0.3,0-0.5c0,0,0-0.1,0-0.1
	c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1c0-0.3,0-0.7,0.1-1c0.1-0.2,0-0.6,0.1-0.7C163,20.8,163,20.7,163,20.7"
      />
      <path
        fill="currentColor"
        d="M157.2,47.9c0,0,0.1-0.2,0.1-0.2c0,0-0.2-0.1-0.2-0.2c0,0,0,0,0,0c0,0-0.1,0-0.1,0c0-0.1,0.1-0.1,0-0.2c0,0-0.1,0-0.1-0.1
	c-0.1-0.1-0.2-0.2-0.2-0.4h0v0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0v-0.1c0,0,0,0-0.1,0
	c0,0,0-0.1,0-0.1v0h0c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1-0.1-0.1-0.1c0,0-0.2,0.1-0.2,0.1c0,0-0.1,0-0.1-0.1
	c0,0-0.1,0-0.1,0c0,0,0,0,0,0v0h0c0,0-0.1,0-0.1,0c0,0,0,0-0.1,0c-0.1,0-0.2,0.1-0.2,0c0,0,0-0.1,0-0.1c-0.1-0.1-0.3-0.1-0.4-0.1
	c0-0.1-0.1,0-0.2,0h0c0,0,0,0,0-0.1c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.2,0v0c0,0-0.1,0-0.1,0
	c0,0-0.1,0-0.1,0l-0.3,0c0,0,0,0,0-0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1,0-0.1,0.1v0c0,0,0,0-0.1,0c0,0,0,0,0,0
	c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0c-0.1-0.1-0.1,0.1-0.2,0.1c-0.1,0-0.1-0.1-0.2,0c0,0-0.1,0-0.1,0c0,0,0,0.1-0.1,0.1
	c-0.1,0-0.1,0-0.2,0v0c-0.1,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0c0,0,0,0,0,0v0c0,0-0.2,0-0.2-0.1c0-0.1,0.1-0.1,0.1-0.1
	c0-0.1,0.2-0.2,0.3-0.2c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.1,0.1-0.2c0,0,0.1-0.1,0.1-0.1c0-0.1,0-0.1,0.1-0.2c0,0,0.1-0.1,0.1-0.1
	c0-0.1,0.1-0.2,0.1-0.2c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0-0.2c0-0.1,0-0.2,0-0.3c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.1,0-0.2
	c0,0,0-0.1,0.1-0.1c0-0.1,0-0.1,0-0.2c0-0.1,0.1-0.3,0.1-0.4c0-0.1,0-0.2,0-0.3c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1,0-0.1
	c0,0,0-0.1,0-0.1c0-0.1,0.1-0.2,0-0.3c0,0-0.1,0-0.1,0c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.1-0.1-0.2c0,0,0-0.1,0-0.1c0,0,0-0.1,0-0.1
	c0-0.1,0-0.1,0-0.2c0,0,0-0.1-0.1-0.1c0-0.1-0.1-0.2-0.1-0.3c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.4-0.6-0.6-0.6v0c0,0-0.3-0.4-0.4-0.4
	c0,0,0,0,0,0v0c0,0-0.1,0-0.1,0c0-0.1-0.3-0.1-0.4-0.1c0-0.2-0.5-0.3-0.7-0.3c-0.1,0-0.1-0.1-0.2-0.2c0-0.1,0.5,0,0.5-0.2
	c0,0,0,0,0,0c0.1-0.1,0.2-0.1,0.3-0.1c0,0,0.1-0.1,0.1-0.1h0c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1-0.1c0.1,0,0.1,0,0.2-0.1
	c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.2-0.2,0.3-0.3c0,0,0-0.1,0.1-0.1c0.1-0.1,0.2,0,0.2-0.2c0,0,0-0.1,0-0.1c0-0.1,0-0.1,0-0.2
	c0,0,0-0.1,0.1-0.1c0-0.1-0.1-0.2-0.2-0.2c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.1,0-0.2c0,0,0.1,0,0.1,0v0c0,0,0-0.1,0-0.1
	c0-0.1,0-0.1,0-0.2c0-0.1,0-0.2-0.1-0.2c-0.1,0-0.1,0-0.2-0.1c0,0,0-0.1,0-0.1h0c0,0,0,0,0.1,0c0,0,0.1,0,0.1,0
	c0.1-0.1,0.1-0.3,0.2-0.4c0,0,0-0.1,0.1-0.1c0,0,0-0.1,0-0.1c0,0,0.1,0,0.1-0.1c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.1-0.2,0.2-0.2
	c0,0,0-0.2,0-0.2c0,0,0.1,0,0.1,0c0,0,0-0.1,0-0.1c0.1,0,0.1-0.2,0.2-0.3c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0.1-0.2,0.1-0.3
	c0.1-0.2,0.1-0.3,0.2-0.5c0,0,0-0.1,0.1-0.1c0-0.1,0-0.2,0-0.2c0-0.1,0.1-0.1,0.1-0.2l0.1-0.2c0-0.1,0-0.2,0-0.2c0-0.1,0-0.1,0-0.2
	c0-0.1,0-0.1,0-0.2l0-0.4c0,0,0,0-0.1,0c0,0,0-0.1,0-0.1c-0.1-0.2-0.2-0.3-0.3-0.5c-0.1-0.2-0.2-0.4-0.4-0.5
	c-0.1-0.1-0.2-0.1-0.3-0.2c0,0-0.1,0-0.1,0c-0.1,0-0.1-0.1-0.2-0.2c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1
	c-0.1,0-0.2,0-0.2-0.1c0,0-0.1-0.1-0.1-0.1c0,0-0.1,0-0.1,0c-0.1,0-0.2-0.1-0.2-0.1c0,0-0.1,0-0.1,0v0c0,0-0.1,0-0.1,0
	c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0v0c0,0,0,0-0.1,0c-0.1,0-0.1,0-0.2,0
	c0,0,0,0,0,0c0,0.1-0.3,0.1-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0c0,0-0.2,0.1-0.3,0.1c0,0,0,0-0.1,0c0,0,0,0,0,0
	c0,0-0.1,0-0.1,0c-0.1,0-0.1,0.1-0.1,0.1v0c0,0,0,0-0.1,0h0c0,0,0,0.1,0,0.1c-0.1,0.1-0.3,0.2-0.4,0.3c-0.1,0-0.1,0.1-0.2,0.1
	c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.1-0.4,0.2-0.5,0.4c-0.2,0.1-0.5,0.3-0.7,0.4c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.2,0.1-0.3,0.2
	c-0.1,0-0.1,0-0.1,0.1c0,0,0,0-0.1,0c0,0.1-0.3,0.3-0.4,0.3c0,0-0.1,0.1-0.1,0.1c0,0-0.1,0-0.2,0.1v0h0c0,0.1-0.1,0.1-0.1,0.1
	c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.2,0c0,0-0.1-0.1-0.1-0.1l-0.1,0c0,0,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1c-0.1-0.1-0.2-0.3-0.3-0.4
	c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.2-0.6-0.1-0.8c0,0,0.1,0,0.1,0v0c0,0,0-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0
	c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0.1v0c0.1,0,0.1-0.3,0.4-0.2c0,0,0,0.1,0,0.1v0c0.1,0,0.1-0.1,0.1-0.2
	c0.1,0,0.1-0.1,0.2-0.1c0,0,0.1,0,0.1,0c0,0,0,0,0,0c0,0,0.1,0,0.2-0.1c0,0,0.1-0.1,0.1-0.2c0,0,0.1,0,0.1,0h0v0.1h0v0h0v0
	c0,0,0-0.1,0-0.1h0c0,0,0,0,0.1,0c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0,0.2,0c0,0,0-0.1,0-0.1
	c0-0.1,0.4-0.2,0.5-0.2v0c0,0,0-0.1,0-0.2h0v0c0,0,0.1,0,0.1,0c0.1,0,0.1-0.2,0.3-0.2h0c0,0,0,0.1,0,0.1v0h0v0c0,0,0.1,0,0.1-0.1
	c0.1,0,0.2,0,0.3,0c0-0.1,0.1-0.2,0.1-0.2c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1,0,0.2,0c0-0.1,0.1-0.2,0.1-0.4c0.1,0,0.2,0,0.2-0.1
	c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1v0c0,0,0.1,0,0.1-0.1c0.1,0,0.1,0,0.1-0.1c0,0,0,0,0.1,0h0c0,0,0,0,0,0v0
	c0,0,0-0.2,0.1-0.2c0.1,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0l0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.2-0.1
	c0,0,0.2-0.2,0.2-0.2c0,0,0,0,0.1,0c0.1,0,0.1-0.1,0.2,0c0,0,0.1,0,0.1,0.1c0,0,0.2-0.1,0.3-0.1c0.2,0,0.3,0.1,0.5,0.1
	c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0.1,0.2,0c0.1,0,0.2-0.1,0.3-0.2c0,0,0-0.1,0.1-0.1
	c0.1-0.1,0.2,0,0.3,0c0.1,0,0.1-0.2,0.1-0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0.1-0.1,0.1-0.2c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1
	c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0.1-0.1,0.1-0.1c0.1-0.1,0.1-0.2,0.3-0.2h0c0,0,0,0,0,0h0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1-0.1
	c-0.1-0.1-0.1-0.2,0-0.2v0c0.1,0,0.1,0,0.2,0h0c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c0,0-0.1,0-0.1-0.1
	c0,0,0-0.1,0-0.1c0-0.2-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0-0.1c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.1-0.3-0.1-0.5-0.2c0,0-0.1,0-0.1,0
	c0,0,0,0-0.1,0h-0.1c-0.1,0-0.1,0-0.2,0c0,0,0-0.1,0-0.1c-0.1,0-0.3,0-0.3,0c0,0-0.1,0.1-0.1,0.1l0-0.1v0c-0.1,0-0.1,0.1-0.2,0.1
	c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.2,0.1-0.3,0h0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.3,0.1c0,0,0,0,0,0v0c0,0,0,0,0-0.1c0,0,0,0,0,0h0
	c0,0,0,0-0.1,0.1v0c-0.1,0-0.1,0-0.2-0.1c-0.1,0-0.1,0.1-0.1,0.1c0,0,0,0-0.1,0.1v0c-0.1,0-0.2,0-0.3,0v0c0,0-0.1,0-0.1,0v0
	c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0-0.1,0.1h0v-0.1h0v0c-0.1,0-0.3,0-0.4,0c0,0-0.1,0-0.1,0
	c0,0-0.1,0-0.1,0v0h0c0,0,0,0.1,0,0.1c-0.1,0-0.1,0-0.1,0c0,0-0.2,0.1-0.3,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.1,0
	c-0.1,0-0.2,0-0.2,0.1c-0.1,0-0.2,0.1-0.3,0.1v0c0,0,0,0,0,0c0,0.1-0.1,0.1-0.1,0.2c0,0-0.3,0.1-0.3,0.1c0,0-0.1,0-0.1,0l-0.1,0
	c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0-0.2,0.1v0
	h0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0.1-0.3,0.1-0.4,0.2
	c-0.1,0-0.3,0.1-0.4,0.1c-0.1,0-0.1,0-0.2,0c0,0-0.1,0-0.1,0c0,0-0.1,0-0.1,0c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1,0-0.1,0
	c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2-0.1-0.4-0.1c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0-0.1,0.1c0,0-0.1,0-0.1,0c0,0.1-0.1,0.1-0.1,0.1
	c-0.1,0.1-0.2,0.2-0.2,0.3c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3c0,0.1,0,0.3,0,0.4c0,0.1,0,0.1,0,0.2
	c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1-0.1,0.2-0.1,0.3c0,0.1,0,0.2,0,0.3c0,0,0,0.1,0,0.1c0,0.1,0,0.1-0.1,0.1
	c0,0,0,0.1,0.1,0.1c0,0,0,0,0,0c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0.1,0.2h0c0,0,0,0,0,0c0,0.1,0.1,0.1,0.1,0.1c0,0.1,0.1,0.3,0.2,0.3
	c0,0,0.1,0,0.2,0.1c0.1,0.1,0.2,0.2,0.3,0.2c0.3,0.2,0.6,0.3,1,0.3c0.1,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0,0.1-0.1,0.3-0.1,0.4
	c0,0.1,0,0.2,0,0.2c0,0.1,0,0.1-0.1,0.2c-0.1,0.3,0,0.6,0,1c0,0.1,0,0.3,0.1,0.4c0,0.1,0,0.3,0,0.4c0,0.1,0.1,0.3,0.1,0.4
	c0,0.1,0,0.2,0,0.2c0,0.1,0,0.2,0,0.3c0,0.1,0.1,0.2,0.1,0.3c0,0,0,0.1,0.1,0.1l0,0.3c0,0.1,0,0.1,0.1,0.2c0,0.1,0,0.1,0,0.2
	c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0.1,0.1c0,0.1,0,0.2,0,0.2c0.1,0.2,0.2,0.3,0.2,0.5h0c0,0,0,0,0,0c0.1,0,0.1,0.1,0.1,0.1
	c0,0.1,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.2,0.2,0.3c0,0,0.1,0.1,0.1,0.1c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0.1c-0.1,0-0.2,0.1-0.2,0.1
	c-0.2,0.1-0.4,0.2-0.6,0.3c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0h0c0,0-0.2,0.1-0.2,0.1c0,0-0.1,0-0.1,0v0c-0.1,0-0.1,0.1-0.1,0.1
	c0,0-0.1,0-0.1,0c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0-0.1,0-0.1,0v0
	c0,0-0.1,0.1-0.1,0.1c-0.1,0.1-0.3,0.2-0.2,0.4c0,0.1,0.1,0.1,0,0.2c0,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0.1l0.1,0v0
	c0,0,0,0,0,0c0,0.1-0.1,0.2-0.1,0.3c0,0,0,0-0.1,0.1c0,0,0,0.1-0.1,0.1c-0.1,0.2,0.1,0.3-0.1,0.4c0,0.1,0.2,0.2,0,0.4
	c0,0.1,0.1,0.1,0.1,0.2c0,0.1,0,0.1,0,0.2l0,0.2c0,0,0,0.1,0,0.1c0,0.1,0.1,0.2,0.1,0.3c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0,0.1
	c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.1,0.1,0.1,0.2c0,0,0.1,0,0.1,0.1c0.1,0.1,0.2,0.1,0.2,0.2
	c0.1,0.1,0.1,0.2,0.2,0.3c0.2,0.2,0.4,0.2,0.6,0.4c0.1,0.1,0.3,0.3,0.4,0.3c0,0.1,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0
	c0.3,0.1,0.5,0.2,0.8,0.2c0,0,0.1,0,0.1,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0.1,0.4,0.1c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.2,0
	c0.1,0,0.1,0,0.2-0.1c0,0,0.1,0,0.1,0c0,0,0.1-0.2,0.2-0.3l0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0c0-0.1,0.1-0.1,0.1-0.2
	c0-0.1,0.1-0.2,0.1-0.2c0,0,0.1-0.1,0.1-0.1c0-0.1,0.1-0.1,0.1-0.2c0,0,0.1-0.1,0.1-0.1c0.1-0.1,0.1-0.2,0.1-0.3h0c0,0,0,0.1,0,0.1
	c0,0,0.1,0,0.1-0.1c0-0.1-0.1-0.1-0.1-0.2c0.1-0.2,0.4-0.3,0.6-0.4c0.1,0,0.2-0.1,0.2-0.1c0.1,0,0.3-0.1,0.4-0.1c0,0,0.1,0,0.1,0
	c0,0,0.1,0,0.1,0c0.2,0,0.3,0,0.4,0.1c0,0,0.1,0.1,0.1,0.1c0,0.1,0.1,0.1,0.1,0.1c0.1,0.2,0,0.6-0.1,0.7c0,0.1-0.2,0.1-0.2,0.2
	c-0.1,0.1-0.1,0.2-0.2,0.2v0c0,0-0.1,0-0.1,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0v0c-0.2,0-0.3,0.2-0.4,0.3c0,0-0.1,0-0.1,0.1
	c0,0,0,0.1,0,0.2c0,0-0.1,0-0.1,0.1c0,0.1,0,0.1,0,0.1c0,0.1-0.1,0.2-0.2,0.2c0,0-0.1,0-0.1,0.1c-0.1,0.1-0.1,0.1-0.2,0.1v0h0
	c0,0,0,0.1,0,0.1c0,0,0.4,0.1,0.4,0.2c0,0.1-0.2,0.2-0.2,0.2c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.1,0-0.2,0.1c0,0-0.1,0-0.1,0
	c0,0.1,0,0.1,0,0.2c0,0,0.1,0,0.1,0c0.1,0.1,0.1,0.3,0,0.4c0,0-0.1,0-0.1,0v0c0,0,0,0,0,0c0,0,0,0,0.1,0c0.1,0,0.2,0,0.2,0.2h0v0h0
	v0c0,0,0-0.1,0-0.2v0c0,0,0,0,0.1,0c0,0,0,0,0-0.1h0v0h0c0,0,0,0.1,0,0.1h0v0c0,0,0-0.1,0-0.2c0,0,0,0,0,0c0,0-0.1,0-0.1,0
	c-0.1,0-0.1-0.2,0-0.2v0c0,0,0.1,0,0.1,0c0.1,0,0.2-0.1,0.2,0c0,0.1-0.1,0.1-0.2,0.1v0h0c0,0,0.1,0.1,0.1,0.1c0,0,0,0,0,0h0
	c0.1,0,0.2-0.1,0.3,0c0,0,0,0,0,0.1v0.1c0,0,0.1,0,0.1,0c0,0.1-0.1,0.1-0.2,0.1c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.2,0-0.2,0v0
	c0,0,0-0.1,0-0.1c0,0.1-0.4,0.2-0.5,0.2c0-0.1-0.1-0.1-0.1-0.2c0-0.1,0-0.1-0.1-0.2c0,0,0,0-0.1-0.1h0c0,0.1-0.1,0.1-0.1,0.1
	c0,0.1,0.1,0.2,0.1,0.2c0,0.1-0.1,0.1-0.1,0.2c0,0.1,0,0.1,0,0.2c-0.1,0-0.4-0.5-0.4-0.6c0-0.2,0.2-0.2,0.1-0.4v0
	c-0.1,0-0.1,0-0.2,0.1h0v0c0,0-0.1-0.2-0.1-0.3h0v0c0.1,0,0.2,0.1,0.3,0.1v0h0c0-0.2-0.3-0.2-0.1-0.3c0,0,0.1,0,0.1-0.1h0
	c0-0.1-0.3,0-0.3,0h-0.1c0-0.1,0-0.1,0.1-0.1c0-0.1,0-0.2,0.1-0.3c0,0,0-0.1,0-0.1c0,0,0,0,0,0v0h0v0l-0.1,0c0,0.1-0.1,0-0.2,0v0h0
	c0,0-0.1,0.1-0.1,0.1c0,0.1,0.1,0.1,0.2,0.1v0c0,0,0,0.1-0.1,0.1c0,0-0.1,0-0.1,0c0,0,0,0.1,0,0.1v0h0c0-0.1,0-0.2,0.1-0.2
	c0-0.1-0.1-0.1-0.1-0.1c0,0,0,0,0,0.1h0c0,0,0,0,0,0c0,0-0.1,0.1-0.1,0.1c0,0.2,0,0.1-0.1,0.3c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0.1,0
	c0,0,0,0,0,0h0v0c0,0,0,0,0,0v0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.3,0.1c0,0,0,0.1,0,0.1c0,0,0.1,0,0.1,0v0h0c0,0,0,0,0,0v0
	c-0.1,0.1-0.1,0.1-0.2,0.2v0c-0.1,0-0.1,0-0.2,0c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.1,0.1-0.2,0.1v0c0,0-0.1,0-0.1,0
	c0,0.1-0.1,0.1-0.1,0.2c-0.1,0-0.1,0-0.2,0.1h0c0,0-0.1-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0.1-0.1,0.1-0.1,0.2
	c0,0,0,0.1,0,0.1h0c0,0,0-0.1,0-0.1c-0.1,0,0,0.1,0,0.2c-0.1,0-0.1-0.1-0.2-0.2h0c0-0.1,0-0.1,0-0.2h0c0,0,0,0.1-0.1,0.1
	c-0.1,0-0.1,0-0.2,0c0,0,0,0.1,0,0.1h0c0,0.1,0.1,0,0.2,0v0h0c0,0.2-0.1,0.2-0.1,0.3c-0.1,0-0.1,0-0.2-0.1v0c0,0-0.1,0-0.1,0h0
	c0,0,0,0,0,0c0-0.1-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1h0v0h0v0c0,0,0,0,0,0h0v0c0,0,0,0,0,0v0h0c0,0,0,0,0,0c0-0.1,0-0.1-0.1-0.2v0
	c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0.1,0,0.1,0,0.2c0,0,0,0,0,0c-0.2,0.1-0.3-0.2-0.4,0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.2,0.2
	c0,0,0,0.1,0,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0v0c0,0,0,0-0.1,0h0c0,0-0.1,0.1-0.1,0.1v0h0c0-0.2-0.2,0-0.3-0.1h0v0h0
	c0,0-0.1,0-0.1,0c0,0.1,0.1,0.1,0,0.2c0,0.1-0.2,0.1-0.2,0.2c0,0.1,0,0.1,0,0.1v0.2c0,0.1,0,0.1,0,0.2c0,0.1-0.1,0.3-0.1,0.4h0
	c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2c0,0,0-0.1,0-0.1c0,0,0-0.1,0.1-0.1h0c0,0,0,0,0,0c0,0.2,0,0.4,0,0.6
	c0,0-0.1,0.1-0.1,0.1h0c0,0,0,0,0-0.1h0v0c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0c0,0,0,0.1,0,0.1c0,0.2,0,0.3,0.1,0.5
	c0,0,0,0.1,0.1,0.1c0,0,0,0.1,0,0.1c0,0.1,0.1,0.1,0.1,0.2c0.1,0.1,0.1,0.1,0.2,0.2c0,0.1,0,0.1,0.1,0.2c0,0,0.1,0.1,0.1,0.1
	c0,0,0,0.1,0,0.1c0,0,0.1,0.1,0.1,0.1c0.1,0.1,0.1,0.1,0.2,0.2c0,0,0,0.1,0.1,0.1c0,0,0.1,0,0.1,0.1c0.1,0.1,0.2,0.2,0.3,0.3
	c0.1,0.1,0.1,0.1,0.2,0.2c0.1,0,0.4,0.1,0.5,0.2c0,0,0.1,0,0.1,0c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0,0.1,0
	c0.1,0,0.1,0.1,0.2,0.1c0.1,0,0.1,0,0.2,0.1c0,0,0.1,0,0.1,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0,0.1,0
	c0.1,0.1,0.3,0.1,0.5,0.1l0.2,0c0,0,0.1,0,0.1,0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0
	c0.2,0,0.4,0,0.6,0c0.1,0,0.2,0,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0,0,0.1-0.1,0.1-0.1
	c0,0,0,0,0,0.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.2,0,0.2,0c0.1,0,0.2,0,0.3,0v0.1h0v0h0v0
	c0,0,0,0,0-0.1c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.3-0.1,0.4-0.1c0,0,0.1,0,0.1,0v0
	c0,0,0,0,0,0c0.1,0,0.2,0,0.4,0c0,0,0-0.1,0.1-0.1h0c0,0,0,0,0,0c0,0.1,0.2,0.1,0.3,0.1c0,0,0.1,0,0.1,0.1c0,0,0,0,0,0c0,0,0,0,0,0
	c0.1,0,0.2,0,0.3,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.2,0,0.3,0,0.4,0.1v0c0.1,0,0.2,0,0.3,0c0,0,0,0,0-0.1c0.1,0,0.2,0,0.2,0.1
	c0-0.1,0.3,0,0.3,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1c0.1,0.1,0.1,0,0.2,0c0.1,0,0.1,0,0.1-0.1h0
	c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.3,0,0.3-0.1h0c0,0,0,0,0,0c0,0.1,0.1,0.1,0.2,0.1c0.2,0,0.2-0.2,0.4-0.3
	c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0c0,0,0.1-0.1,0.1-0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0,0.3,0c0.2,0,0.4-0.2,0.5-0.4h0c0,0,0,0,0,0
	c0,0,0,0,0.1,0c0,0,0.1-0.1,0.1-0.1c0.1,0,0.1-0.1,0.2-0.1c0,0,0-0.1,0-0.1h0v0c0,0,0.1,0,0.1,0c0.1,0,0.2-0.3,0.2-0.4
	c0.1,0,0.1,0,0.1-0.1h0v0c0,0,0.1,0,0.1,0v0c0,0,0,0,0,0c0-0.2,0.1-0.2,0.2-0.3c0-0.1,0-0.1,0.1-0.2c0,0,0-0.1,0-0.1c0,0,0,0,0,0
	c0,0,0.1-0.1,0.1-0.1c0-0.1,0-0.3,0-0.3c0,0,0,0,0.1-0.1c0,0,0,0,0,0c0-0.1,0-0.2,0-0.2c0-0.1,0.1-0.1,0.1-0.2c0-0.1,0-0.2,0-0.2
	c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0-0.1C157.3,48,157.2,47.9,157.2,47.9 M140.6,48.1C140.6,48.2,140.6,48.2,140.6,48.1
	C140.6,48.2,140.6,48.2,140.6,48.1z M140.6,48.1c0.1,0,0.1,0.1,0,0.2V48.1z M140.8,48.6L140.8,48.6L140.8,48.6c0,0-0.1,0-0.2-0.1
	c0-0.1,0-0.1,0-0.2c0.1,0,0.1,0.1,0.2,0.1C140.9,48.4,140.8,48.6,140.8,48.6 M141,46.8C141,46.8,141,46.8,141,46.8
	c-0.1,0.2-0.1,0.3-0.2,0.4h-0.1v0c0,0,0,0,0,0c0-0.1,0-0.1,0.1-0.2C140.9,46.9,140.9,46.9,141,46.8C140.9,46.8,140.9,46.8,141,46.8
	L141,46.8L141,46.8L141,46.8z M143.1,46.5L143.1,46.5C143,46.5,143,46.5,143.1,46.5c-0.1-0.1-0.1-0.2-0.1-0.2c0,0-0.1,0-0.1-0.1
	c-0.1,0,0.1-0.1,0.1-0.1c0.1,0,0.1,0.1,0.1,0.2C143.1,46.3,143.1,46.5,143.1,46.5L143.1,46.5L143.1,46.5z M145.4,45.7
	c0.3,0.1,0.1,0.1,0.1,0.3c0,0,0.1,0,0.1,0v0h0v0c0,0,0,0-0.1,0c-0.1,0.1-0.2,0-0.2-0.1h-0.1v0C145.3,46,145.3,45.8,145.4,45.7
	 M145.1,45.4h0.2v0c0,0,0,0.1,0,0.1C145.3,45.5,145.2,45.5,145.1,45.4C145.1,45.5,145.1,45.4,145.1,45.4 M145,46.1
	c0,0.1-0.1,0.1-0.2,0.2c-0.1,0-0.1-0.1-0.2-0.1v0h0c0,0.1,0,0.1,0,0.2c0,0-0.1,0-0.1,0c-0.1,0-0.1,0-0.2,0c0-0.1,0-0.1,0-0.2h0v0
	c0.1,0,0.2,0,0.2-0.1c0,0,0,0,0.1,0c0,0,0,0,0,0c0-0.1,0.1-0.1,0.2-0.1C145,46,145,46.1,145,46.1z M144.7,45.5
	C144.7,45.5,144.7,45.5,144.7,45.5c0,0.1,0.1,0,0.2,0.1c0,0,0,0,0,0c0,0,0,0-0.1,0c-0.2,0-0.2,0-0.3-0.2
	C144.6,45.5,144.6,45.5,144.7,45.5 M144.4,45.8h0.1v0h0v0C144.6,45.8,144.6,45.8,144.4,45.8c0,0.1,0,0.1-0.1,0.1v0
	C144.4,45.8,144.4,45.8,144.4,45.8 M144.3,45.5C144.3,45.5,144.3,45.5,144.3,45.5c0,0,0,0.1,0,0.1v0h0
	C144.2,45.5,144.2,45.6,144.3,45.5 M144.2,47.1c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0h0c0,0,0,0,0,0.1c0,0-0.2,0.1-0.2,0.1
	c-0.1,0-0.2,0-0.2-0.1c0,0,0.1,0,0.1,0C144.2,47.2,144.2,47.1,144.2,47.1z M143.9,45.7C143.9,45.7,144,45.7,143.9,45.7
	c0.1,0.1,0,0.1,0,0.1v0h0C143.8,45.8,143.9,45.7,143.9,45.7z M143.8,45.8c0.1,0,0.1,0.2,0.1,0.3C143.8,46.1,143.8,45.9,143.8,45.8z
	 M143.4,45.8c0.1,0,0.1,0,0.2,0c0,0,0.1,0,0.1,0v0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c-0.1,0-0.1,0-0.1,0v0c0,0-0.1-0.1-0.1-0.1v0
	c0,0-0.1,0-0.1,0.1v0h0C143.4,45.9,143.4,45.8,143.4,45.8 M143.5,46.9c0-0.1,0-0.2-0.1-0.3v0c0,0,0.1-0.2,0.1-0.2
	c-0.3,0-0.2-0.4,0-0.3c0,0,0.1,0,0.1-0.1c0.1,0,0.1,0.1,0.1,0.2c0,0,0.1,0,0.2,0c0,0.1,0,0.2,0,0.3c0.1,0,0.1-0.1,0.2-0.2
	c0.1,0,0,0.1,0,0.2c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1c0,0.1-0.1,0.1-0.1,0.1c0,0-0.1,0.1-0.1,0.1
	C143.7,46.9,143.6,46.9,143.5,46.9 M144.7,47.5c0,0-0.1,0.1-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1c0,0-0.1,0-0.1,0c-0.1,0.1,0,0.1,0,0.2
	c0,0.1,0,0.1-0.1,0.2h0v0c0,0-0.1-0.1-0.1-0.1c0,0,0,0-0.1,0v0h0c0,0,0,0,0,0.1h0v0c-0.1,0-0.1,0.1-0.1,0.1c0,0,0,0,0,0
	c0,0,0,0,0-0.1c0,0,0.1-0.1,0-0.1c0,0-0.1-0.1-0.1-0.1c0,0,0-0.1,0-0.1c0,0-0.1-0.1-0.1-0.1v0c0.1-0.1,0.2,0,0.2-0.1
	c0.1,0,0.1,0,0.2,0h0v0.1h0v0h0v0c0,0,0-0.1,0-0.1v0c0,0,0,0,0,0c0-0.1,0.1-0.1,0.1-0.1c0,0,0-0.1,0.1-0.1v0c0,0,0.2,0,0.2,0
	c0,0,0.1-0.1,0.1-0.2v0c0,0,0.1,0,0.1,0C144.8,47.3,144.7,47.4,144.7,47.5 M145.6,46.7c0,0-0.1,0-0.1,0c-0.1,0-0.1,0.1-0.2,0.1
	c0,0-0.3,0.1-0.3,0.1c0,0,0,0-0.1,0h0c0,0,0,0,0,0c-0.1,0-0.1,0-0.2-0.1h0v0c0,0,0,0.1,0,0.1c0,0-0.1,0-0.1,0c0-0.1-0.1-0.1-0.1-0.2
	c0-0.1,0.2-0.1,0.3,0v0c0,0,0.1-0.1,0.1-0.1c0,0,0.1,0,0.1,0c0.1,0,0.1-0.1,0.1-0.2c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.2,0,0.2-0.1
	c0,0,0.1,0,0.1,0C145.6,46.5,145.6,46.6,145.6,46.7C145.6,46.6,145.6,46.7,145.6,46.7 M145.9,46.9C145.9,46.9,145.9,46.8,145.9,46.9
	c-0.1-0.1,0-0.1,0-0.1C146,46.8,146,46.8,145.9,46.9 M147.8,45.7C147.8,45.7,147.8,45.7,147.8,45.7
	C147.9,45.7,147.9,45.7,147.8,45.7C147.9,45.7,147.8,45.7,147.8,45.7z M147.3,39.3L147.3,39.3c0,0-0.1,0-0.1,0c0,0-0.1,0.1-0.2,0.1
	v0c-0.1,0-0.2,0.1-0.2,0.1c-0.1,0.1-0.2,0.1-0.3,0.2h0c0,0,0,0,0,0c0,0-0.1,0-0.1,0c-0.2,0.1-0.3,0.2-0.5,0.2c0,0-0.1,0.1-0.1,0.1
	c0,0-0.1,0-0.1,0.1c-0.1,0-0.1,0.1-0.2,0.1c-0.1,0-0.1,0.1-0.2,0.1h-0.1v0c0,0,0,0,0,0c0,0.2-0.3,0.2-0.4,0.2c0,0,0,0-0.1-0.1
	c-0.1,0-0.1,0-0.1,0c-0.1,0-0.1-0.1-0.2-0.1c0,0-0.1-0.1-0.1-0.1H144c0-0.1-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.2,0
	c-0.1,0-0.1,0-0.2,0c0,0,0,0,0-0.1v0c-0.1,0-0.3-0.1-0.4-0.2h0c-0.1-0.1-0.2-0.1-0.3-0.1c0,0,0-0.1-0.1-0.1c0,0-0.1-0.1-0.1-0.1
	c0,0-0.1-0.1-0.1-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.1-0.2-0.2-0.3c0,0,0,0,0,0c0-0.2-0.1-0.3,0-0.5c0.2-0.2,0.4-0.3,0.6-0.4
	c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0c0,0,0.1,0.1,0.1,0.1c0.1,0,0.1-0.1,0.2-0.1c0.1,0,0.1,0.1,0.2,0.1
	c0,0,0.1,0,0.1,0.1c0.1,0,0.1,0,0.2,0.1c0.1,0,0.2,0.1,0.3,0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0.1,0.2,0.1c0,0,0.1,0.1,0.1,0.1
	c0.2,0.1,0.2,0,0.3,0.2c0.1,0,0.2-0.1,0.2,0c0.1,0.1,0.1,0.2,0.3,0.2c0,0,0,0,0,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.2,0.1
	c0.1,0,0.1,0,0.2,0c0.1,0,0.3,0.1,0.4,0.1C147.2,39.2,147.2,39.2,147.3,39.3C147.3,39.3,147.4,39.3,147.3,39.3
	C147.4,39.3,147.4,39.3,147.3,39.3 M149.7,33.3h-0.2c0,0.2-0.1,0.4-0.2,0.5c-0.1,0-0.2,0-0.3,0c0,0.1-0.2,0.2-0.2,0.3
	c0,0.1-0.1,0.1-0.1,0.2c-0.2,0-0.2-0.1-0.4,0v0c0,0-0.1,0-0.1,0c-0.1,0-0.2,0.1-0.3,0.1h-0.2c0,0.1-0.1,0.2-0.1,0.3v0.1
	c-0.1,0-0.1-0.1-0.2-0.1c-0.1,0-0.2-0.1-0.3-0.1c-0.1,0-0.1-0.1-0.2-0.1c-0.1-0.1-0.3,0-0.4,0.1c-0.1,0-0.2-0.1-0.3-0.1
	c0-0.1,0.1-0.1,0.2-0.2h0c0-0.1,0-0.2,0-0.3c0.1-0.1,0.2-0.2,0.4-0.2c0.1-0.1,0.2-0.2,0.3-0.3c0.1,0,0.1-0.1,0.1-0.1
	c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.2-0.1,0.3-0.1c0.2-0.1,0.4-0.4,0.6-0.5c0.1,0,0.2-0.1,0.2-0.1c0.2-0.1,0.2-0.1,0.4-0.1v0
	c0,0,0,0,0,0c0-0.1,0.1-0.1,0.2-0.1c0,0,0.1,0.1,0.1,0.1c0.2,0.2,0.2,0.1,0.2,0.4c0.1,0,0.1,0.1,0.2,0.1
	C149.7,33,149.7,33.2,149.7,33.3 M150.1,46.5C150.1,46.5,150.1,46.5,150.1,46.5C150.1,46.5,150.1,46.5,150.1,46.5z M150.5,46.7
	L150.5,46.7C150.5,46.6,150.5,46.6,150.5,46.7C150.5,46.6,150.5,46.6,150.5,46.7L150.5,46.7C150.5,46.6,150.5,46.6,150.5,46.7
	L150.5,46.7L150.5,46.7z"
      />
    </svg>
  )
}
