import { LANGUAGES } from '@/data'
import metadata from './metadata'
import menuItemData from './menuItemData'
import blogPost from './blogPost'
import locationData from './locationData'
import pageData from './pageData'

const languageArray = Object.values(LANGUAGES)
/* eslint-disable */
export const pageMetadataFields = `
  _type,
  _createdAt,
  _updatedAt,
  title,
  slug,
  metadata {
    ${languageArray
      .map(language => {
        return `
        $language == "${language}" => {
          ...(${language}Metadata) {
            ${metadata.fields}
          }
        }
      `
      })
      .join(',')}
  },
  ${languageArray
    .map(language => {
      return `
      $language == "${language}" => {
        "isEnabled": $language in isEnabled
      }
    `
    })
    .join(',')},
  _type == "page" => {
    ${pageData.fields}
  },
  _type == "menuItem" => {
    ${menuItemData.fields}
  },
  _type == "location" => {
    ${locationData.fields}
  },
  _type == "blogPost" => {
    ${blogPost.fields}
  },
  "globalMetaData": {
    ...(*[_type == "siteSettingsMetadata"] {
      _type,
      ${languageArray
        .map(language => {
          return `
          $language == "${language}" => {
            ...(${language}SiteSettingsMetadata) {
              ${metadata.fields}
            }
          }
        `
        })
        .join(',')}
    }[0])
  }
`

export const pageSitemapFields = `
  _type,
  _updatedAt,
  slug,
  metadata {
    ${languageArray
      .map(language => {
        return `
        $language == "${language}" => {
          ...(${language}Metadata) {
            allowCrawlers
          }
        }
      `
      })
      .join(',')}
  },
  ${languageArray
    .map(language => {
      return `
      $language == "${language}" => {
        "isEnabled": $language in isEnabled
      }
    `
    })
    .join(',')},
`

/* eslint-enable */
