export default function Hamburger({ className }: SvgProps) {
  return (
    <svg
      className={className}
      viewBox="0 0 24 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.90909 2.23832L21 2.32923L24 0L21 3.85259L2.90909 3.76168L0 6L2.90909 2.23832Z"
        fill="currentColor"
      />
      <path
        d="M2.90909 14.2383H21.0909L24 12L21.0909 15.7617H2.90909L0 18L2.90909 14.2383Z"
        fill="currentColor"
      />
    </svg>
  )
}
