import { imageAsset, link } from '@/data/sanity/fragments'
import cmsSettings from '../cmsSettings'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  sectionBackground,
  title,
  items[] {
    year,
    title,
    eyebrow,
    description[]{
      ...,
      markDefs[]{
        ...,
        _type == "link" => {
          ${link.fields}
        },
      }
    },
    ${imageAsset.fragment('image')},
    imageStyle
  }
`

export const fragment = (name = 'timeline') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
