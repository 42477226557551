import { LANGUAGES } from '@/data'
import link from './link'
import { getMergedLanguageQueryString } from '@/data/sanity/utils'

export const fields = `
  blogPostData {
    ${getMergedLanguageQueryString('BlogPostData', [
      'publishedDate',
      {
        name: 'blogCategory',
        dereference: true,
        fields: `
        title, 
        slug
      `,
      },
    ])}
  },
  ${Object.values(LANGUAGES)
    .map(language => {
      return `
      $language == "${language}" => {
        "richTextContent": richTextContent.${language}RichTextContent[] {
          _key,
          _type,
          children[] {
            ...,
            _type == "link" => {
              ${link.fields}
            },
          },
          style,
          listItem,
          level
        }
      }
    `
    })
    .join(',')}
`

export const fragment = (name = 'blogPost') => `${name}{${fields}}`

const exported = {
  fields,
  fragment,
}

export default exported
