import { imageAsset, link, videoAsset } from '@/data/sanity/fragments'
import cmsSettings from '../cmsSettings'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  sectionBackground,
  items[]{
    mediaType,
    ${imageAsset.fragment('image')},
    ${videoAsset.fragment('videoLoopDesktop')},
    ${videoAsset.fragment('videoLoopMobile')},
    title,
    description,
    smallText,
    ${link.fragment('cta')}
  }
`

export const fragment = (name = 'contentCards') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
