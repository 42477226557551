import cmsSettings from '../cmsSettings'
import { link } from '@/data/sanity/fragments'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  sectionBackground,
  content[]{
    ...,
    markDefs[]{
      ...,
      _type == "link" => {
        ${link.fields}
      },
    },
    _type == "link" => {
      ${link.fields}
    },
  }
`

export const fragment = (name = 'richTextContent') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
