import classnames from 'classnames'
import styles from './Button.module.scss'
import React, { useImperativeHandle, useRef, useState } from 'react'
import Link from '@/components/Link/Link'
import useBreakpoint from '@/hooks/use-breakpoint'
import Icon from '@/components/Icon/Icon'

const BUTTON_TYPES = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  PLAIN: 'plain',
}

const Button = React.forwardRef<ButtonImperativeHandle, ButtonProps>(
  (
    {
      className,
      label,
      children,
      onFocus,
      onBlur,
      onMouseEnter,
      onMouseLeave,
      isHoverState,
      onClick,
      element,
      link,
      linkClassName,
      disableHoverAnimation = false,
      ariaLabel,
      disableOpenNewTab,
      disabled,
      primary,
      secondary,
      tertiary,
      plain,
      icon,
      type: buttonType,
      htmlFor,
    },
    ref,
  ) => {
    label = label || link?.label
    icon = icon || link?.icon
    const [isHover, setIsHover] = useState(false)
    const containerRef = useRef<HTMLButtonElement | HTMLLabelElement | null>(null)
    const Element = link ? 'span' : element || 'button'
    const { isMobile } = useBreakpoint()

    let type: string = BUTTON_TYPES.SECONDARY
    if (secondary) {
      type = BUTTON_TYPES.SECONDARY
    } else if (tertiary) {
      type = BUTTON_TYPES.TERTIARY
    } else if (primary) {
      type = BUTTON_TYPES.PRIMARY
    } else if (plain) {
      type = BUTTON_TYPES.PLAIN
    }

    useImperativeHandle(ref, () => ({
      getElement: () => {
        return containerRef.current
      },
      setIsHover: isHover => {
        setIsHover(isHover)
      },
    }))

    const handleOnMouseEnter = () => {
      if (onMouseEnter) onMouseEnter()
      if (!isMobile && !disableHoverAnimation) {
        setIsHover(true)
      }
    }

    const handleOnMouseLeave = () => {
      if (onMouseLeave) onMouseLeave()
      if (!isMobile && !disableHoverAnimation) {
        setIsHover(false)
      }
    }

    const handleOnClick = () => {
      if (onClick) onClick()
    }

    const handleOnFocus = () => {
      if (onFocus) onFocus()
    }

    const handleOnBlur = () => {
      if (onBlur) onBlur()
    }

    const content = (
      <Element
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ref={containerRef as any}
        className={classnames(styles.Button, className, { [styles.hover]: isHover || isHoverState })}
        onMouseEnter={handleOnMouseEnter}
        onMouseLeave={handleOnMouseLeave}
        onClick={handleOnClick}
        aria-label={ariaLabel}
        data-button-type={type}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        disabled={disabled}
        type={buttonType}
        data-button
        htmlFor={htmlFor}
      >
        {label && !children && (
          <>
            <span
              className={styles.label}
              data-button-label
            >
              {label}
            </span>
            {icon && (
              <span
                className={styles.icon}
                data-icon={icon}
              >
                <Icon
                  name={icon}
                  addFilled={primary}
                />
              </span>
            )}
          </>
        )}
        {children && children}
      </Element>
    )

    if (link) {
      return (
        <Link
          className={classnames(linkClassName, styles.link)}
          link={link}
          disableOpenNewTab={disableOpenNewTab}
        >
          {content}
        </Link>
      )
    }

    return content
  },
)

Button.displayName = 'Button'

export default Button
