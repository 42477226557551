import { imageAsset } from '@/data/sanity/fragments'
import { getMainDataOverrides, getMergedLanguageQueryString } from '@/data/sanity/utils'

export const fields = `
  _id,
  _key,
  title,  
  'slug': slug.current,
  ...(content) {
    ${getMergedLanguageQueryString('Content', ['price', 'purchaseLink', { name: 'image', fields: imageAsset.fields }])}
  },
  ${getMainDataOverrides({
    objectSuffix: 'Content',
    mainObjectName: 'content',
    mainDataOverrides: ['title'],
  })}
`

export const fragment = (name: string) => `${name}{
	${fields}
}`

const exports = { fields, fragment }

export default exports
