import { imageAsset } from '@/data/sanity/fragments'
import cmsSettings from '../cmsSettings'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  sectionBackground,
  title,
  review,
  author,
  designation,
  ${imageAsset.fragment('image')},
  textSide
`

export const fragment = (name = 'reviews') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
