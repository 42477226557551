import cmsSettings from '../cmsSettings'
import pressHighlight from '../pressHighlight'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  sectionBackground,
  'items': *[_type == "pressHighlight" && $language in isEnabled] {
    ${pressHighlight.fields}
  }[0...8]
`

export const fragment = (name = 'pressHighlightListing') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
