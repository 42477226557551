export default function Check({ className }: SvgProps) {
  return (
    <svg
      className={className}
      viewBox="0 0 9 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 1.5L3.1875 6.5L1 4.22727"
        stroke="currentColor"
        strokeLinecap="square"
      />
    </svg>
  )
}
