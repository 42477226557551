import classnames from 'classnames'
import styles from './Spinner.module.scss'

export default function Spinner({ className }: SvgProps) {
  return (
    <svg
      className={classnames(styles.spinner, className)}
      viewBox="0 0 50 50"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        className={styles.path}
        cx="25"
        cy="25"
        r="20"
        fill="none"
        strokeWidth="5"
      />
    </svg>
  )
}
