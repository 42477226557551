import { DEFAULT_LANGUAGE, LANGUAGES } from '@/data'

export const fields = `
  title,
  ${Object.values(LANGUAGES)
    .map(lang => {
      if (lang === DEFAULT_LANGUAGE) return ''
      return `
        $language == "${lang}" => {
          defined(${lang}Title) => {"title": ${lang}Title}
        },
      `
    })
    .join('')}
  slug
`

export const fragment = (name: string) => `${name}{
	${fields}
}`

const exports = { fields, fragment }

export default exports
