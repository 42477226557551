import { ClientConfig, createClient } from 'next-sanity'
import { page, siteSettings } from '@/data/sanity/fragments'
import { formatSiteSettingsResponse, getSections, mergeSiteSettings } from '@/utils'
import { DOC_TYPES, LANGUAGES } from '@/data'
import { pageSitemapFields } from '@/data/sanity/fragments/_shared'

let perspective = process.env.SANITY_PREVIEW_TOKEN === undefined ? 'published' : 'previewDrafts'
if (process.env.NODE_ENV === 'development') {
  perspective = 'raw'
}

const SANITY_CONFIG: ClientConfig = {
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET,
  apiVersion: process.env.NEXT_PUBLIC_SANITY_API_VERSION,
  useCdn: process.env.SANITY_PREVIEW_TOKEN === undefined ? true : false,
  token: process.env.SANITY_PREVIEW_TOKEN,
  perspective: perspective as 'raw' | 'published' | 'previewDrafts',
}

export const client = createClient(SANITY_CONFIG)

const revalidateDefaultPeriod = parseInt(process.env.REVALIDATE || '604800') // 1 week revalidation
const revalidate = process.env.NODE_ENV !== 'production' ? 0 : revalidateDefaultPeriod

export const getSiteSettings = async ({ isPreview, language }: { isPreview: boolean; language?: string }) => {
  if (!language) language = LANGUAGES.EN

  /* eslint-disable */
  const siteSettingsData: SanitySiteSettingsResponse = await client.fetch(
    `
      *[(
        _type == "siteSettingsMetadata" ||
        _type == "navigation" ||
        _type == "footer"
        )] {
        ${siteSettings.fields}
      }
  `,
    { isPreview: Boolean(isPreview), language },
    {
      next: {
        revalidate,
        tags: ['siteSettingsMetadata', 'footer', 'navigation'],
      },
    },
  )

  /* eslint-enable */

  if (!siteSettingsData?.length) return null

  const formatted = formatSiteSettingsResponse(siteSettingsData)

  return formatted as SanitySiteSettingsFormatted
}

export const getPage = async (slug: string, type = 'page', isPreview: boolean, language?: string) => {
  if (!language) language = LANGUAGES.EN

  const query = `
    *[_type == "${type}" && slug.current == "${slug}"] {
      ${page.getFields()}
    }
  `
  const response = (
    await client.fetch(
      query,
      { isPreview: Boolean(isPreview), language },
      {
        next: {
          revalidate,
          tags: ['page', `${type}:${slug}`, ...Object.values(DOC_TYPES).map(type => type)],
        },
      },
    )
  )[0]

  if (!response || !siteSettings) return null

  const merged = mergeSiteSettings(response, response.globalMetaData) as SanityPage
  const newSections = getSections(merged, language)

  merged.sections = newSections

  return merged as SanityPage
}

export const getAllPages = async (
  type = DOC_TYPES.PAGE,
  isPreview?: boolean,
  language?: string,
  isSitemapGeneration?: boolean,
) => {
  if (!language) language = LANGUAGES.EN

  const tags = isSitemapGeneration ? ['sitemap'] : [...Object.values(DOC_TYPES).map(type => type)]

  const docTypes = typeof DOC_TYPES === 'object' && Object.keys(DOC_TYPES).length > 0 ? Object.values(DOC_TYPES) : []
  const docTypesOrCondition = docTypes.map(type => `_type == "${type}"`).join(' || ')
  const sitemapQuery = ` *[${docTypesOrCondition}] {
      ${pageSitemapFields}
    }
  `
  const pagesQuery = `*[_type == "${type}"] {
      ${page.getFields()}
    }
  `

  const pages = await client.fetch(
    isSitemapGeneration ? sitemapQuery : pagesQuery,
    { isPreview: Boolean(isPreview), language },
    {
      next: {
        revalidate,
        tags,
      },
    },
  )

  if (!pages.length) return null

  return pages as SanityPage[]
}

export const getAllPagesForAllLanguages = async (
  type = DOC_TYPES.PAGE,
  isPreview?: boolean,
  isSitemapGeneration?: boolean,
) => {
  let data: SanityPageWithLanguage[] = []
  const slugTypeHashmap: { [key: string]: string } = {}

  for (const lang of Object.values(LANGUAGES)) {
    const pageData = await getAllPages(type, isPreview, lang, isSitemapGeneration)
    const newData: SanityPageWithLanguage[] = []

    pageData?.forEach((page: SanityPage) => {
      if (slugTypeHashmap[`${page._type}_${page.slug.current}_${lang}`]) return
      slugTypeHashmap[`${page._type}_${page.slug.current}_${lang}`] = 'true'
      newData.push({ ...page, language: lang })
    })

    data = [...data, ...newData]
  }

  if (!data.length) return []

  return data as SanityPageWithLanguage[]
}
