import { link } from '@/data/sanity/fragments'
import cmsSettings from '../cmsSettings'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  sectionBackground,
  items[] {
    groupTitle,
    accordionItems[] {
      title,
      description[]{
        ...,
        markDefs[]{
          ...,
          _type == "link" => {
            ${link.fields}
          },
        }
      },
    }
  }
`

export const fragment = (name = 'accordionItemsWithSideNavigation') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
