import { imageAsset, videoAsset } from '@/data/sanity/fragments'
import cmsSettings from '../cmsSettings'

export const STAGGERED_IMAGES_ITEM_FIELDS = `
  mediaType,
  ${imageAsset.fragment('image')},
  ${videoAsset.fragment('videoLoopDesktop')},
  ${videoAsset.fragment('videoLoopMobile')}
`

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  sectionBackground,
  items[] {
    ${STAGGERED_IMAGES_ITEM_FIELDS}
  }
`

export const fragment = (name = 'staggeredImages') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
