import cmsSettings from '../cmsSettings'
import { imageAsset, videoAsset } from '@/data/sanity/fragments'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  sectionBackground,
  mediaType,
  ${imageAsset.fragment('image')},
  ${videoAsset.fragment('videoLoopDesktop')},
  ${videoAsset.fragment('videoLoopMobile')}
`

export const fragment = (name = 'fourOhFour') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
