'use client'

import { useState, useRef, useEffect } from 'react'
import classnames from 'classnames'
import styles from './Accordion.module.scss'
import Content from './AccordionContent/Content'
import Header from './AccordionHeader/Header'
import gsap from 'gsap'

const DURATION = 0.4

const Accordion = ({ className, items, exclusiveOpen = false, initialOpenIndex }: AccordionProps) => {
  const [openItems, setOpenItems] = useState<{ [key: number]: boolean }>({})
  const accordionRefs = useRef<Array<HTMLDivElement | null>>([])

  const animateAccordion = (itemId: number, isOpen: boolean) => {
    gsap.killTweensOf(accordionRefs.current[itemId])
    gsap.fromTo(accordionRefs.current[itemId], isOpen ? {} : { height: 0 }, {
      height: isOpen ? 0 : 'auto',
      duration: isOpen ? DURATION : DURATION * 1.5,
      ease: 'Power3.easeOut',
    })
  }

  const toggleItem = (itemId: number) => {
    const openAccordion = openItems[itemId]
    animateAccordion(itemId, openAccordion)

    if (exclusiveOpen) {
      setOpenItems({ [itemId]: !openItems[itemId] })
      Object.keys(openItems).forEach(key => {
        if (Number(key) !== itemId) {
          animateAccordion(parseInt(key), true)
          setOpenItems(prevState => ({
            ...prevState,
            [key]: false,
          }))
        }
      })
    } else {
      setOpenItems(prevState => ({
        ...prevState,
        [itemId]: !prevState[itemId],
      }))
    }
  }

  const openInitialItem = (index: number) => {
    gsap.set(accordionRefs.current[index], {
      height: 'auto',
    })
    setOpenItems({ [index]: true })
  }

  useEffect(() => {
    if (initialOpenIndex !== undefined) {
      openInitialItem(initialOpenIndex)
    }
  }, [initialOpenIndex])

  return (
    <div className={classnames(styles.Accordion, className)}>
      {items.map((item, index) => {
        const accordionId = `id_${Math.random()}`
        const headerId = `id_${Math.random()}`

        return (
          <div
            key={index}
            className={styles.item}
            data-accordion-item
          >
            <Header
              item={item}
              toggleItem={toggleItem}
              accordionId={accordionId}
              headerId={headerId}
              index={index}
              isOpen={openItems[index]}
            />
            <div
              className={classnames(styles.contentWrapper, openItems[index] && styles.contentWrapperOpen)}
              ref={el => {
                accordionRefs.current[index] = el
              }}
            >
              <Content
                item={item}
                accordionId={accordionId}
                headerId={headerId}
              />
            </div>
          </div>
        )
      })}
    </div>
  )
}

Accordion.displayName = 'Accordion'

export default Accordion
