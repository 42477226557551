import { pageMetadataFields } from '@/data/sanity/fragments/_shared'
import cmsSettings from '../cmsSettings'
import { DEFAULT_LANGUAGE, LANGUAGES } from '@/data'
import { headerWithCtas, imageAsset } from '@/data/sanity/fragments'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  sectionBackground,
  hideHeaderContent,
  ${headerWithCtas.fragment('headerContent')},
  ${imageAsset.fragment('backgroundSvg')},
  orderOfStates[],
  showPrivateDiningOnly,
  ${Object.values(LANGUAGES)
    .map(language => {
      if (language === DEFAULT_LANGUAGE) {
        const query = `_type == "location" && $language in isEnabled && locationData.${language}LocationData.showInLists == true`
        return `
          $language == "${language}" => {
            showPrivateDiningOnly == true => {
              'items': *[${query} && locationData.${language}LocationData.hasPrivateDining == true] {
                ${pageMetadataFields}
              }
            },
            showPrivateDiningOnly == false => {
              'items': *[${query}] {
                ${pageMetadataFields}
              } 
            }
          }
        `
      } else {
        const query = `_type == "location" && $language in isEnabled && (locationData.${language}LocationData.showInLists == true || locationData.${DEFAULT_LANGUAGE}LocationData.showInLists == true)`
        return `
          $language == "${language}" => {
            showPrivateDiningOnly == true => {
              'items': *[${query} && (locationData.${language}LocationData.hasPrivateDining == true || locationData.${DEFAULT_LANGUAGE}LocationData.hasPrivateDining == true)] {
                ${pageMetadataFields}
              }
            },
            showPrivateDiningOnly == false => {
              'items': *[${query}] {
                ${pageMetadataFields}
              }
            }
          }
        `
      }
    })
    .join(',')}

`

export const fragment = (name = 'locationsList') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
