import cmsSettings from '../cmsSettings'
import { headerWithCtas, imageAsset } from '@/data/sanity/fragments'
import { link } from '@/data/sanity/fragments'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  sectionBackground,
  title,
  description,
  ${link.fragment('cta')},
  ${imageAsset.fragment('image')},
  ${headerWithCtas.fragment('headerContent')}
`

export const fragment = (name = 'testComponent') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
