import imageAsset from './imageAsset'

export const fields = `
  _key,
  title,
  description,
  keywords,
  allowCrawlers,
  ${imageAsset.fragment('image')},
  ${imageAsset.fragment('favicon')},
  metaBackgroundColorHex,
  themeColorHex
`

export const fragment = (name = 'metadata') => `${name}{${fields}}`

const exported = {
  fields,
  fragment,
}

export default exported
