import { imageAsset, link } from '@/data/sanity/fragments'
import { getMainDataOverrides, getMergedLanguageQueryString } from '@/data/sanity/utils'
import menuItemCategory from './menuItemCategory'
import { STAGGERED_IMAGES_ITEM_FIELDS } from '@/data/sanity/fragments/sections/staggeredImages'

export const fields = `
  menuItemData {
    ${getMergedLanguageQueryString('MenuItemData', [
      { name: 'category', fields: menuItemCategory.fields, dereference: true },
      'description',
      'subtitle',
      { name: 'images', isArray: true, fields: imageAsset.fieldsWithDataUri },
      { name: 'lifestyleImages', isArray: true, fields: imageAsset.fieldsWithDataUri },
      'heroImageStyle',
    ])}
  },
  ${getMainDataOverrides({
    objectSuffix: 'MenuItemData',
    mainObjectName: 'menuItemData',
    mainDataOverrides: ['title'],
  })}
  staggeredMediaContent {
    ${getMergedLanguageQueryString('StaggeredMediaContent', [
      { name: 'items', isArray: true, fields: STAGGERED_IMAGES_ITEM_FIELDS },
    ])}
  },
  textAndImageContent {
    ${getMergedLanguageQueryString('TextAndImageContent', [
      'isEnabled',
      'title',
      'description',
      { name: 'cta', isArray: true, fields: link.fields },
      { name: 'image', fields: imageAsset.fields },
    ])}
  }
`

export const fragment = (name = 'menuData') => `${name}{${fields}}`

const exported = {
  fields,
  fragment,
}

export default exported
