'use client'

import { useContext, useEffect } from 'react'
import styles from './VideoPopout.module.scss'
import useStore from '@/store'
import { ScrollContext } from '@/context/Scroll'

const VideoPopout = () => {
  const setPopoutVideo = useStore(state => state.setPopoutVideo)
  const popoutVideo = useStore(state => state.popoutVideo)
  const { scroll } = useContext(ScrollContext)

  useEffect(() => {
    if (!scroll) return
    scroll[popoutVideo ? 'stop' : 'start']()
  }, [scroll, popoutVideo])

  const getUrl = () => {
    if (!popoutVideo || !popoutVideo?.videoId || !popoutVideo?.videoType) return ''

    if (popoutVideo.videoType === 'vimeo') {
      return `https://player.vimeo.com/video/${popoutVideo.videoId}`
    }

    if (popoutVideo.videoType === 'youtube') {
      return `https://www.youtube.com/embed/${popoutVideo.videoId}`
    }

    return ''
  }

  if (!popoutVideo) return null

  return (
    <div className={styles.VideoPopout}>
      <div
        className={styles.bg}
        onClick={() => {
          setPopoutVideo(null)
        }}
      />
      <div className={styles.videoContainer}>
        <div className={styles.embedContainer}>
          <iframe
            frameBorder={0}
            src={getUrl()}
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  )
}

VideoPopout.displayName = 'VideoPopout'

export default VideoPopout
