'use client'

import { forwardRef, useContext } from 'react'
import NextLink from 'next/link'
import classnames from 'classnames'
import { getUrlFromPageData, proxyAssetCdn } from '@/utils'
import styles from './Link.module.scss'
import useCurrentPage from '@/hooks/use-current-page'
import { ScrollContext } from '@/context/Scroll'
import { NAV_ID } from '@/components/Navigation/Navigation'
import useStore from '@/store'

const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      className,
      children,
      link,
      onMouseEnter,
      onMouseLeave,
      linkOnly,
      ariaLabel,
      onClick,
      onFocus,
      disableOpenNewTab = false,
      language,
    },
    ref,
  ) => {
    const { linkType, label, link: url, hash, navigationOffset } = link
    const { scroll } = useContext(ScrollContext)
    const { currentPath, currentLanguage } = useCurrentPage()
    const setPopoutVideo = useStore(state => state.setPopoutVideo)
    const urlObject = url as SanityLinkInternal
    const slug = typeof url === 'object' ? url?.slug : ''
    const path = `/${language ? language : currentLanguage}${getUrlFromPageData(urlObject?._type, slug)}`

    if (linkType === 'disabled') {
      return null
    }

    if (typeof url !== 'string' && linkType === 'external') {
      return null
    }

    if (typeof url !== 'object' && linkType === 'internal') {
      return null
    }

    if (linkType === 'external' || linkType === 'file') {
      return (
        <a
          ref={ref}
          aria-label={ariaLabel}
          data-link={true}
          {...(ariaLabel && !label && !children && { name: ariaLabel })}
          href={typeof url === 'string' ? proxyAssetCdn(url) : ''}
          className={classnames(className)}
          target={disableOpenNewTab ? '_self' : '_blank'}
          rel="noreferrer"
          onFocus={e => {
            if (onFocus) onFocus(e)
          }}
          onMouseEnter={() => {
            if (onMouseEnter) onMouseEnter()
          }}
          onMouseLeave={() => {
            if (onMouseLeave) onMouseLeave()
          }}
          onClick={e => {
            if (onClick) onClick(e)
          }}
        >
          {label && !children && !linkOnly && <span>{label}</span>}
          {children && children}
        </a>
      )
    } else if (linkType === 'internal' || linkType === 'videoPopout') {
      const goesToOtherPage = currentPath !== path
      const hasHashOnSamePage = !goesToOtherPage && hash
      const isPopoutVideo = linkType === 'videoPopout' && link.videoId && link.videoPopoutType

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const props: any = {
        'aria-label': ariaLabel,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onFocus: (e: any) => {
          if (onFocus) onFocus(e)
        },
        ref: ref,
        className: classnames(
          className,
          { [styles.hashLink]: hasHashOnSamePage },
          {
            [styles.inactive]: !hasHashOnSamePage && currentPath === path,
          },
        ),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        onClick: (e: any) => {
          if (onClick) onClick(e)

          if (isPopoutVideo) {
            setPopoutVideo({
              videoType: link.videoPopoutType as SanityLinkVideoTypes,
              videoId: link.videoId as string,
            })
          }

          if (hash) {
            if (hasHashOnSamePage) {
              e.preventDefault()
            }

            const goToElement = () => {
              const id = document.getElementById(hash)

              let offset = 0
              const navElement = document.getElementById(NAV_ID)
              if (navElement && navigationOffset) {
                offset = (navElement?.offsetHeight + 20) * -1
              }

              if (id && scroll) {
                scroll.scrollTo(id, {
                  duration: 0.3,
                  offset,
                })
              }
            }

            setTimeout(
              () => {
                goToElement()
              },
              hasHashOnSamePage ? 0 : 400,
            )
          }
        },
        onMouseEnter: () => {
          if (onMouseEnter) onMouseEnter()
        },
        onMouseLeave: () => {
          if (onMouseLeave) onMouseLeave()
        },
      }

      if (hasHashOnSamePage || isPopoutVideo) {
        return (
          <span {...props}>
            {label && !children && <span>{label}</span>}
            {children && children}
          </span>
        )
      }

      props.scroll = false
      props.href = `${path}${hash ? `#${hash}` : ''}`
      props['data-link'] = true

      return (
        <NextLink {...props}>
          {label && !children && <span>{label}</span>}
          {children && children}
        </NextLink>
      )
    }
  },
)

Link.displayName = 'Link'

export default Link
