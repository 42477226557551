import { useState, useEffect } from 'react'
import useStore from '@/store'
import styles from './StickyBanner.module.scss'
import classnames from 'classnames'
import Icon from '@/components/Icon/Icon'
import Link from '@/components/Link/Link'
import SanityImage from '@/components/SanityImage/SanityImage'
import { setCookie } from '@/utils'
import { STICKY_BANNER_CLOSED_COOKIE_EXPIRY_SECONDS, STICKY_BANNER_CLOSED_COOKIE_NAME } from '@/data'

const StickyBanner = ({ data }: { data: StickyBannerData }) => {
  const { text, link, image, isEnabled } = data || {}
  const [isClosed, setIsClosed] = useState(false)
  const setStickyBannerIsOpen = useStore(state => state.setStickyBannerIsOpen)

  useEffect(() => {
    if (isClosed || !isEnabled) {
      setCookie({
        name: STICKY_BANNER_CLOSED_COOKIE_NAME,
        value: 'true',
        maxAge: STICKY_BANNER_CLOSED_COOKIE_EXPIRY_SECONDS,
      })
      setStickyBannerIsOpen(false)
    }
  }, [isClosed, setStickyBannerIsOpen, isEnabled])

  return (
    <div className={classnames(styles.StickyBanner, { [styles.hide]: isClosed })}>
      {image && (
        <div className={styles.backgroundImage}>
          <SanityImage
            image={image}
            className={styles.image}
            breakpoints={{
              xs: {
                width: 700,
                image,
                options: {
                  height: 1400,
                  fit: 'crop',
                },
              },
              tablet: {
                width: 2000,
                image,
              },
              desktop: {
                width: 2500,
                image,
              },
            }}
          />
        </div>
      )}
      <button
        onClick={() => setIsClosed(true)}
        aria-label="Close"
        className={styles.closeButton}
      >
        <Icon
          name="close"
          className={styles.closeIcon}
        />
      </button>
      {text && (
        <div className={styles.Content}>
          {link ? (
            <Link
              link={link}
              className={styles.desktopLinkList__item__link}
            >
              {text}
            </Link>
          ) : (
            text
          )}
        </div>
      )}
    </div>
  )
}

StickyBanner.displayName = 'StickyBanner'

export default StickyBanner
