'use client'
import classnames from 'classnames'
import styles from './Content.module.scss'
import Button from '@/components/Button/Button'
import AccordionRichText from '../AccordionRichText/AccordionRichText'
import React from 'react'

const Content = ({ item, headerId, accordionId }: AccordionContentProps) => {
  return (
    <div
      id={accordionId}
      className={classnames(styles.Content)}
      role="region"
      aria-labelledby={headerId}
    >
      <div className={styles.contentInnerWrapper}>
        {item.subtitle && <h3 className={styles.subtitle}>{item.subtitle}</h3>}
        <div className={styles.description}>
          {typeof item.description === 'string' && <p className={styles.descriptionString}>{item.description}</p>}
          {React.isValidElement(item.description) ? (
            <>{item.description}</>
          ) : (
            <AccordionRichText content={item.description as SanityContentBlockProps[]} />
          )}
        </div>
        {item.cta && (
          <Button
            className={styles.cta}
            link={item.cta}
            plain
            icon="caretRight"
          />
        )}
      </div>
    </div>
  )
}

Content.displayName = 'Content'

export default Content
