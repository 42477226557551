import link from './link'

export const fields = `
  links[] {
    ${link.fields}
  },
  stickyBanner {
    isEnabled,
    text,
    link {
      ${link.fields}
    },
    image {
      asset-> {
        url
      }
    }
  }
`

export const fragment = (name = 'navigation') => `${name}{${fields}}`

const exported = {
  fields,
  fragment,
}

export default exported
