export default function Play({ className }: SvgProps) {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.9974 18.3337C14.5998 18.3337 18.3307 14.6027 18.3307 10.0003C18.3307 5.39795 14.5998 1.66699 9.9974 1.66699C5.39502 1.66699 1.66406 5.39795 1.66406 10.0003C1.66406 14.6027 5.39502 18.3337 9.9974 18.3337Z"
        stroke="currentColor"
        strokeLinecap="square"
      />
      <path
        d="M12 10L9 13L9 7L12 10Z"
        fill="currentColor"
      />
    </svg>
  )
}
