'use client'

import ContentWithStickyNavigationMobileMenu from '@/components/ContentWithStickyNavigation/ContentWithStickyNavigationMobileMenu/ContentWithStickyNavigationMobileMenu'
import useStore from '@/store'

const ContentWithStickyNavigationMobileMenuContainer = () => {
  const stickyMobileNavContent = useStore(state => state.stickyMobileNavContent)

  if (!stickyMobileNavContent.length) return null

  return (
    <>
      {stickyMobileNavContent.map((item, i) => (
        <ContentWithStickyNavigationMobileMenu
          key={i}
          {...item}
        />
      ))}
    </>
  )
}

ContentWithStickyNavigationMobileMenuContainer.displayName = 'ContentWithStickyNavigationMobileMenuContainer'

export default ContentWithStickyNavigationMobileMenuContainer
