export default function Clock({ className }: SvgProps) {
  return (
    <svg
      className={className}
      viewBox="0 0 20 20"
    >
      <path
        d="M10.0003 18.3333C14.6027 18.3333 18.3337 14.6023 18.3337 9.99996C18.3337 5.39759 14.6027 1.66663 10.0003 1.66663C5.39795 1.66663 1.66699 5.39759 1.66699 9.99996C1.66699 14.6023 5.39795 18.3333 10.0003 18.3333Z"
        stroke="currentColor"
        fill="none"
        strokeLinecap="square"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 3.5L10.5 9.69098L14.5 12.5L9.5 10.309L10 3.5Z"
        fill="currentColor"
      />
    </svg>
  )
}
