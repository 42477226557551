export const fields = `
	_id,
	_key,
	alt,
	_type,
	asset->{
		_id,
		_key,
		_type,
		alt,
		extension,
		metadata {
			dimensions {
				aspectRatio,
				height,
				width
			}
		},
		originalFilename,
		size,
		url
	}
`

export const fieldsWithDataUri = `
	_id,
	_key,
	alt,
	_type,
	asset->{
		_id,
		_key,
		_type,
		alt,
		extension,
		metadata {
			lqip,
			dimensions {
				aspectRatio,
				height,
				width
			}
		},
		originalFilename,
		size,
		url
	}
`

export const fragment = (name: string, hasDataUri?: boolean) => `${name}{
	${hasDataUri ? fieldsWithDataUri : fields}
}`

const exports = { fields, fragment }

export default exports
