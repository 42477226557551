import cmsSettings from '../cmsSettings'
import merchandiseItem from '../merchandiseItem'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  sectionBackground,
  title,
  subtitle,
  image,
  items[]->{
    ${merchandiseItem.fields}
  }
`

export const fragment = (name = 'merchandiseListing') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
