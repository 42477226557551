'use client'

import Image from 'next/image'
import classnames from 'classnames'
import { useNextSanityImage } from 'next-sanity-image'
import { client as sanityClient } from '@/data/sanity'
import { getImageUrl, proxyAssetCdn } from '@/utils'
import styles from './SanityImage.module.scss'
import { useMemo, useState } from 'react'
import scssExports from '@/styles/export-vars.module.scss'

const { mobile, tablet, laptop, desktop, xl } = scssExports

const BREAKPOINTS = {
  xs: 1,
  mobile: parseInt(mobile),
  tablet: parseInt(tablet),
  laptop: parseInt(laptop),
  desktop: parseInt(desktop),
  xl: parseInt(xl),
}

type BreakpointArrayItem = SanityImageBreakpoint & {
  breakpoint: number
}

const SanityImage = ({
  className,
  image,
  id,
  onLoad,
  sizes,
  breakpoints,
  unoptimized,
  priority,
  disableLoadedOpacity,
  customization,
}: SanityImageProps) => {
  const [loaded, setLoaded] = useState(false)
  const dataUri = image?.asset?.metadata?.lqip

  const imageProps: any = useNextSanityImage(sanityClient, image, {
    imageBuilder: (imageUrlBuilder, options) => {
      let builder = imageUrlBuilder
        .width(customization?.width || options.width || Math.min(options.originalImageDimensions.width, 1920))
        .quality(customization?.quality || options.quality || 75)
        .fit(customization?.fit || 'clip')

      if (customization?.height) {
        builder = builder.height(customization.height)
      }

      if (customization?.crop) {
        builder = builder.crop(customization.crop)
      }

      if (customization?.blur) {
        builder = builder.blur(customization.blur)
      }

      if (customization?.sharpen) {
        builder = builder.sharpen(customization.sharpen)
      }

      if (customization?.flipHorizontal) {
        builder = builder.flipHorizontal()
      }

      if (customization?.flipVertical) {
        builder = builder.flipVertical()
      }

      if (customization?.rect) {
        const { left, top, width, height } = customization.rect
        builder = builder.rect(left, top, width, height)
      }

      return builder
    },
  })

  if (imageProps) {
    if (dataUri) {
      imageProps.placeholder = 'blur'
      imageProps.blurDataURL = dataUri
    } else {
      imageProps.placeholder = 'empty'
    }
  }

  const imageElement = (
    <Image
      onLoad={() => {
        if (onLoad) onLoad()
        setLoaded(true)
      }}
      {...imageProps}
      src={proxyAssetCdn(imageProps?.src) || ''}
      width={imageProps?.width || 100}
      height={imageProps?.height || 100}
      sizes={sizes ? sizes : '75vw'}
      id={id}
      alt={image?.alt || ''}
      className={classnames(
        className,
        [styles.SanityImage],
        { [styles.loaded]: loaded && !disableLoadedOpacity },
        { [styles.hasDataUri]: dataUri },
      )}
      unoptimized={imageProps ? true : unoptimized}
      priority={priority}
    />
  )

  const breakpointsAsArray = useMemo(() => {
    if (!breakpoints) return

    const breakpointOrder = Object.keys(BREAKPOINTS).reverse()
    const asArray: BreakpointArrayItem[] = []

    Object.keys(breakpoints).forEach(key => {
      if (!breakpoints[key as keyof typeof breakpoints]) return
      asArray[breakpointOrder.indexOf(key)] = {
        ...breakpoints[key as keyof typeof breakpoints],
        breakpoint: BREAKPOINTS[key as keyof typeof breakpoints],
      } as BreakpointArrayItem
    })

    return asArray.filter(item => item)
  }, [breakpoints])

  if (breakpoints) {
    return (
      <picture>
        {breakpointsAsArray?.map((item, index) => {
          if (!item.image?.asset?.url || !item.width || !item.breakpoint) {
            return null
          }
          return (
            <source
              srcSet={getImageUrl(item.image, { width: item.width, ...item?.options }) || undefined}
              media={`(min-width: ${item.breakpoint}px)`}
              key={index}
            />
          )
        })}
        {imageElement}
      </picture>
    )
  }

  if (!image) return null

  return imageElement
}

SanityImage.displayName = 'SanityImage'

export default SanityImage
