import cmsSettings from '../cmsSettings'
import ecommerceItem from '../ecommerceItem'

export const fields = `
  _type,
  _id,
  ${cmsSettings()},
  sectionBackground,
  items[]-> {
    ${ecommerceItem.fields}
  }
`

export const fragment = (name = 'ecommerceItemList') => `${name}{ ${fields} }`

const exported = {
  fields,
  fragment,
}

export default exported
