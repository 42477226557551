import { videoAsset } from '@/data/sanity/fragments'
import link from './link'

export const fields = `
  linkColumns[] {
    title,
    items[] {
      ${link.fields}
    }
  },
  socialMediaLinks[]{
    ${link.fields}
  },
  legal,
  ${videoAsset.fragment('allergenGuidePdf')}
`

export const fragment = (name = 'footer') => `${name}{${fields}}`

const exported = {
  fields,
  fragment,
}

export default exported
